import { getData, postData, putData } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const ActivityTemplateService = {
    getAll: function(){
        return getData(`${ApiHost}/api/ActivityTemplates`);
    },
    postActivityTemplate: function(template){
        return postData(`${ApiHost}/api/ActivityTemplates`, template);
    },
    putActivityTemplate: function(template){
        return putData(`${ApiHost}/api/ActivityTemplates`, template);
    },
    updateScriptedActivities: function(templateId, scriptedActivities) {
        return putData(`${ApiHost}/api/ActivityTemplates/${templateId}/scriptedActivities`, scriptedActivities);
    },
    updateUnitTemplates: function(templateId, unitTemplates, syncChanges) {
        return putData(`${ApiHost}/api/ActivityTemplates/${templateId}/units?syncChanges=${syncChanges}`, unitTemplates);
    },
}
