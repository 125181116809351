import { deleteData, getData, patchData, postData, putData, urlQueryString } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const UnitService = {
    getAll: function (limit, customerId, siteId) {
        const qs = urlQueryString({
            limit,
            customer_id: customerId,
            site_id: siteId,
        }, true);
        return getData(`${ApiHost}/api/Units?${qs}`)
    },
    getScriptedActivities: function(unitId) {
        return getData(`${ApiHost}/api/Units/${unitId}/scriptedActivities`);
    },
    getUnitConfig: function(unitId) {
        return getData(`${ApiHost}/api/Config/${unitId}`);
    },
    postUnit: function(systemMode, unit) {
        return postData(`${ApiHost}/api/Units/${systemMode}`, unit);
    },
    putUnit: function(unitId, unit){
        return putData(`${ApiHost}/api/Units/${unitId}`, unit)
    },
    patchUnit: function(unitId, patchUnitDTO){
        const data = [
            { op: 'replace', path: '/isActive', value: patchUnitDTO.isActive },
            { op: 'replace', path: '/isRegistered', value: patchUnitDTO.isRegistered }
        ];
        return patchData(`${ApiHost}/api/Units/${unitId}`, data)
    },
    patchUnitSettings: function(unitId, patchAdminGlobalSettingDTO){
        const data = [
            { op: 'replace', path: '/AdminAutoLogoutTime', value: patchAdminGlobalSettingDTO.adminAutoLogoutTime },
            { op: 'replace', path: '/DefaultUnits', value: patchAdminGlobalSettingDTO.defaultUnits },
            { op: 'replace', path: '/UnitId', value: patchAdminGlobalSettingDTO.unitId },
            { op: 'replace', path: '/UnitModel', value: patchAdminGlobalSettingDTO.unitModel },
            { op: 'replace', path: '/UserAutoLogoutTime', value: patchAdminGlobalSettingDTO.userAutoLogoutTime },
            { op: 'replace', path: '/UserInactiveTime', value: patchAdminGlobalSettingDTO.userInactiveTime },
            { op: 'replace', path: '/UserLiteTime', value: patchAdminGlobalSettingDTO.userLiteTime },
            { op: 'replace', path: '/UserSearchFilterBy', value: patchAdminGlobalSettingDTO.userSearchFilterBy },
            { op: 'replace', path: '/UserSearchDisplayFullName', value: patchAdminGlobalSettingDTO.userSearchDisplayFullName },
            { op: 'replace', path: '/RequireUserPIN', value: patchAdminGlobalSettingDTO.requireUserPIN },
            { op: 'replace', path: '/RequireOrientationActivity', value: patchAdminGlobalSettingDTO.requireOrientationActivity },
        ];
        return patchData(`${ApiHost}/api/Units/${unitId}/AdminGlobalSettings`, data)
    },
    updateScriptedActivities: function(unitId, scriptedActivities) {
        return putData(`${ApiHost}/api/Units/${unitId}/scriptedActivities`, scriptedActivities);
    },
    updateUnitTemplate: function(unitId, templateId) {
        return putData(`${ApiHost}/api/Units/${unitId}/activityTemplate`, templateId);
    },
    deleteUnit: function (unitId) {
        return deleteData(`${ApiHost}/api/Units/${unitId}`);
    }
};
