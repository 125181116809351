import { createSlice } from "@reduxjs/toolkit";

const initialTableState = {
    tests: [],
    users: [],
    userGroups: [],
    units: [],
    sites: [],
    customers: [],
    scriptedActivities: [],
    baseActivities: [],
    tags: [],
    pillars: [],
    activityGroups: [],
    activityTemplates: [],
    categories: [],
};

export const tableDataState = createSlice({
    name: 'tableDataState',
    initialState: initialTableState,
    reducers: {
        setTests: (state, action) => {
            state.tests = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setUserGroups: (state, action) => {
            state.userGroups = action.payload;
        },
        setUnits: (state, action) => {
            state.units = action.payload;
        },
        setSites: (state, action) => {
            state.sites = action.payload;
        },
        setCustomers: (state, action) => {
            state.customers = action.payload;
        },
        setScriptedActivities: (state, action) => {
            state.scriptedActivities = action.payload;
        },
        setBaseActivities: (state, action) => {
            state.baseActivities = action.payload;
        },
        setTags: (state, action) => {
            state.tags = action.payload;
        },
        setPillars: (state, action) => {
            state.pillars = action.payload;
        },
        setActivityGroups: (state, action) => {
            state.activityGroups = action.payload;
        },
        setActivityTemplates: (state, action) => {
            state.activityTemplates = action.payload;
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
    }
})

export const {
    setTests,
    setUsers,
    setUserGroups,
    setUnits,
    setSites,
    setCustomers,
    setScriptedActivities,
    setBaseActivities,
    setTags,
    setPillars,
    setActivityGroups,
    setActivityTemplates,
    setCategories
} = tableDataState.actions;

export default tableDataState.reducer;
