/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Button } from '../../CoreComponents/Button';
import { Card } from '../../CoreComponents/Card';
import { TableCSF } from '../../CoreComponents/TableComponents/TableCSF';
import { useTranslation } from '../../CoreComponents/Translation';
import { useHook } from '../../CoreComponents/Utils';
import { DistributionChart } from '../../CoreComponents/ChartComponents/DistributionChart';
import { LineChart } from '../../CoreComponents/ChartComponents/LineChart';
import { shortTime } from '../../Utils/Common';

const caloriesTabStyles = {
    root: css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    `,
    tabSelection: css`
        border: 2px solid #00ABA5;
        border-radius: 8px;
        padding: 2px;
        margin: 40px auto 20px auto;
        width: 256px;

        & > button {
            border-radius: 4px;
            width: 50%;
            min-width: unset;
        }
    `,
    tabContent: css`
        height: calc(100% - 80px);
        overflow: auto;
    `,
    graphTabContent: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 32px 0 12px;
        height: 100%;

        & > * {
            max-height: 600px;
        }
    `,
};

const SubTabs = {
    Graphs: 1,
    Table: 2
};

export const SubTabsTranslationKeysArr = [
    { value: SubTabs.Graphs, translationKey: 'analytics.subTabs.graphs' },
    { value: SubTabs.Table, translationKey: 'analytics.subTabs.table' },
];

export const CaloriesTab = ({ reportData }) => {
    const { t } = useTranslation();
    const $selectedTab = useHook(SubTabs.Graphs);
    const chartData = useMemo(() => {
        const { charts } = reportData.calories;
        return {
            distribution: charts.distribution,
            trend: [{
                label: t('analytics.charts.caloriesTrend'),
                data: charts.trend,
                exportSettings: {
                    xAxis: t('analytics.chartExportDataField.date'),
                    yAxis: t('analytics.charts.caloriesTrend'),
                },
            }],
        };
    }, [t, reportData.calories]);

    const headCells = useMemo(() => [
        {
            id: 'date',
            label: t('analytics.table.date'),
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.date ? shortTime(rowData.date) : '-';
            }
        },
        {
            id: 'fullName',
            label: t('analytics.table.fullName'),
            isSortable: true
        },
        {
            id: 'username',
            label: t('analytics.table.username'),
            isSortable: true
        },
        {
            id: 'scriptedActivity',
            label: t('analytics.table.scriptedActivity'),
            isSortable: true
        },
        {
            id: 'scriptedActivityStep',
            label: t('analytics.table.scriptedActivityStep'),
            isSortable: true
        },
        {
            id: 'average',
            label: t('analytics.table.calories'),
            isSortable: true
        },
        {
            id: 'quick-fix',
            label: '',
            CellRender: () => null
        },
    ], [t]);

    const unitType = t('constants.units.calories');
    const unitTypeShort = t('constants.units.caloriesShort');

    const { table } = reportData.calories;

    return (
        <div css={caloriesTabStyles.root}>
            <div css={caloriesTabStyles.tabContent}>
                {$selectedTab.value === SubTabs.Graphs ?
                    <div css={caloriesTabStyles.graphTabContent}>
                        {chartData.distribution.length > 1 &&
                            <Card title={t('analytics.charts.caloriesDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.caloriesDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.caloriesDistribution')}
                                    enableOptionsMenu
                                    data={chartData.distribution}
                                    bottomAxisTitle={t('analytics.charts.caloriesDistribution.xAxis')}
                                />
                            </Card>
                        }
                        <Card title={t('analytics.charts.caloriesTrend')}>
                            <LineChart
                                title={t('analytics.charts.caloriesTrend')}
                                unitType={unitType}
                                exportFileName={t('analytics.charts.caloriesTrend')}
                                enableOptionsMenu
                                datasets={chartData.trend}
                                xAxisLabel={t('analytics.charts.xAxis')}
                                yAxisLabel={t('analytics.charts.caloriesTrend.yAxis')}
                            />
                        </Card>
                    </div>
                    :
                    <TableCSF
                        headCells={headCells}
                        rows={table}
                        totalItemsCount={table.length}
                        // paginationSettingsPosition="bottom-left"
                        rowKeySelector={r => r.testStepId}
                        isLoading={false}
                        showAdvancedSearch={false}
                        showExport
                        exportFileNameGenerator={() => `calories-${(new Date()).toISOString().split('T')[0]}`}
                    />
                }
            </div>
            <div css={caloriesTabStyles.tabSelection}>
                {SubTabsTranslationKeysArr.map(x => (
                    <Button
                        key={x.value}
                        variant={$selectedTab.value === x.value ?
                            'contained'
                            : 'text'
                        }
                        onClick={() => {
                            $selectedTab.set(x.value);
                        }}
                    >
                        {t(x.translationKey)}
                    </Button>
                ))}
            </div>
        </div>
    );
};
