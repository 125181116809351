/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { Button } from '../CoreComponents/Button';
import { PrintIcon } from '../CoreComponents/CustomIcons';
import { useTranslation } from '../CoreComponents/Translation';
import { useHook } from '../CoreComponents/Utils';
import { TestService } from '../Services/TestService';
import { UserService } from '../Services/UserService';
import { ReportTable } from './Reports/ReportTable';
import { UnitService } from '../Services/UnitService';
import { Footer, InternalLink } from '../CoreComponents/Layout';
import { monthYearDate, parseDate, shortTime } from '../Utils/Common'
import { CircularLoader } from '../CoreComponents/Loaders';
import { AdminTypes } from '../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { LineChart } from '../CoreComponents/ChartComponents/LineChart';
import { setSessions, setSessionsData, setUnits, setUsers, setUsersData } from '../globalStates/overviewState';

const overviewStyle = {
    root: css`
        height: 100%;
        padding-right: 40px;

        & .print {
            width: 100%;
            text-align: end;
            padding: 20px 32px 20px 40px;
        }

        & .print-action {
            min-width: unset;
            margin-left: 20px;
            margin-right: 10px;
        }

        & .cards-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, 840px);
            grid-template-rows: repeat(auto-fit, 1fr);
            padding: 0 32px 0 80px;
            height: calc(100% - 120px);
            overflow-y: auto;
            gap: 80px;
            justify-content: center;

            & .card {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 8px 16px #28326529;
                border-radius: 8px;
                padding: 40px;
                display: flex;
                flex-direction: column;
                position: relative;
                height: 100%;
                width: 100%;

                & .chart-with-options-container {
                    position: unset;

                    & .options {
                        top: 40px;
                        right: 40px;
                    }
                }

                & > h2 {
                    text-transform: uppercase;
                }

                & > .tabs {
                    display: flex;
                }

                & .chart-container {
                    height: 420px;
                }

                & .chartTitle {
                    margin-top: 40px;
                    margin-bottom: 0;
                    text-transform: none;
                }

                & .link-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    height: 100%;
                    margin-top: auto;
                }

                & table {
                    border-spacing: 0 12px;

                    & tr {
                        box-shadow: none;
                    }
                }
            }

            & .tab {
                display: flex;
                flex-direction: column;
                cursor: pointer;
                height: 172px;
                width: 160px;
                text-align: center;
                border-radius: 8px;

                & > span:first-of-type {
                    text-align: left;
                    font: normal normal bold 24px/24px Mustica Pro;
                    letter-spacing: 0.22px;
                    display: block;
                    width: 100%;
                    padding: 12px 0 13px 20px;
                    border-radius: 8px 8px 0 0;
                    background-color: #FFFFFF;
                    color: #3A817F;
                    text-transform: uppercase;
                }

                & > span:nth-of-type(2) {
                    width: 100%;
                    text-align: left;
                    font: normal normal bold 40px/24px Roboto;
                    letter-spacing: 0.22px;
                    padding-top: 20px;
                    padding-left: 20px;
                    color: #2C2E35;
                    height: 48px;
                }

                & > .fail {
                    font: normal normal bold 24px/24px Mustica Pro;
                    color: #E0758A;
                    margin: 16px auto 21px 20px;
                    height: 31px;
                }

                & > .success {
                    font: normal normal bold 24px/24px Mustica Pro;
                    color: forestgreen;
                    margin: 16px auto 21px 20px;
                    height: 31px;
                }

                &.selected {
                    background: #E0E0E0 0% 0% no-repeat padding-box;

                    & > span:first-of-type {
                        background: #00ABA5 0% 0% no-repeat padding-box;
                        color: #FFFFFF;
                    }
                }
            }

            & > .footer {
                grid-column-start: 1;
                grid-column-end: -1;

            }
        }

        @media print {
            padding: 0;

            & .print {
                display: none;
            }

            & .cards-container {
                display: flex;
                flex-direction: column;
                padding: 0 80px 0 80px;
            }

            & .print-margin{
                page-break-inside: avoid;
            }

            & .print-margin.charts {
                height: 1260px;
            }

            & .card.charts {
                height: 1180px;
            }

            & .card {
                margin-top: 40px;
            }

            & .print-margin {
                width: 100%;
                // height: 920px;
            }
        }
    `,
    link: css`
        font: normal normal normal 16px/24px Roboto;
        letter-spacing: 0.14px;
        color: #00ABA5;
        margin-top: auto;

        @media print {
            display: none;
        }
    `,
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,

}

export const OverviewPage = () => {
    const storeState = useSelector((state) => state.storeState);
    const overviewState = useSelector((state) => state.overviewState);
    const dispatch = useDispatch();
    const $isPrinting = useHook(false);
    const $selectedTab = useHook(1);
    const $sessionsData = useHook(overviewState.sessionsData);
    const $usersData = useHook(overviewState.usersData);
    const $users = useHook(overviewState.users);
    const $units = useHook(overviewState.units);
    const $sessions = useHook(overviewState.sessions);
    const { t } = useTranslation();
    const $isLoading = useHook(!Object.values(overviewState).every(x => x.length));

    const growthUsers = useMemo(() => (
        $usersData.value.length > 1 && $usersData.value[$usersData.value.length - 2].value !== 0 ?
            (($usersData.value[$usersData.value.length - 1].value - $usersData.value[$usersData.value.length - 2].value)
                / $usersData.value[$usersData.value.length - 2].value * 100)
            : 0
    ), [$usersData.value]);

    const growthSessions = useMemo(() => (
        $sessionsData.value.length > 1 && $sessionsData.value[$sessionsData.value.length - 2].value !== 0 ?
            (($sessionsData.value[$sessionsData.value.length - 1].value - $sessionsData.value[$sessionsData.value.length - 2].value)
                / $sessionsData.value[$sessionsData.value.length - 2].value * 100)
            : 0
    ), [$sessionsData.value]);

    const unitHeaders = useMemo(() => ({
        skuNumber: t('units.table.skuNumber'),
        site: t('units.table.siteName')
    }), [t])

    const usersHeaders = useMemo(() => ({
        name: t('users.table.name'),
        username: t('users.table.username'),
        timeAdded: t('users.table.timeAdded'),
        sites: t('users.table.sites')
    }), [t])

    const sessionsHeaders = useMemo(() => ({
        scriptedActivity: t('tests.table.scriptedActivity'),
        username: t('tests.table.username'),
        time: t('tests.table.time'),
        site: t('tests.table.site')
    }), [t])

    useEffect(() => {
        let siteIds = null;
        let customerIds = null

        const isLessThenSuperAdmin = storeState.currentUser.admin.adminTypeId < AdminTypes.SuperAdmin;
        const isTrazerAdmin = storeState.currentUser.admin.adminTypeId > AdminTypes.SuperAdmin;

        if (!isTrazerAdmin) {
            if (isLessThenSuperAdmin) {
                siteIds = storeState.currentUser.admin.adminSites.map(site => site.siteId);
            } else {
                customerIds = [storeState.currentUser.admin.customerId];
            }
        }

        (async () => {
            await Promise.all([
                TestService.getSessionTrends(10, customerIds, siteIds)
                    .then(data => {
                        if (overviewState.sessionsData.length !== data.length) {
                            const mappedData = data.map(x => ({
                                value: x.item2,
                                label: monthYearDate(parseDate(x.item1, 'yy-MM'))
                            }));

                            $sessionsData.set(mappedData);
                            dispatch(setSessionsData(mappedData));
                        }
                    }),
                UserService.countTrends(10, customerIds, siteIds)
                    .then(data => {
                        if (overviewState.usersData.length !== data.length) {
                            const mappedData = data.map(x => ({
                                value: x.item2,
                                label: monthYearDate(parseDate(x.item1, 'yy-MM'))
                            }));
                            $usersData.set(mappedData);
                            dispatch(setUsersData(mappedData));
                        }
                    }),
                UserService.getAll(10, 'DateCreated', null, customerIds, siteIds)
                    .then(users => {
                        if (overviewState.users.length !== users.length) {
                            const mappedUsers = users.map(x => ({
                                name: x.firstName + ' ' + x.lastName, username: x.username,
                                timeAdded: shortTime(x.dateCreated),
                                sites: x.userSites.map(s => s.site.name).join(', ')
                            }));

                            $users.set(mappedUsers);
                            dispatch(setUsers(mappedUsers));
                        }
                    }),
                UnitService.getAll(10, customerIds, siteIds)
                    .then(units => {
                        if (overviewState.units.length !== units.length) {
                            const mappedUnits = units.map(x => ({
                                skuNumber: x.skuNumber || '-',
                                site: x.site.name
                            }));

                            $units.set(mappedUnits);
                            dispatch(setUnits(mappedUnits));
                        }
                    }),
                TestService.getAllTests(10, null, customerIds, siteIds)
                    .then(tests => {
                        const mappedSessions = tests.map(x => ({
                            scriptedActivity: x.scriptedActivityName,
                            username: x.username,
                            time: shortTime(x.startTime),
                            site: x.siteName
                        }));
                        $sessions.set(mappedSessions);
                        dispatch(setSessions(mappedSessions));
                    })
            ])
            $isLoading.set(false);
        })();
    }, [storeState.currentUser])

    return $isLoading.value ?
        <div css={overviewStyle.loadingContainer}>
            <CircularLoader />
        </div>
        : (
            <div css={overviewStyle.root}>
                <div className="print">
                    <Button
                        className="print-action"
                        onClick={() => {
                            $isPrinting.set(true);
                            setTimeout(() => {
                                window.print();
                                $isPrinting.set(false);
                            }, 200)
                        }}
                    >
                        <PrintIcon />
                    </Button>
                </div>
                <div className="cards-container">
                    <div className="print-margin charts">
                        <div className="card charts">
                            <div className="tabs">
                                <div onClick={() => $selectedTab.set(1)} className={$selectedTab.value === 1 ? 'tab selected' : 'tab'}>
                                    <span>{t('overview.section.users')}</span>
                                    <span>
                                        {$usersData.value.length > 1 &&
                                            $usersData.value[$usersData.value.length - 1].value
                                        }
                                    </span>
                                    <span className={growthUsers < 0 ? 'fail' : 'success'}>{growthUsers.toFixed(2)}%</span>
                                </div>
                                <div onClick={() => $selectedTab.set(2)} className={$selectedTab.value === 2 ? 'tab selected' : 'tab'}>
                                    <span>{t('overview.section.sessions')}</span>
                                    <span>
                                        {$sessionsData.value.length > 1 &&
                                            $sessionsData.value[$sessionsData.value.length - 1].value
                                        }
                                    </span>
                                    <span className={growthSessions < 0 ? 'fail' : 'success'}>{growthSessions.toFixed(2)}%</span>
                                </div>
                            </div>
                            {$isPrinting.value ?
                                <>
                                    <h2 className="chartTitle">{t('overview.section.monthlyUsersTrend')}</h2>
                                    <div className="chart-container">
                                        <LineChart
                                            fractionDigits={0}
                                            datasets={[{
                                                label: t('overview.section.users'),
                                                data: $usersData.value
                                            }]}
                                            xAxisLabel={t('overview.section.date')}
                                            yAxisLabel={t('overview.section.users')}
                                        />
                                    </div>
                                    <h2 className="chartTitle">{t('overview.section.monthlySessionsTrend')}</h2>
                                    <div className="chart-container">
                                        <LineChart
                                            fractionDigits={0}
                                            datasets={[{
                                                label: t('overview.section.sessions'),
                                                data: $sessionsData.value
                                            }]}
                                            xAxisLabel={t('overview.section.date')}
                                            yAxisLabel={t('overview.section.sessions')}
                                        />
                                    </div>
                                </>
                                :
                                $selectedTab.value === 1 ?
                                    <>
                                        <h2 className="chartTitle">{t('overview.section.monthlyUsersTrend')}</h2>
                                        <div className="chart-container">
                                            <LineChart
                                                fractionDigits={0}
                                                datasets={[{
                                                    label: t('overview.section.users'),
                                                    data: $usersData.value,
                                                    exportSettings: {
                                                        xAxis: t('overview.chartExportDataField.date'),
                                                        yAxis: t('overview.section.users'),
                                                    },
                                                }]}
                                                title={t('overview.section.monthlyUsersTrend')}
                                                exportFileName={t('overview.section.monthlyUsersTrend')}
                                                xAxisLabel={t('overview.section.date')}
                                                yAxisLabel={t('overview.section.users')}
                                                enableOptionsMenu
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <h2 className="chartTitle">{t('overview.section.monthlySessionsTrend')}</h2>
                                        <div className="chart-container">
                                            <LineChart
                                                fractionDigits={0}
                                                datasets={[{
                                                    label: t('overview.section.sessions'),
                                                    data: $sessionsData.value,
                                                    exportSettings: {
                                                        xAxis: t('overview.chartExportDataField.date'),
                                                        yAxis: t('overview.section.sessions'),
                                                    },
                                                }]}
                                                title={t('overview.section.monthlySessionsTrend')}
                                                exportFileName={t('overview.section.monthlySessionsTrend')}
                                                xAxisLabel={t('overview.section.date')}
                                                yAxisLabel={t('overview.section.sessions')}
                                                enableOptionsMenu
                                            />
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                    <div className="print-margin">
                        <div className="card">
                            <h2>{t('overview.section.units')}</h2>
                            <ReportTable
                                headers={unitHeaders}
                                data={$units.value}
                                color="gray"
                                disableRowHeader
                            />
                            <div className="link-container">
                                <InternalLink css={overviewStyle.link} to="/manage/units">{t('actions.viewAll')}</InternalLink>
                            </div>
                        </div>
                    </div>
                    <div className="print-margin">
                        <div className="card">
                            <h2>{t('overview.section.recentlyAddedUsers')}</h2>
                            <ReportTable
                                headers={usersHeaders}
                                data={$users.value}
                                color="gray"
                                disableRowHeader
                            />
                            <div className="link-container">
                                <InternalLink css={overviewStyle.link} to="/manage/users">{t('actions.viewAll')}</InternalLink>
                            </div>
                        </div>
                    </div>
                    <div className="print-margin">
                        <div className="card">
                            <h2>{t('overview.section.recentSessions')}</h2>
                            <ReportTable
                                headers={sessionsHeaders}
                                data={$sessions.value}
                                color="gray"
                                disableRowHeader
                            />
                            <div className="link-container">
                                <InternalLink css={overviewStyle.link} to="/manage/tests">{t('actions.viewAll')}</InternalLink>
                            </div>
                        </div>
                    </div>
                    <Footer className="footer" />
                </div>
            </div>
        )
};
