import { durationInMinutes, shortDate, shortTime } from '../../../Utils/Common';
import { NoData, TestStatuses, TestStatusesTranslationKeysObj } from '../../../Utils/Constants';

export const CalculatedReactionTimeLeftRightDifference = (left, right, returnAsText = false) => {
    if (!left || !right) {
        return NoData;
    }

    const leftRightDifference = Math.abs(left - right) / Math.max(left, right) * 100;
    return (left === right ?
            '0.00 %'
        : returnAsText ?
            `${leftRightDifference.toFixed(2)} ${left > right ? '% L' : '% R'}`
        :
            <span className={
                leftRightDifference < 10 ? 'low'
                    : leftRightDifference <= 20 ? 'medium'
                    : 'high'
            }>
                {leftRightDifference.toFixed(2)}
                {left > right ? '% L' : '% R'}
            </span>)
    ;
}

export const CalculatedLeftRightDifference = (left, right, returnAsText = false) => {
    if (!left || !right) {
        return NoData;
    }

    const leftRightDifference = Math.abs(left - right) / Math.max(left, right) * 100;
    return (left === right ?
            '0.00 %'
        : returnAsText ?
            `${leftRightDifference.toFixed(2)} ${left > right ? '% R' : '% L'}`
        :
            <span className={
                leftRightDifference < 10 ? 'low'
                    : leftRightDifference <= 20 ? 'medium'
                    : 'high'
            }>
                {leftRightDifference.toFixed(2)}
                {left > right ? '% R' : '% L'}
            </span>)
    ;
}

const CalculatedDifference = (result1, result2, isInverse) => {
    if (!result1 || !result2) {
        return NoData;
    }

    const percentage = ((result2 - result1) / result1) * 100;
    if (percentage === 0) {
        return '0.00 %';
    }

    const viewModel = {};
    if (percentage > 0) {
        viewModel.result = `+${percentage.toFixed(2)} %`;
        viewModel.type = isInverse ? 'degradation' : 'improvement';
    } else {
        viewModel.result = `${percentage.toFixed(2)} %`;
        viewModel.type = isInverse ? 'improvement' : 'degradation';
    }

    return <span className={viewModel.type}>{viewModel.result}</span>;
}

const degreeFormatter = (valueDeg, valueDir) => {
    if (valueDeg == null) {
        return;
    }

    return valueDir ?
        `${valueDeg.toFixed()}\u00B0 ${valueDir}`
        : `${valueDeg.toFixed()}\u00B0`;
};

const buildRadarChartSectionData = (convertUnits, data, { calculatedMappings, ...mapping }, disableUnitConversion) => {
    let hasData = false;
    const chartData = [];
    const tableData = {};
    const keys = Object.keys(mapping);

    for (const key of keys) {
        const value = !data[key] || disableUnitConversion ?
            data[key]?.toFixed(2)
            : convertUnits(data[key]);
        const label = mapping[key];
        chartData.push({ value, label });
        tableData[label] = value;
        hasData ||= Boolean(value);
    }
    for (const key in calculatedMappings) {
        tableData[key] = calculatedMappings[key](data);
    }
    return hasData ? {
        chartData,
        tableData
    } : null;
}

const changeIntervals = (data, dataSelector) => {
    let indexCount = 0;
    let nullCount = 0;
    let averageValue = 0;
    let result = [];
    let hasData = false;

    if (data.length){
        for (const entry of data) {
            const current = dataSelector(entry);
            
            if (current != null) {
                averageValue += current;
            }
            else {
                nullCount++;
            }

            hasData = true;
            indexCount++;
            
            if (indexCount == 20 && nullCount < indexCount) {
                result.push({timeElapsed: (result.length + 1) * 10000, value: averageValue / (indexCount - nullCount)});
                indexCount = 0;
                nullCount = 0;
                averageValue = 0;
            }
            else if (indexCount == 20 && nullCount >= indexCount) {
                result.push({timeElapsed: (result.length + 1) * 10000, value: null});
                indexCount = 0;
                nullCount = 0;
                averageValue = 0;
            }
        }

        if (indexCount > 0 && nullCount < indexCount) {
            result.push({timeElapsed: (result.length + 1) * 10000, value: averageValue / (indexCount - nullCount)});
        }
    }
    
    return hasData ? result : null;
}

const collectTenSecondTimelineData = (data) => {
    let min, max, average, start, end;
    let hasData = false;
    if (data.length) {
        start = data[0].value;
        min = max = start;
        average = 0;

        let validEntriesCount = 0;
        for (const entry of data) {
            const current = entry.value;
            if (current == null) {
                continue;
            }

            hasData = true;
            min = Math.min(min, current);
            max = Math.max(max, current);
            average += current;

            end = current;
            ++validEntriesCount;
        }

        if (validEntriesCount) {
            average /= validEntriesCount;
        }
    }

    return hasData ?
        { min, max, average, start, end }
        : null;
}

const collectTimelineData = (data, dataSelector) => {
    let min, max, average, start, end;
    let hasData = false;
    if (data.length) {
        start = dataSelector(data[0]);
        min = max = start;
        average = 0;

        let validEntriesCount = 0;
        for (const entry of data) {
            const current = dataSelector(entry);
            if (current == null) {
                continue;
            }

            hasData = true;
            min = Math.min(min, current);
            max = Math.max(max, current);
            average += current;

            end = current;
            ++validEntriesCount;
        }

        if (validEntriesCount) {
            average /= validEntriesCount;
        }
    }

    return hasData ?
        { min, max, average, start, end }
        : null;
}

export const PrepareHistoryData = ({
    convertSmallUnits,
    jumpSquatFormatter,
    convertUnits,
    allTests,
}) => {
    let historyData = {};

    function distinctByProperty(arr, prop) {
        const set = new Set();
        return arr.filter(obj => {
            if (!set.has(obj[prop])) {
                set.add(obj[prop]);
                return true;
            }
            return false;
        });
    }

    let SASteps = allTests
    .filter(x => x.testSteps)
    .map(x => x.testSteps && x.testSteps.map(t => ({scriptedActivityStepId: t.scriptedActivityStepId, orderId: t.orderId})))
    .flat();

    SASteps = distinctByProperty(SASteps, 'scriptedActivityStepId');

    for (const SAstep of SASteps) {
        const uniqueStepKey = `${SAstep.scriptedActivityStepId}_${SAstep.orderId}`;
        const currentTestHistoryData = {
            sway: [],
            misses: [],

            totalDistance: [],
            reactionTime: [],
            dynamicReactionTime: [],
            speed: [],
            acceleration: [],
            deceleration: [],

            heartRate: [],

            totalTargetsHit: [],

            initialNeckRotation: [],
            neckRotation: [],
            initialNeckFlexion: [],
            neckFlexion: [],
            initialSpineRotation: [],
            spineRotation: [],
            initialSpineFlexion: [],
            spineFlexion: [],
            initialLRShoulderRotation: [],
            lrShoulderRotation: [],
            initialShoulderFlexion: [],
            shoulderFlexion: [],
            initialShoulderAbduction: [],
            shoulderAbduction: [],
            initialShoulderHorizontalAbduction: [],
            shoulderHorizontalAbduction: [],
            initialElbowFlexion: [],
            elbowFlexion: [],

            initialTorso: [],
            torso: [],
            initialPelvisRotation: [],
            pelvisRotation: [],
            initialSquatDepth: [],
            squatDepth: [],
            jumpHeight: [],
            initialKneeFlexion: [],
            kneeFlexion: [],
            initialKneeValgusOrVarus: [],
            kneeValgusOrVarus: [],
            initialDorsiflexion: [],
            dorsiflexion: [],
            initialShoulderRotation: [],
            shoulderRotation: [],
            initialThoracicRotation: [],
            thoracicRotation: [],
            initialLumbarRotation: [],
            lumbarRotation: [],
            initialHipRotation: [],
            hipRotation: [],
        };
        for (const currentTest of allTests) {
            const step = currentTest.testSteps?.find(x => x.scriptedActivityStepId === SAstep.scriptedActivityStepId);
            if (!step){
                continue;
            }

            const settings = JSON.parse(step.settings);

            const childSettings = settings.TrackedMetrics?.ChildSettings
            const hasTrackedMetrics = settings.TrackedMetrics && settings.TrackedMetrics.SelectedValue.toLowerCase() === 'on' ;
            const currentStepMetrics = {
                trackedReaction: hasTrackedMetrics && childSettings?.ReactionTime.SelectedValue.toLowerCase() === 'on',
                trackedSpeed: hasTrackedMetrics && childSettings?.Speed.SelectedValue.toLowerCase() === 'on',
                trackedAcceleration: hasTrackedMetrics && childSettings?.Acceleration.SelectedValue.toLowerCase() === 'on',
                trackedDeceleration: hasTrackedMetrics && childSettings?.Deceleration.SelectedValue.toLowerCase() === 'on',
                trackedArms: hasTrackedMetrics && childSettings?.Arms.SelectedValue.toLowerCase() === 'on',
                trackedDistance: hasTrackedMetrics && childSettings?.Distance.SelectedValue.toLowerCase() === 'on',
                trackedHeartRate: hasTrackedMetrics && childSettings?.HeartRate.SelectedValue.toLowerCase() === 'on',
                trackedJumpHeight: hasTrackedMetrics && childSettings?.JumpHeight.SelectedValue.toLowerCase() === 'on',
                trackedLegs: hasTrackedMetrics && childSettings?.Legs.SelectedValue.toLowerCase() === 'on',
                trackedMisses: hasTrackedMetrics && childSettings?.Misses.SelectedValue.toLowerCase() === 'on',
                trackedSpine: hasTrackedMetrics && childSettings?.Spine.SelectedValue.toLowerCase() === 'on',
                trackedSquatDepth: hasTrackedMetrics && childSettings?.SquatDepth.SelectedValue.toLowerCase() === 'on',
                trackedSway: hasTrackedMetrics && childSettings?.Sway.SelectedValue.toLowerCase() === 'on',
                trackedTorso: hasTrackedMetrics && childSettings?.Torso.SelectedValue.toLowerCase() === 'on'
            };

            if (!step) {
                continue;
            }

            const date = shortDate(new Date(step.startTime));
            // TODO: Refactor this to be generated before each if statement
            // after it's uploaded and verified
            const stepHistoryData = {
                totalDistance: {
                    label: date,
                    value: step.testResultMobility ?
                    convertUnits(step.testResultMobility?.totalDistance)
                        : step.testResultUpperExtremity?.totalDistance,
                },
                reactionTime: {
                    label: date,
                    value: step.testResultMobility ?
                        step.testResultMobility.unadjustedReactionTimeAvg?.toFixed(2)
                        : step.testResultUpperExtremity?.unadjustedReactionTimeAvg,
                },
                dynamicReactionTime: {
                    label: date,
                    value: step.testResultMobility ?
                        step.testResultMobility.reactionTimeAvg?.toFixed(2)
                        : step.testResultUpperExtremity?.reactionTimeAvg,
                },
                speed: {
                    label: date,
                    value: step.testResultMobility?.speedAvg ?
                        convertUnits(step.testResultMobility?.speedAvg)
                        : step.testResultMobility?.speedAvg,
                },
                acceleration: {
                    label: date,
                    value: step.testResultMobility?.accelerationAvg ?
                        convertUnits(step.testResultMobility?.accelerationAvg)
                        : step.testResultMobility?.accelerationAvg,
                },
                deceleration: {
                    label: date,
                    value: step.testResultMobility?.decelerationAvg ?
                        convertUnits(step.testResultMobility?.decelerationAvg)
                        : step.testResultMobility?.decelerationAvg,
                },
                sway: {
                    label: date,
                    value: step.testResultBalance?.totalSway ?
                        convertUnits(step.testResultBalance?.totalSway)
                        : step.testResultBalance?.totalSway,
                },
                misses: {
                    label: date,
                    value: step.testResultBalance?.totalMisses,
                },
                heartRate: {
                    label: date,
                    value: (
                        step.testResultMobility?.hrAverage
                        || step.testResultUpperExtremity?.hrAverage
                        || step.testResultKinematic?.hrAverage
                        || null
                    )?.toFixed(2),
                },
                totalTargetsHit: {
                    label: date,
                    value: step.testResultUpperExtremity?.totalTargetsHit,
                },
                initialNeckRotation: {
                    label: date,
                    chartValues: {
                        initialNeckRotation: step.testResultKinematic?.initialNeckRotation,
                    },
                    tableValues: {
                        initialNeckRotation: degreeFormatter(step.testResultKinematic?.initialNeckRotation, step.testResultKinematic?.initialNeckRotationDir),
                    },
                },
                neckRotation: {
                    label: date,
                    chartValues: {
                        neckRotation: step.testResultKinematic?.neckRotation,
                    },
                    tableValues: {
                        neckRotation: degreeFormatter(step.testResultKinematic?.neckRotation, step.testResultKinematic?.neckRotationDir),
                    },
                },
                initialNeckFlexion: {
                    label: date,
                    chartValues: {
                        initialNeckFlexion: step.testResultKinematic?.initialNeckFlexion,
                        initialNeckLatFlexion: step.testResultKinematic?.initialNeckLatFlexion,
                    },
                    tableValues: {
                        initialNeckFlexion: degreeFormatter(step.testResultKinematic?.initialNeckFlexion, step.testResultKinematic?.initialNeckFlexionDir),
                        initialNeckLatFlexion: degreeFormatter(step.testResultKinematic?.initialNeckLatFlexion, step.testResultKinematic?.initialNeckLatFlexionDir),
                    },
                },
                neckFlexion: {
                    label: date,
                    chartValues: {
                        neckFlexion: step.testResultKinematic?.neckFlexion,
                        neckLatFlexion: step.testResultKinematic?.neckLatFlexion,
                    },
                    tableValues: {
                        neckFlexion: degreeFormatter(step.testResultKinematic?.neckFlexion, step.testResultKinematic?.neckFlexionDir),
                        neckLatFlexion: degreeFormatter(step.testResultKinematic?.neckLatFlexion, step.testResultKinematic?.neckLatFlexionDir),
                    },
                },
                initialSpineRotation: {
                    label: date,
                    chartValues: {
                        initialSpineRotation: step.testResultKinematic?.initialSpineRotation,
                    },
                    tableValues: {
                        initialSpineRotation: degreeFormatter(step.testResultKinematic?.initialSpineRotation, step.testResultKinematic?.initialSpineRotationDir),
                    },
                },
                spineRotation: {
                    label: date,
                    chartValues: {
                        spineRotation: step.testResultKinematic?.spineRotation,
                    },
                    tableValues: {
                        spineRotation: degreeFormatter(step.testResultKinematic?.spineRotation, step.testResultKinematic?.spineRotationDir),
                    },
                },
                initialSpineFlexion: {
                    label: date,
                    chartValues: {
                        initialSpineFlexion: step.testResultKinematic?.initialSpineFlexion,
                        initialSpineLatFlexion: step.testResultKinematic?.initialSpineLatFlexion,
                    },
                    tableValues: {
                        initialSpineFlexion: degreeFormatter(step.testResultKinematic?.initialSpineFlexion, step.testResultKinematic?.initialSpineFlexionDir),
                        initialSpineLatFlexion: degreeFormatter(step.testResultKinematic?.initialSpineLatFlexion, step.testResultKinematic?.initialSpineLatFlexionDir),
                    },
                },
                spineFlexion: {
                    label: date,
                    chartValues: {
                        spineFlexion: step.testResultKinematic?.spineFlexion,
                        spineLatFlexion: step.testResultKinematic?.spineLatFlexion,
                    },
                    tableValues: {
                        spineFlexion: degreeFormatter(step.testResultKinematic?.spineFlexion, step.testResultKinematic?.spineFlexionDir),
                        spineLatFlexion: degreeFormatter(step.testResultKinematic?.spineLatFlexion, step.testResultKinematic?.spineLatFlexionDir),
                    },
                },
                initialLRShoulderRotation: {
                    label: date,
                    chartValues: {
                        initialLeftShoulderRotation: step.testResultKinematic?.initialLeftShoulderRotation,
                        initialRightShoulderRotation: step.testResultKinematic?.initialRightShoulderRotation,
                    },
                    tableValues: {
                        initialLeftShoulderRotation: degreeFormatter(step.testResultKinematic?.initialLeftShoulderRotation, step.testResultKinematic?.initialLeftShoulderRotationDir),
                        initialRightShoulderRotation: degreeFormatter(step.testResultKinematic?.initialRightShoulderRotation, step.testResultKinematic?.initialRightShoulderRotationDir),
                    },
                },
                lrShoulderRotation: {
                    label: date,
                    chartValues: {
                        leftShoulderRotation: step.testResultKinematic?.leftShoulderRotation,
                        rightShoulderRotation: step.testResultKinematic?.rightShoulderRotation,
                    },
                    tableValues: {
                        leftShoulderRotation: degreeFormatter(step.testResultKinematic?.leftShoulderRotation, step.testResultKinematic?.leftShoulderRotationDir),
                        rightShoulderRotation: degreeFormatter(step.testResultKinematic?.rightShoulderRotation, step.testResultKinematic?.rightShoulderRotationDir),
                    },
                },
                initialShoulderFlexion: {
                    label: date,
                    chartValues: {
                        initialLeftShoulderFlexion: step.testResultKinematic?.initialLeftShoulderFlexion,
                        initialRightShoulderFlexion: step.testResultKinematic?.initialRightShoulderFlexion,
                    },
                    tableValues: {
                        initialLeftShoulderFlexion: degreeFormatter(step.testResultKinematic?.initialLeftShoulderFlexion),
                        initialRightShoulderFlexion: degreeFormatter(step.testResultKinematic?.initialRightShoulderFlexion),
                    },
                },
                shoulderFlexion: {
                    label: date,
                    chartValues: {
                        leftShoulderFlexion: step.testResultKinematic?.leftShoulderFlexion,
                        rightShoulderFlexion: step.testResultKinematic?.rightShoulderFlexion,
                    },
                    tableValues: {
                        leftShoulderFlexion: degreeFormatter(step.testResultKinematic?.leftShoulderFlexion),
                        rightShoulderFlexion: degreeFormatter(step.testResultKinematic?.rightShoulderFlexion),
                    },
                },
                initialShoulderAbduction: {
                    label: date,
                    chartValues: {
                        initialLeftShoulderAbduction: step.testResultKinematic?.initialLeftShoulderAbduction,
                        initialRightShoulderAbduction: step.testResultKinematic?.initialRightShoulderAbduction,
                    },
                    tableValues: {
                        initialLeftShoulderAbduction: degreeFormatter(step.testResultKinematic?.initialLeftShoulderAbduction),
                        initialRightShoulderAbduction: degreeFormatter(step.testResultKinematic?.initialRightShoulderAbduction),
                    },
                },
                shoulderAbduction: {
                    label: date,
                    chartValues: {
                        leftShoulderAbduction: step.testResultKinematic?.leftShoulderAbduction,
                        rightShoulderAbduction: step.testResultKinematic?.rightShoulderAbduction,
                    },
                    tableValues: {
                        leftShoulderAbduction: degreeFormatter(step.testResultKinematic?.leftShoulderAbduction),
                        rightShoulderAbduction: degreeFormatter(step.testResultKinematic?.rightShoulderAbduction),
                    },
                },
                initialShoulderHorizontalAbduction: {
                    label: date,
                    chartValues: {
                        initialLeftShoulderHorizontalAbduction: step.testResultKinematic?.initialLeftShoulderHorizontalAbduction,
                        initialRightShoulderHorizontalAbduction: step.testResultKinematic?.initialRightShoulderHorizontalAbduction,
                    },
                    tableValues: {
                        initialLeftShoulderHorizontalAbduction: degreeFormatter(step.testResultKinematic?.initialLeftShoulderHorizontalAbduction),
                        initialRightShoulderHorizontalAbduction: degreeFormatter(step.testResultKinematic?.initialRightShoulderHorizontalAbduction),
                    },
                },
                shoulderHorizontalAbduction: {
                    label: date,
                    chartValues: {
                        leftShoulderHorizontalAbduction: step.testResultKinematic?.leftShoulderHorizontalAbduction,
                        rightShoulderHorizontalAbduction: step.testResultKinematic?.rightShoulderHorizontalAbduction,
                    },
                    tableValues: {
                        leftShoulderHorizontalAbduction: degreeFormatter(step.testResultKinematic?.leftShoulderHorizontalAbduction),
                        rightShoulderHorizontalAbduction: degreeFormatter(step.testResultKinematic?.rightShoulderHorizontalAbduction),
                    },
                },
                initialElbowFlexion: {
                    label: date,
                    chartValues: {
                        initialLeftElbowFlexion: step.testResultKinematic?.initialLeftElbowFlexion,
                        initialRightElbowFlexion: step.testResultKinematic?.initialRightElbowFlexion,
                    },
                    tableValues: {
                        initialLeftElbowFlexion: degreeFormatter(step.testResultKinematic?.initialLeftElbowFlexion),
                        initialRightElbowFlexion: degreeFormatter(step.testResultKinematic?.initialRightElbowFlexion),
                    },
                },
                elbowFlexion: {
                    label: date,
                    chartValues: {
                        leftElbowFlexion: step.testResultKinematic?.leftElbowFlexion,
                        rightElbowFlexion: step.testResultKinematic?.rightElbowFlexion,
                    },
                    tableValues: {
                        leftElbowFlexion: degreeFormatter(step.testResultKinematic?.leftElbowFlexion),
                        rightElbowFlexion: degreeFormatter(step.testResultKinematic?.rightElbowFlexion),
                    },
                },
                initialTorso: {
                    label: date,
                    chartValues: {
                        initialTrunkLean: step.testResultKinematic?.initialTrunkLeanDeg,
                        initialTrunkLatFlexion: step.testResultKinematic?.initialTrunkLatFlexionDeg,
                    },
                    tableValues: {
                        initialTrunkLean: degreeFormatter(step.testResultKinematic?.initialTrunkLeanDeg, step.testResultKinematic?.initialTrunkLeanDir),
                        initialTrunkLatFlexion: degreeFormatter(step.testResultKinematic?.initialTrunkLatFlexionDeg, step.testResultKinematic?.initialTrunkLatFlexion),
                    },
                },
                torso: {
                    label: date,
                    chartValues: {
                        trunkLean: step.testResultKinematic?.trunkLeanDeg,
                        trunkLatFlexion: step.testResultKinematic?.trunkLatFlexionDeg,
                    },
                    tableValues: {
                        trunkLean: degreeFormatter(step.testResultKinematic?.trunkLeanDeg, step.testResultKinematic?.trunkLeanDir),
                        trunkLatFlexion: degreeFormatter(step.testResultKinematic?.trunkLatFlexionDeg, step.testResultKinematic?.trunkLatFlexion),
                    },
                },
                initialPelvisRotation: {
                    label: date,
                    chartValues: {
                        initialPelvisRotation: step.testResultKinematic?.initialPelvisRotation,
                    },
                    tableValues: {
                        initialPelvisRotation: degreeFormatter(step.testResultKinematic?.initialPelvisRotation, step.testResultKinematic?.initialPelvisRotationDir),
                    },
                },
                pelvisRotation: {
                    label: date,
                    chartValues: {
                        pelvisRotation: step.testResultKinematic?.pelvisRotation,
                    },
                    tableValues: {
                        pelvisRotation: degreeFormatter(step.testResultKinematic?.pelvisRotation, step.testResultKinematic?.pelvisRotationDir),
                    },
                },
                initialSquatDepth: {
                    label: date,
                    chartValues: {
                        initialSquatDepth: step.testResultKinematic?.initialSquatDepth ?
                            convertSmallUnits(step.testResultKinematic?.initialSquatDepth)
                            : step.testResultKinematic?.initialSquatDepth,
                    },
                    tableValues: {
                        initialSquatDepth: step.testResultKinematic?.initialSquatDepth ?
                            jumpSquatFormatter(step.testResultKinematic?.initialSquatDepth)
                            : step.testResultKinematic?.initialSquatDepth,
                    },
                },
                squatDepth: {
                    label: date,
                    chartValues: {
                        squatDepth: step.testResultKinematic?.squatDepth ?
                            convertSmallUnits(step.testResultKinematic?.squatDepth)
                            : step.testResultKinematic?.squatDepth,
                    },
                    tableValues: {
                        squatDepth: step.testResultKinematic?.squatDepth ?
                            jumpSquatFormatter(step.testResultKinematic?.squatDepth)
                            : step.testResultKinematic?.squatDepth,
                    },
                },
                jumpHeight: {
                    label: date,
                    chartValues: {
                        jumpHeight: step.testResultKinematic?.jumpHeight ?
                            convertSmallUnits(step.testResultKinematic?.jumpHeight)
                            : step.testResultKinematic?.jumpHeight,
                    },
                    tableValues: {
                        jumpHeight: step.testResultKinematic?.jumpHeight ?
                            jumpSquatFormatter(step.testResultKinematic?.jumpHeight)
                            : step.testResultKinematic?.jumpHeight,
                    },
                },
                initialKneeFlexion: {
                    label: date,
                    chartValues: {
                        initialLeftKneeFlexion: step.testResultKinematic?.initialLeftKneeFlexion,
                        initialRightKneeFlexion: step.testResultKinematic?.initialRightKneeFlexion,
                    },
                    tableValues: {
                        initialLeftKneeFlexion: degreeFormatter(step.testResultKinematic?.initialLeftKneeFlexion),
                        initialRightKneeFlexion: degreeFormatter(step.testResultKinematic?.initialRightKneeFlexion),
                    },
                },
                kneeFlexion: {
                    label: date,
                    chartValues: {
                        leftKneeFlexion: step.testResultKinematic?.leftKneeFlexion,
                        rightKneeFlexion: step.testResultKinematic?.rightKneeFlexion,
                    },
                    tableValues: {
                        leftKneeFlexion: degreeFormatter(step.testResultKinematic?.leftKneeFlexion),
                        rightKneeFlexion: degreeFormatter(step.testResultKinematic?.rightKneeFlexion),
                    },
                },
                initialKneeValgusOrVarus: {
                    label: date,
                    chartValues: {
                        initialLeftKneeValgusOrVarus: step.testResultKinematic?.initialLeftKneeValgusVarusDeg,
                        initialRightKneeValgusOrVarus: step.testResultKinematic?.initialRightKneeValgusVarusDeg,
                    },
                    tableValues: {
                        initialLeftKneeValgusOrVarus: degreeFormatter(step.testResultKinematic?.initialLeftKneeValgusVarusDeg, step.testResultKinematic?.initialLeftKneeValgusVarus),
                        initialRightKneeValgusOrVarus: degreeFormatter(step.testResultKinematic?.initialRightKneeValgusVarusDeg, step.testResultKinematic?.initialRightKneeValgusVarus),
                    },
                },
                kneeValgusOrVarus: {
                    label: date,
                    chartValues: {
                        leftKneeValgusOrVarus: step.testResultKinematic?.leftKneeValgusVarusDeg,
                        rightKneeValgusOrVarus: step.testResultKinematic?.rightKneeValgusVarusDeg,
                    },
                    tableValues: {
                        leftKneeValgusOrVarus: degreeFormatter(step.testResultKinematic?.leftKneeValgusVarusDeg, step.testResultKinematic?.leftKneeValgusVarus),
                        rightKneeValgusOrVarus: degreeFormatter(step.testResultKinematic?.rightKneeValgusVarusDeg, step.testResultKinematic?.rightKneeValgusVarus),
                    },
                },
                initialDorsiflexion: {
                    label: date,
                    chartValues: {
                        initialLeftAnkleDorsiflexion: step.testResultKinematic?.initialLeftAnkleDorsiflexion,
                        initialRightAnkleDorsiflexion: step.testResultKinematic?.initialRightAnkleDorsiflexion,
                    },
                    tableValues: {
                        initialLeftAnkleDorsiflexion: degreeFormatter(step.testResultKinematic?.initialLeftAnkleDorsiflexion),
                        initialRightAnkleDorsiflexion: degreeFormatter(step.testResultKinematic?.initialRightAnkleDorsiflexion),
                    },
                },
                dorsiflexion: {
                    label: date,
                    chartValues: {
                        leftAnkleDorsiflexion: step.testResultKinematic?.leftAnkleDorsiflexion,
                        rightAnkleDorsiflexion: step.testResultKinematic?.rightAnkleDorsiflexion,
                    },
                    tableValues: {
                        leftAnkleDorsiflexion: degreeFormatter(step.testResultKinematic?.leftAnkleDorsiflexion),
                        rightAnkleDorsiflexion: degreeFormatter(step.testResultKinematic?.rightAnkleDorsiflexion),
                    },
                },
                initialShoulderRotation: {
                    label: date,
                    chartValues: {
                        initialShoulderRotation: step.testResultKinematic?.initialShoulderRotation,
                    },
                    tableValues: {
                        initialShoulderRotation: degreeFormatter(step.testResultKinematic?.initialShoulderRotation, step.testResultKinematic?.initialShoulderRotationDir),
                    },
                },
                shoulderRotation: {
                    label: date,
                    chartValues: {
                        shoulderRotation: step.testResultKinematic?.shoulderRotation,
                    },
                    tableValues: {
                        shoulderRotation: degreeFormatter(step.testResultKinematic?.shoulderRotation, step.testResultKinematic?.shoulderRotationDir),
                    },
                },
                initialThoracicRotation: {
                    label: date,
                    chartValues: {
                        initialThoracicRotation: step.testResultKinematic?.initialThoracicRotation,
                    },
                    tableValues: {
                        initialThoracicRotation: degreeFormatter(step.testResultKinematic?.initialThoracicRotation, step.testResultKinematic?.initialThoracicRotationDir),
                    },
                },
                thoracicRotation: {
                    label: date,
                    chartValues: {
                        thoracicRotation: step.testResultKinematic?.thoracicRotation,
                    },
                    tableValues: {
                        thoracicRotation: degreeFormatter(step.testResultKinematic?.thoracicRotation, step.testResultKinematic?.thoracicRotationDir),
                    },
                },
                initialLumbarRotation: {
                    label: date,
                    chartValues: {
                        initialLumbarRotation: step.testResultKinematic?.initialLumbarRotation,
                    },
                    tableValues: {
                        initialLumbarRotation: degreeFormatter(step.testResultKinematic?.initialLumbarRotation, step.testResultKinematic?.initialLumbarRotationDir),
                    },
                },
                lumbarRotation: {
                    label: date,
                    chartValues: {
                        lumbarRotation: step.testResultKinematic?.lumbarRotation,
                    },
                    tableValues: {
                        lumbarRotation: degreeFormatter(step.testResultKinematic?.lumbarRotation, step.testResultKinematic?.lumbarRotationDir),
                    },
                },
                initialHipRotation: {
                    label: date,
                    chartValues: {
                        initialLeftHipRotation: step.testResultKinematic?.initialLeftHipRotation,
                        initialRightHipRotation: step.testResultKinematic?.initialRightHipRotation,
                    },
                    tableValues: {
                        initialLeftHipRotation: degreeFormatter(step.testResultKinematic?.initialLeftHipRotation, step.testResultKinematic?.initialLeftHipRotationDir),
                        initialRightHipRotation: degreeFormatter(step.testResultKinematic?.initialRightHipRotation, step.testResultKinematic?.initialRightHipRotationDir),
                    },
                },
                hipRotation: {
                    label: date,
                    chartValues: {
                        leftHipRotation: step.testResultKinematic?.leftHipRotation,
                        rightHipRotation: step.testResultKinematic?.rightHipRotation,
                    },
                    tableValues: {
                        leftHipRotation: degreeFormatter(step.testResultKinematic?.leftHipRotation, step.testResultKinematic?.leftHipRotationDir),
                        rightHipRotation: degreeFormatter(step.testResultKinematic?.rightHipRotation, step.testResultKinematic?.rightHipRotationDir),
                    },
                },
                isPartial: step.stepStatus === TestStatuses.Partial
            };

            if (stepHistoryData.sway.value != null && currentStepMetrics.trackedSway) {
                currentTestHistoryData
                    .sway
                    .push({value: stepHistoryData.sway, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.misses.value != null && currentStepMetrics.trackedMisses) {
                currentTestHistoryData
                    .misses
                    .push({value: stepHistoryData.misses, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.totalDistance.value != null && currentStepMetrics.trackedDistance) {
                currentTestHistoryData
                    .totalDistance
                    .push({value: stepHistoryData.totalDistance, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.reactionTime.value != null && currentStepMetrics.trackedReaction) {
                currentTestHistoryData
                    .reactionTime
                    .push({value: stepHistoryData.reactionTime, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.dynamicReactionTime.value != null && currentStepMetrics.trackedReaction) {
                currentTestHistoryData
                    .dynamicReactionTime
                    .push({value: stepHistoryData.dynamicReactionTime, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.speed.value != null && currentStepMetrics.trackedSpeed) {
                currentTestHistoryData
                    .speed
                    .push({value: stepHistoryData.speed, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.acceleration.value != null && currentStepMetrics.trackedAcceleration) {
                currentTestHistoryData
                    .acceleration
                    .push({value: stepHistoryData.acceleration, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.deceleration.value != null && currentStepMetrics.trackedDeceleration) {
                currentTestHistoryData
                    .deceleration
                    .push({value: stepHistoryData.deceleration, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.heartRate.value != null && currentStepMetrics.trackedHeartRate) {
                currentTestHistoryData
                    .heartRate
                    .push({value: stepHistoryData.heartRate, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.totalTargetsHit.value != null) {
                currentTestHistoryData
                    .totalTargetsHit
                    .push({value: stepHistoryData.totalTargetsHit, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.initialNeckRotation.chartValues.initialNeckRotation != null && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .initialNeckRotation
                    .push({value: stepHistoryData.initialNeckRotation, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.neckRotation.chartValues.neckRotation != null && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .neckRotation
                    .push({value: stepHistoryData.neckRotation, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialNeckFlexion.chartValues.initialNeckFlexion != null
                || stepHistoryData.initialNeckFlexion.chartValues.initialNeckLatFlexion != null)
                && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .initialNeckFlexion
                    .push({value: stepHistoryData.initialNeckFlexion, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.neckFlexion.chartValues.neckFlexion != null
                || stepHistoryData.neckFlexion.chartValues.neckLatFlexion != null)
                && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .neckFlexion
                    .push({value: stepHistoryData.neckFlexion, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.initialSpineRotation.chartValues.initialSpineRotation != null && currentStepMetrics.trackedSpine
            ) {
                currentTestHistoryData
                    .initialSpineRotation
                    .push({value: stepHistoryData.initialSpineRotation, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.spineRotation.chartValues.spineRotation != null && currentStepMetrics.trackedSpine
            ) {
                currentTestHistoryData
                    .spineRotation
                    .push({value: stepHistoryData.spineRotation, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialSpineFlexion.chartValues.initialSpineFlexion != null
                || stepHistoryData.initialSpineFlexion.chartValues.initialSpineLatFlexion != null)
                && currentStepMetrics.trackedSpine
            ) {
                currentTestHistoryData
                    .initialSpineFlexion
                    .push({value: stepHistoryData.initialSpineFlexion, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.spineFlexion.chartValues.spineFlexion != null
                || stepHistoryData.spineFlexion.chartValues.spineLatFlexion != null)
                && currentStepMetrics.trackedSpine
            ) {
                currentTestHistoryData
                    .spineFlexion
                    .push({value: stepHistoryData.spineFlexion, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialLRShoulderRotation.chartValues.initialLeftShoulderRotation != null
                || stepHistoryData.initialLRShoulderRotation.chartValues.initialRightShoulderRotation != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .initialLRShoulderRotation
                    .push({value: stepHistoryData.initialLRShoulderRotation, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.lrShoulderRotation.chartValues.leftShoulderRotation != null
                || stepHistoryData.lrShoulderRotation.chartValues.rightShoulderRotation != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .lrShoulderRotation
                    .push({value: stepHistoryData.lrShoulderRotation, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialShoulderFlexion.chartValues.initialLeftShoulderFlexion != null
                || stepHistoryData.initialShoulderFlexion.chartValues.initialRightShoulderFlexion != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .initialShoulderFlexion
                    .push({value: stepHistoryData.initialShoulderFlexion, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.shoulderFlexion.chartValues.leftShoulderFlexion != null
                || stepHistoryData.shoulderFlexion.chartValues.rightShoulderFlexion != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .shoulderFlexion
                    .push({value: stepHistoryData.shoulderFlexion, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialShoulderAbduction.chartValues.initialLeftShoulderAbduction != null
                || stepHistoryData.initialShoulderAbduction.chartValues.initialRightShoulderAbduction != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .initialShoulderAbduction
                    .push({value: stepHistoryData.initialShoulderAbduction, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.shoulderAbduction.chartValues.leftShoulderAbduction != null
                || stepHistoryData.shoulderAbduction.chartValues.rightShoulderAbduction != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .shoulderAbduction
                    .push({value: stepHistoryData.shoulderAbduction, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialShoulderHorizontalAbduction.chartValues.initialLeftShoulderHorizontalAbduction != null
                || stepHistoryData.initialShoulderHorizontalAbduction.chartValues.initialRightShoulderHorizontalAbduction != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .initialShoulderHorizontalAbduction
                    .push({value: stepHistoryData.initialShoulderHorizontalAbduction, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.shoulderHorizontalAbduction.chartValues.leftShoulderHorizontalAbduction != null
                || stepHistoryData.shoulderHorizontalAbduction.chartValues.rightShoulderHorizontalAbduction != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .shoulderHorizontalAbduction
                    .push({value: stepHistoryData.shoulderHorizontalAbduction, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialElbowFlexion.chartValues.initialLeftElbowFlexion != null
                || stepHistoryData.initialElbowFlexion.chartValues.initialRightElbowFlexion != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .initialElbowFlexion
                    .push({value: stepHistoryData.initialElbowFlexion, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.elbowFlexion.chartValues.leftElbowFlexion != null
                || stepHistoryData.elbowFlexion.chartValues.rightElbowFlexion != null)
                && currentStepMetrics.trackedArms
            ) {
                currentTestHistoryData
                    .elbowFlexion
                    .push({value: stepHistoryData.elbowFlexion, isPartial: stepHistoryData.isPartial});
            }
            if (((stepHistoryData.initialTorso.chartValues.initialTrunkLean != null && stepHistoryData.initialTorso.chartValues.initialTrunkLean != 0)
                || (stepHistoryData.initialTorso.chartValues.initialTrunkLatFlexion != null && stepHistoryData.initialTorso.chartValues.initialTrunkLatFlexion != 0))
                && currentStepMetrics.trackedTorso
            ) {
                currentTestHistoryData
                    .initialTorso
                    .push({value: stepHistoryData.initialTorso, isPartial: stepHistoryData.isPartial});
            }
            if (((stepHistoryData.torso.chartValues.trunkLean != null && stepHistoryData.torso.chartValues.trunkLean != 0)
                || (stepHistoryData.torso.chartValues.trunkLatFlexion != null && stepHistoryData.torso.chartValues.trunkLatFlexion != 0))
                && currentStepMetrics.trackedTorso
            ) {
                currentTestHistoryData
                    .torso
                    .push({value: stepHistoryData.torso, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.initialPelvisRotation.chartValues.initialPelvisRotation != null && currentStepMetrics.trackedTorso) {
                currentTestHistoryData
                    .initialPelvisRotation
                    .push({value: stepHistoryData.initialPelvisRotation, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.pelvisRotation.chartValues.pelvisRotation != null && currentStepMetrics.trackedTorso) {
                currentTestHistoryData
                    .pelvisRotation
                    .push({value: stepHistoryData.pelvisRotation, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.initialSquatDepth.chartValues.initialSquatDepth != null && currentStepMetrics.trackedTorso) {
                currentTestHistoryData
                    .initialSquatDepth
                    .push({value: stepHistoryData.initialSquatDepth, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.squatDepth.chartValues.squatDepth != null && currentStepMetrics.trackedTorso) {
                currentTestHistoryData
                    .squatDepth
                    .push({value: stepHistoryData.squatDepth, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.jumpHeight.chartValues.jumpHeight != null && currentStepMetrics.trackedTorso) {
                currentTestHistoryData
                    .jumpHeight
                    .push({value: stepHistoryData.jumpHeight, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialKneeFlexion.chartValues.initialLeftKneeFlexion != null
                || stepHistoryData.initialKneeFlexion.chartValues.initialRightKneeFlexion != null)
                && currentStepMetrics.trackedLegs
            ) {
                currentTestHistoryData
                    .initialKneeFlexion
                    .push({value: stepHistoryData.initialKneeFlexion, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.kneeFlexion.chartValues.leftKneeFlexion != null
                || stepHistoryData.kneeFlexion.chartValues.rightKneeFlexion != null)
                && currentStepMetrics.trackedLegs
            ) {
                currentTestHistoryData
                    .kneeFlexion
                    .push({value: stepHistoryData.kneeFlexion, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialKneeValgusOrVarus.chartValues.initialLeftKneeValgusOrVarus != null
                || stepHistoryData.initialKneeValgusOrVarus.chartValues.initialRightKneeValgusOrVarus != null)
                && currentStepMetrics.trackedLegs
            ) {
                currentTestHistoryData
                    .initialKneeValgusOrVarus
                    .push({value: stepHistoryData.initialKneeValgusOrVarus, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.kneeValgusOrVarus.chartValues.leftKneeValgusOrVarus != null
                || stepHistoryData.kneeValgusOrVarus.chartValues.rightKneeValgusOrVarus != null)
                && currentStepMetrics.trackedLegs
            ) {
                currentTestHistoryData
                    .kneeValgusOrVarus
                    .push({value: stepHistoryData.kneeValgusOrVarus, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialDorsiflexion.chartValues.initialLeftAnkleDorsiflexion != null
                || stepHistoryData.initialDorsiflexion.chartValues.initialRightAnkleDorsiflexion != null)
                && currentStepMetrics.trackedLegs
            ) {
                currentTestHistoryData
                    .initialDorsiflexion
                    .push({value: stepHistoryData.initialDorsiflexion, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.dorsiflexion.chartValues.leftAnkleDorsiflexion != null
                || stepHistoryData.dorsiflexion.chartValues.rightAnkleDorsiflexion != null)
                && currentStepMetrics.trackedLegs
            ) {
                currentTestHistoryData
                    .dorsiflexion
                    .push({value: stepHistoryData.dorsiflexion, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.initialShoulderRotation.chartValues.initialShoulderRotation != null && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .initialShoulderRotation
                    .push({value: stepHistoryData.initialShoulderRotation, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.shoulderRotation.chartValues.shoulderRotation != null && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .shoulderRotation
                    .push({value: stepHistoryData.shoulderRotation, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.initialThoracicRotation.chartValues.initialThoracicRotation != null && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .initialThoracicRotation
                    .push({value: stepHistoryData.initialThoracicRotation, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.thoracicRotation.chartValues.thoracicRotation != null && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .thoracicRotation
                    .push({value: stepHistoryData.thoracicRotation, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.initialLumbarRotation.chartValues.initialLumbarRotation != null && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .initialLumbarRotation
                    .push({value: stepHistoryData.initialLumbarRotation, isPartial: stepHistoryData.isPartial});
            }
            if (stepHistoryData.lumbarRotation.chartValues.lumbarRotation != null && currentStepMetrics.trackedSpine) {
                currentTestHistoryData
                    .lumbarRotation
                    .push({value: stepHistoryData.lumbarRotation, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.initialHipRotation.chartValues.initialLeftHipRotation != null
                || stepHistoryData.initialHipRotation.chartValues.initialRightHipRotation != null)
                && currentStepMetrics.trackedLegs
            ) {
                currentTestHistoryData
                    .initialHipRotation
                    .push({value: stepHistoryData.initialHipRotation, isPartial: stepHistoryData.isPartial});
            }
            if ((stepHistoryData.hipRotation.chartValues.leftHipRotation != null
                || stepHistoryData.hipRotation.chartValues.rightHipRotation != null)
                && currentStepMetrics.trackedLegs
            ) {
                currentTestHistoryData
                    .hipRotation
                    .push({value: stepHistoryData.hipRotation, isPartial: stepHistoryData.isPartial});
            }
            currentTestHistoryData.isPartial = stepHistoryData.isPartial;
        }
        if (Object.keys(currentTestHistoryData).some(x => currentTestHistoryData[x].length > 0)) {
            historyData = {
                ...historyData,
                [uniqueStepKey]: currentTestHistoryData,
            };
        }

    }
    return historyData
}

export const PrepareTestData = ({
    t,
    convertUnits,
    getBalanceMissDetailedMessage,
    distanceFormatter,
    speedFormatter,
    accelerationFormatter,
    jumpSquatFormatter,
    allScriptedActivitySteps,
    selectedTests,
    selectedTimelineInterval,
    isCompareSteps
}) => {
    const scriptedActivitySteps = allScriptedActivitySteps
        .reduce((a, cV) => ({ ...a, [cV.scriptedActivityStepId]: cV}), {});

    const availableSteps = {};
    let firstAvailableStep = null;
    const balanceMissesTypes = {};
    for (const currentTest of selectedTests) {
        for (const testStep of currentTest.testSteps) {
            availableSteps[testStep.scriptedActivityStepId] = 1;
            if (!firstAvailableStep) {
                firstAvailableStep = testStep.scriptedActivityStepId;
            }

            const balanceMisses = testStep.testResultBalanceMisses;
            if (balanceMisses) {
                const uniqueStepKey = `${testStep.scriptedActivityStepId}_${testStep.orderId}`;
                if (!balanceMissesTypes[uniqueStepKey]) {
                    balanceMissesTypes[uniqueStepKey] = {};
                }

                for (const missEntry of balanceMisses) {
                    balanceMissesTypes[uniqueStepKey][missEntry.missType] = 0;
                }
            }
        }
    }

    const selectedTestsReportData = [];
    const testInfos = [];
    const hasTimelineDataBySAStepId = {};
    const isInDiffMode = selectedTests.length === 2;
    const diffTestResult = {
        hasMobilityData: false,
        hasBalanceData: false,
        hasBalanceMissesData: false,
        hasMobilityTimelineData: false,
        hasUpperExtremityData: false,
        hasCognitiveData: false,
        scriptedActivityStepId: 0,
        orderId: 0,
        steps: {}
    };
    let testIndex = 0;
    let trackedMetrics = {};
    let prevTest = null;
    for (const currentTest of selectedTests) {
        ++testIndex;
        const testName = currentTest.isBaseline ?
            t('testReport.testNameBaseline')
            : (isCompareSteps ? t('testReport.stepNamePattern', currentTest.testSteps[testIndex - 1].orderId)
                : t('testReport.testNamePattern', testIndex));

        const currentTestResult = {
            hasMobilityData: false,
            hasBalanceData: false,
            hasBalanceMissesData: false,
            hasMobilityTimelineData: false,
            hasUpperExtremityData: false,
            hasCognitiveData: false,
            scriptedActivityStepId: 0,
            orderId: 0,
            steps: {}
        };

        const currentTestInfo = {
            testId: currentTest.testId,
            testName,
            username: currentTest.user.username,
            userFullName: `${currentTest.user.firstName} ${currentTest.user.lastName}`,
            medicalRecordNumber: currentTest.user.medicalRecordNumber,
            providerFullName: currentTest.provider ? `${currentTest.provider.firstName} ${currentTest.provider.lastName}` : null,
            startTime: shortTime(currentTest.startTime),
            duration: durationInMinutes(currentTest.duration),
            siteName: currentTest.site.name,
            testStatus: t(TestStatusesTranslationKeysObj[currentTest.testStatus]),
            activityName: currentTest.scriptedActivity.name,
            testNotes: currentTest.testNote ? {
                ...currentTest.testNote,
                dateCreated: shortTime(currentTest.testNote.dateCreated),
                dateModified: shortTime(currentTest.testNote.dateModified),
            }
            :
            null,
            testRef: currentTest,
        };

        testInfos.push(currentTestInfo);

        for (const testStep of currentTest.testSteps) {
            if (!testStep) {
                console.warn('Should never get here!');
                continue;
            }

            const scriptedActivityStep = scriptedActivitySteps[testStep.scriptedActivityStepId];
            const uniqueStepKey = `${testStep.scriptedActivityStepId}_${testStep.orderId}`;
            const settings = JSON.parse(scriptedActivityStep.settings);

            const childSettings = settings.TrackedMetrics?.ChildSettings
            const hasTrackedMetrics = settings.TrackedMetrics && settings.TrackedMetrics.SelectedValue.toLowerCase() === 'on' ;
            const currentStepMetrics = {
                trackedReaction: hasTrackedMetrics && childSettings?.ReactionTime.SelectedValue.toLowerCase() === 'on',
                trackedSpeed: hasTrackedMetrics && childSettings?.Speed.SelectedValue.toLowerCase() === 'on',
                trackedAcceleration: hasTrackedMetrics && childSettings?.Acceleration.SelectedValue.toLowerCase() === 'on',
                trackedDeceleration: hasTrackedMetrics && childSettings?.Deceleration.SelectedValue.toLowerCase() === 'on',
                trackedArms: hasTrackedMetrics && childSettings?.Arms.SelectedValue.toLowerCase() === 'on',
                trackedDistance: hasTrackedMetrics && childSettings?.Distance.SelectedValue.toLowerCase() === 'on',
                trackedHeartRate: hasTrackedMetrics && childSettings?.HeartRate.SelectedValue.toLowerCase() === 'on',
                trackedJumpHeight: hasTrackedMetrics && childSettings?.JumpHeight.SelectedValue.toLowerCase() === 'on',
                trackedLegs: hasTrackedMetrics && childSettings?.Legs.SelectedValue.toLowerCase() === 'on',
                trackedMisses: hasTrackedMetrics && childSettings?.Misses.SelectedValue.toLowerCase() === 'on',
                trackedSpine: hasTrackedMetrics && childSettings?.Spine.SelectedValue.toLowerCase() === 'on',
                trackedSquatDepth: hasTrackedMetrics && childSettings?.SquatDepth.SelectedValue.toLowerCase() === 'on',
                trackedSway: hasTrackedMetrics && childSettings?.Sway.SelectedValue.toLowerCase() === 'on',
                trackedTorso: hasTrackedMetrics && childSettings?.Torso.SelectedValue.toLowerCase() === 'on'
            };
            trackedMetrics[uniqueStepKey] = currentStepMetrics;

            const diffReportData = {
                testId: null, // TODO: remove this
                orderId: testStep.orderId,
                testName: t('testReport.testNameDifference'),
            };
            let prevStep = null;
            if (isInDiffMode && prevTest) {
                prevStep = prevTest.testSteps
                    .find(x => x.scriptedActivityStepId === testStep.scriptedActivityStepId);
            }
            const testReportData = {
                testId: currentTest.testId,
                testName
            };

            const balance = testStep.testResultBalance;
            if (balance) {
                const balanceMap = {
                    swayForward: t('testReport.fieldName.forward'),
                    swayForwardRight: t('testReport.fieldName.forwardRight'),
                    swayRight: t('testReport.fieldName.right'),
                    swayBackwardRight: t('testReport.fieldName.backwardRight'),
                    swayBackward: t('testReport.fieldName.backward'),
                    swayBackwardLeft: t('testReport.fieldName.backwardLeft'),
                    swayLeft: t('testReport.fieldName.left'),
                    swayForwardLeft: t('testReport.fieldName.forwardLeft'),
                };
                balanceMap.calculatedMappings = {
                    [t('testReport.fieldName.totalSway')]:
                        (data, disableUnitConversion = false) =>
                            disableUnitConversion ?
                                Object.keys(balanceMap).reduce((a, c) => a + (data[c] || 0), 0)
                                : convertUnits(Object.keys(balanceMap).reduce((a, c) => a + (data[c] || 0), 0))
                };
                testReportData.chartBalanceSwayData = buildRadarChartSectionData(convertUnits, balance, balanceMap);

                if (prevStep && prevStep.testResultBalance) {
                    const tableData = {};
                    for (const key in balanceMap) {
                        if (key !== 'calculatedMappings') {
                            tableData[balanceMap[key]] = CalculatedDifference(prevStep.testResultBalance[key], balance[key], true);
                            continue;
                        }

                        const calculatedMappings = balanceMap[key];
                        for (const k in calculatedMappings) {
                            tableData[k] =
                                CalculatedDifference(
                                    calculatedMappings[k](prevStep.testResultBalance, true),
                                    calculatedMappings[k](balance, true),
                                    true
                                );
                        }
                    }
                    diffReportData.chartBalanceSwayData = {
                        chartData: null,
                        tableData
                    };
                    diffReportData.hasBalanceData = true;
                }
            }

            const balanceMisses = testStep.testResultBalanceMisses;
            if (balanceMisses) {
                const missData = {
                    ...balanceMissesTypes[uniqueStepKey]
                };
                for (const missEntry of balanceMisses) {
                    if (missData[missEntry.missType] == null) {
                        missData[missEntry.missType] = 0;
                        console.warn('should never have missing type');
                    }

                    ++missData[missEntry.missType];
                }

                testReportData.balanceMissesData = {
                    ...Object.keys(missData)
                        .reduce(
                            (a, c) => ({ ...a, [getBalanceMissDetailedMessage(c)]: missData[c] }),
                            { rowName: testName }
                        ),
                    Total: Object.values(missData).reduce((a, c) => a + c, 0)
                }
            } else if (balanceMissesTypes[uniqueStepKey]) {
                const missData = balanceMissesTypes[uniqueStepKey];
                testReportData.balanceMissesData = {
                    ...Object.keys(missData)
                        .reduce(
                            (a, c) => ({ ...a, [getBalanceMissDetailedMessage(c)]: missData[c] }),
                            { rowName: testName }
                        ),
                    Total: 0
                };
            }

            const isMobilityTrackingEnabled = currentStepMetrics.trackedReaction
                || currentStepMetrics.trackedSpeed
                || currentStepMetrics.trackedAcceleration
                || currentStepMetrics.trackedDeceleration;
            const mobility = testStep.testResultMobility;
            if (mobility) {
                const reactionTimeMap = {
                    // reactionTimeMin: 'Min',
                    // reactionTimeAvg: 'Avg',
                    unadjustedReactionTimeForward: t('testReport.fieldName.forward'),
                    unadjustedReactionTimeForwardRight: t('testReport.fieldName.forwardRight'),
                    unadjustedReactionTimeRight: t('testReport.fieldName.right'),
                    unadjustedReactionTimeBackwardRight: t('testReport.fieldName.backwardRight'),
                    unadjustedReactionTimeBackward: t('testReport.fieldName.backward'),
                    unadjustedReactionTimeBackwardLeft: t('testReport.fieldName.backwardLeft'),
                    unadjustedReactionTimeLeft: t('testReport.fieldName.left'),
                    unadjustedReactionTimeForwardLeft: t('testReport.fieldName.forwardLeft'),
                    // reactionTimeLrdiff: 'Lrdiff',
                    // reactionTimeLrdir: 'Lrdir',
                    calculatedMappings: {
                        [t('testReport.fieldName.lrDifference')]: x => CalculatedReactionTimeLeftRightDifference(x.unadjustedReactionTimeLeft, x.unadjustedReactionTimeRight),
                        [t('testReport.fieldName.flFRDifference')]: x => CalculatedReactionTimeLeftRightDifference(x.unadjustedReactionTimeForwardLeft, x.unadjustedReactionTimeForwardRight),
                        [t('testReport.fieldName.blBRDifference')]: x => CalculatedReactionTimeLeftRightDifference(x.unadjustedReactionTimeBackwardLeft, x.unadjustedReactionTimeBackwardRight),
                    }
                };
                const dynamicReactionTimeMap = {
                    // reactionTimeMin: 'Min',
                    // reactionTimeAvg: 'Avg',
                    reactionTimeForward: t('testReport.fieldName.forward'),
                    reactionTimeForwardRight: t('testReport.fieldName.forwardRight'),
                    reactionTimeRight: t('testReport.fieldName.right'),
                    reactionTimeBackwardRight: t('testReport.fieldName.backwardRight'),
                    reactionTimeBackward: t('testReport.fieldName.backward'),
                    reactionTimeBackwardLeft: t('testReport.fieldName.backwardLeft'),
                    reactionTimeLeft: t('testReport.fieldName.left'),
                    reactionTimeForwardLeft: t('testReport.fieldName.forwardLeft'),
                    // reactionTimeLrdiff: 'Lrdiff',
                    // reactionTimeLrdir: 'Lrdir',
                    calculatedMappings: {
                        [t('testReport.fieldName.lrDifference')]: x => CalculatedReactionTimeLeftRightDifference(x.reactionTimeLeft, x.reactionTimeRight),
                        [t('testReport.fieldName.flFRDifference')]: x => CalculatedReactionTimeLeftRightDifference(x.reactionTimeForwardLeft, x.reactionTimeForwardRight),
                        [t('testReport.fieldName.blBRDifference')]: x => CalculatedReactionTimeLeftRightDifference(x.reactionTimeBackwardLeft, x.reactionTimeBackwardRight),
                    }
                };
                if (currentStepMetrics.trackedReaction) {
                    testReportData.chartReactionTimeData = buildRadarChartSectionData(convertUnits, mobility, reactionTimeMap, true);
                    testReportData.chartDynamicReactionTimeData = buildRadarChartSectionData(convertUnits, mobility, dynamicReactionTimeMap, true);
                }

                const speedMap = {
                    // speedMin: 'Min',
                    // speedAvg: 'Avg',
                    speedForward: t('testReport.fieldName.forward'),
                    speedForwardRight: t('testReport.fieldName.forwardRight'),
                    speedRight: t('testReport.fieldName.right'),
                    speedBackwardRight: t('testReport.fieldName.backwardRight'),
                    speedBackward: t('testReport.fieldName.backward'),
                    speedBackwardLeft: t('testReport.fieldName.backwardLeft'),
                    speedLeft: t('testReport.fieldName.left'),
                    speedForwardLeft: t('testReport.fieldName.forwardLeft'),
                    // speedLrdiff: 'Lrdiff',
                    // speedLrdir: 'Lrdir',
                    calculatedMappings: {
                        [t('testReport.fieldName.lrDifference')]: x => CalculatedLeftRightDifference(x.speedLeft, x.speedRight),
                        [t('testReport.fieldName.flFRDifference')]: x => CalculatedLeftRightDifference(x.speedForwardLeft, x.speedForwardRight),
                        [t('testReport.fieldName.blBRDifference')]: x => CalculatedLeftRightDifference(x.speedBackwardLeft, x.speedBackwardRight),
                    }
                };
                if (currentStepMetrics.trackedSpeed) {
                    testReportData.chartSpeedData = buildRadarChartSectionData(convertUnits, mobility, speedMap);
                }

                const accelerationMap = {
                    // accelerationMin: 'Min',
                    // accelerationAvg: 'Avg',
                    accelerationForward: t('testReport.fieldName.forward'),
                    accelerationForwardRight: t('testReport.fieldName.forwardRight'),
                    accelerationRight: t('testReport.fieldName.right'),
                    accelerationBackwardRight: t('testReport.fieldName.backwardRight'),
                    accelerationBackward: t('testReport.fieldName.backward'),
                    accelerationBackwardLeft: t('testReport.fieldName.backwardLeft'),
                    accelerationLeft: t('testReport.fieldName.left'),
                    accelerationForwardLeft: t('testReport.fieldName.forwardLeft'),
                    // accelerationLrdiff: 'Lrdiff',
                    // accelerationLrdir: 'Lrdir',
                    calculatedMappings: {
                        [t('testReport.fieldName.lrDifference')]: x => CalculatedLeftRightDifference(x.accelerationLeft, x.accelerationRight),
                        [t('testReport.fieldName.flFRDifference')]: x => CalculatedLeftRightDifference(x.accelerationForwardLeft, x.accelerationForwardRight),
                        [t('testReport.fieldName.blBRDifference')]: x => CalculatedLeftRightDifference(x.accelerationBackwardLeft, x.accelerationBackwardRight),
                    }
                };

                if (currentStepMetrics.trackedAcceleration) {
                    testReportData.chartAccelerationData = buildRadarChartSectionData(convertUnits, mobility, accelerationMap);
                }

                const decelerationMap = {
                    // decelerationMin: 'Min',
                    // decelerationAvg: 'Avg',
                    decelerationForward: t('testReport.fieldName.forward'),
                    decelerationForwardRight: t('testReport.fieldName.forwardRight'),
                    decelerationRight: t('testReport.fieldName.right'),
                    decelerationBackwardRight: t('testReport.fieldName.backwardRight'),
                    decelerationBackward: t('testReport.fieldName.backward'),
                    decelerationBackwardLeft: t('testReport.fieldName.backwardLeft'),
                    decelerationLeft: t('testReport.fieldName.left'),
                    decelerationForwardLeft: t('testReport.fieldName.forwardLeft'),
                    // decelerationLrdiff: 'Lrdiff',
                    // decelerationLrdir: 'Lrdir',
                    calculatedMappings: {
                        [t('testReport.fieldName.lrDifference')]: x => CalculatedLeftRightDifference(x.decelerationLeft, x.decelerationRight),
                        [t('testReport.fieldName.flFRDifference')]: x => CalculatedLeftRightDifference(x.decelerationForwardLeft, x.decelerationForwardRight),
                        [t('testReport.fieldName.blBRDifference')]: x => CalculatedLeftRightDifference(x.decelerationBackwardLeft, x.decelerationBackwardRight),
                    }
                };

                if (currentStepMetrics.trackedDeceleration) {
                    testReportData.chartDecelerationData = buildRadarChartSectionData(convertUnits, mobility, decelerationMap);
                }

                testReportData.performance = {
                    rowName: testName,
                    averageJumpHeight: currentStepMetrics.trackedJumpHeight && jumpSquatFormatter(mobility.jumpHeightAvg),
                    averageSquatDepth: currentStepMetrics.trackedSquatDepth && jumpSquatFormatter(mobility.squatHeightAvg),
                    averageReactionTime: currentStepMetrics.trackedReaction && mobility.unadjustedReactionTimeAvg != null ?
                        `${mobility.unadjustedReactionTimeAvg.toFixed(2)} ${t('constants.units.secondsShort')}`
                        : null,
                    averageDynamicReactionTime: currentStepMetrics.trackedReaction && mobility.reactionTimeAvg != null ?
                        `${mobility.reactionTimeAvg.toFixed(2)} ${t('constants.units.secondsShort')}`
                        : null,
                    averageSpeed: currentStepMetrics.trackedSpeed && mobility.speedAvg != null ?
                        speedFormatter(mobility.speedAvg, 2)
                        : null,
                    averageAcceleration: currentStepMetrics.trackedAcceleration && mobility.accelerationAvg != null ?
                        accelerationFormatter(mobility.accelerationAvg, 2)
                        : null,
                    averageDeceleration: currentStepMetrics.trackedDeceleration && mobility.decelerationAvg != null ?
                        accelerationFormatter(mobility.decelerationAvg, 2)
                        : null,
                    averageHR: currentStepMetrics.trackedHeartRate &&  mobility.hrAverage ? mobility.hrAverage.toFixed(2) : null,
                };

                if (prevStep && prevStep.testResultMobility) {
                    const prevMobility = prevStep.testResultMobility;
                    const buildMobilityData = ({ calculatedMappings, ...mapping }, leftData, rightData, isInverse = false) => {
                        const tableData = {};
                        let hasData = false;
                        for (const key in mapping) {
                            const value = CalculatedDifference(leftData[key], rightData[key], isInverse);
                            tableData[mapping[key]] = value;
                            hasData ||= Boolean(leftData[key] || rightData[key]);
                        }

                        return !hasData ? null : {
                            chartData: null,
                            tableData
                        };
                    };

                    if (currentStepMetrics.trackedReaction) {
                        diffReportData.chartReactionTimeData = buildMobilityData(reactionTimeMap, prevMobility, mobility, true);
                        diffReportData.chartDynamicReactionTimeData = buildMobilityData(dynamicReactionTimeMap, prevMobility, mobility, true);
                    }
                    if (currentStepMetrics.trackedSpeed) {
                        diffReportData.chartSpeedData = buildMobilityData(speedMap, prevMobility, mobility);
                    }
                    if (currentStepMetrics.trackedAcceleration) {
                        diffReportData.chartAccelerationData = buildMobilityData(accelerationMap, prevMobility, mobility);
                    }
                    if (currentStepMetrics.trackedDeceleration) {
                        diffReportData.chartDecelerationData = buildMobilityData(decelerationMap, prevMobility, mobility);
                    }
                    diffReportData.performance = {
                        rowName: t('testReport.testNameDifference'),
                        averageReactionTime: CalculatedDifference(currentStepMetrics.trackedReaction && prevMobility.unadjustedReactionTimeAvg, mobility.unadjustedReactionTimeAvg, true),
                        averageDynamicReactionTime: CalculatedDifference(currentStepMetrics.trackedReaction && prevMobility.reactionTimeAvg, mobility.reactionTimeAvg, true),
                        averageSpeed: CalculatedDifference(currentStepMetrics.trackedSpeed && prevMobility.speedAvg, mobility.speedAvg),
                        averageAcceleration: CalculatedDifference(currentStepMetrics.trackedAcceleration && prevMobility.accelerationAvg, mobility.accelerationAvg),
                        averageDeceleration: CalculatedDifference(currentStepMetrics.trackedDeceleration && prevMobility.decelerationAvg, mobility.decelerationAvg),
                        averageHR: CalculatedDifference(currentStepMetrics.trackedHeartRate && prevMobility.hrAverage, mobility.hrAverage),
                        averageJumpHeight: CalculatedDifference(currentStepMetrics.trackedJumpHeight && prevMobility.jumpHeightAvg, mobility.jumpHeightAvg),
                        averageSquatDepth: CalculatedDifference(currentStepMetrics.trackedSquatDepth && prevMobility.squatHeightAvg, mobility.squatHeightAvg),
                    }
                    diffReportData.hasMobilityData = true;
                }

                const summaryData = Object.fromEntries(
                    Object.entries({
                        scriptedActivityStepName: scriptedActivityStep.name,
                        duration: testStep.duration ? durationInMinutes(testStep.duration) : NoData,
                        targets: mobility.score,
                        distance: distanceFormatter(mobility.totalDistance),
                        decelerationIndex: mobility.decelerationMax && mobility.accelerationMax && mobility.accelerationMax != 0 ? mobility.decelerationMax / mobility.accelerationMax : NoData,
                        calories: mobility.calories ? mobility.calories.toFixed(2) : null,
                    }).map(([name, value]) => [
                        name,
                        typeof value == 'number' ?
                            value.toFixed(2)
                            : value || NoData
                    ])
                );

                testReportData.summary = summaryData;
            }

            const mobilityTimelines = isMobilityTrackingEnabled ? testStep.testResultMobilityTimelines : null;
            if (mobilityTimelines) {
                const timelineTableDataMap = {
                    start: t('testReport.fieldName.start'),
                    min: t('testReport.fieldName.min'),
                    max: t('testReport.fieldName.max'),
                    average: t('testReport.fieldName.average'),
                    end: t('testReport.fieldName.end'),
                };
                // let hasTimelineDataBySAStepId = false;
                const buildTimelineSectionData = (data, dataSelector, dataMap, disableUnitConversion) => {
                    if (selectedTimelineInterval == '0.5s' || (data.length && data[0].timeElapsed > 2000)) {
                        const tableData = collectTimelineData(data, dataSelector);
                        return {
                            chartData: data.map(mt => {
                                const value = dataSelector(mt);
                                return {
                                    value: !value || disableUnitConversion ?
                                        value?.toFixed(2)
                                        : convertUnits(value),
                                    label: mt.timeElapsed / 1000
                                };
                            }),
                            tableData: tableData &&
                                Object
                                    .keys(dataMap)
                                    .reduce((a, currentKey) => ({
                                        ...a,
                                        [dataMap[currentKey]]: (
                                                !tableData[currentKey] || disableUnitConversion ?
                                                    tableData[currentKey]?.toFixed(2)
                                                    : convertUnits(tableData[currentKey], 2)
                                            ) ?? NoData
                                    }), {})
                        };
                    }
                    else {
                        const updatedData = changeIntervals(data, dataSelector);
                        const tableData = collectTenSecondTimelineData(updatedData);
                        return {
                            chartData: updatedData.map(mt => {
                                const value = mt.value;
                                return {
                                    value: !value || disableUnitConversion ?
                                        value?.toFixed(2)
                                        : convertUnits(value),
                                    label: mt.timeElapsed / 1000
                                };
                            }),
                            tableData: tableData &&
                                Object
                                    .keys(dataMap)
                                    .reduce((a, currentKey) => ({
                                        ...a,
                                        [dataMap[currentKey]]: (
                                                !tableData[currentKey] || disableUnitConversion ?
                                                    tableData[currentKey]?.toFixed(2)
                                                    : convertUnits(tableData[currentKey], 2)
                                            ) ?? NoData
                                    }), {})
                        };
                    }
                };
                if (currentStepMetrics.trackedReaction) {
                    testReportData.chartReactionTimeTimelineData = buildTimelineSectionData(mobilityTimelines, x => x.unadjustedReaction, timelineTableDataMap, true);
                    testReportData.chartDynamicReactionTimeTimelineData = buildTimelineSectionData(mobilityTimelines, x => x.reaction, timelineTableDataMap, true);
                }
                if (currentStepMetrics.trackedSpeed) {
                    testReportData.chartSpeedTimelineData = buildTimelineSectionData(mobilityTimelines, x => x.speed, timelineTableDataMap);
                }
                if (currentStepMetrics.trackedAcceleration) {
                    testReportData.chartAccelerationTimelineData = buildTimelineSectionData(mobilityTimelines, x => x.acceleration, timelineTableDataMap);
                }
                if (currentStepMetrics.trackedDeceleration) {
                    testReportData.chartDecelerationTimelineData = buildTimelineSectionData(mobilityTimelines, x => x.deceleration, timelineTableDataMap);
                }
                testReportData.chartHeartRateTimelineData = buildTimelineSectionData(mobilityTimelines, x => x.heartRate, timelineTableDataMap, true);

                if (prevStep && prevStep.testResultMobilityTimelines) {
                    const prevMobilityTimelines = prevStep.testResultMobilityTimelines;
                    const buildMobilityTimelinesData = (nameMapping, leftData, rightData, selector, isInverse = false) => {
                        const updatedLeftData = changeIntervals(leftData, selector);
                        const updatedRightData = changeIntervals(rightData, selector);
                        const leftTableData = selectedTimelineInterval == '0.5s' || (leftData.length && leftData[0].timeElapsed > 2000)
                            ? collectTimelineData(leftData, selector)
                            : collectTenSecondTimelineData(updatedLeftData);
                        const rightTableData = selectedTimelineInterval == '0.5s' || (rightData.length && rightData[0].timeElapsed > 2000)
                            ? collectTimelineData(rightData, selector)
                            : collectTenSecondTimelineData(updatedRightData);
                        if (!leftTableData || !rightTableData) {
                            return;
                        }

                        return {
                            chartData: null,
                            tableData: Object.keys(nameMapping)
                                .reduce((a, key) => ({
                                    ...a,
                                    [nameMapping[key]]: CalculatedDifference(leftTableData[key], rightTableData[key], isInverse)
                                }), {})
                        };
                    };
                    diffReportData.chartReactionTimeTimelineData = buildMobilityTimelinesData(timelineTableDataMap, prevMobilityTimelines, mobilityTimelines, x => x.unadjustedReaction, true);
                    diffReportData.chartDynamicReactionTimeTimelineData = buildMobilityTimelinesData(timelineTableDataMap, prevMobilityTimelines, mobilityTimelines, x => x.reaction, true);
                    diffReportData.chartSpeedTimelineData = buildMobilityTimelinesData(timelineTableDataMap, prevMobilityTimelines, mobilityTimelines, x => x.speed);
                    diffReportData.chartAccelerationTimelineData = buildMobilityTimelinesData(timelineTableDataMap, prevMobilityTimelines, mobilityTimelines, x => x.acceleration);
                    diffReportData.chartDecelerationTimelineData = buildMobilityTimelinesData(timelineTableDataMap, prevMobilityTimelines, mobilityTimelines, x => x.deceleration);
                    diffReportData.chartHeartRateTimelineData = buildMobilityTimelinesData(timelineTableDataMap, prevMobilityTimelines, mobilityTimelines, x => x.heartRate);
                    diffReportData.hasBalanceData = true;
                }
            }

            const buildKinematicEntry = (kinematic) => ({
                initialStanceWidthRatio: kinematic.initialStanceWidthRatio ? kinematic.initialStanceWidthRatio.toFixed(2) : null,
                stanceWidthRatio: kinematic.stanceWidthRatio ? kinematic.stanceWidthRatio.toFixed(2) : null,
                initialStanceWidthDistance: kinematic.initialStanceWidthDistance == null ?
                    NoData
                    : distanceFormatter(kinematic.initialStanceWidthDistance),
                stanceWidthDistance: kinematic.stanceWidthDistance == null ?
                    NoData
                    : distanceFormatter(kinematic.stanceWidthDistance),

                initialNeckRotation: degreeFormatter(kinematic.initialNeckRotation, kinematic.initialNeckRotationDir),
                neckRotation: degreeFormatter(kinematic.neckRotation, kinematic.neckRotationDir),
                initialNeckFlexion: degreeFormatter(kinematic.initialNeckFlexion, kinematic.initialNeckFlexionDir),
                neckFlexion: degreeFormatter(kinematic.neckFlexion, kinematic.neckFlexionDir),
                initialNeckLatFlexion: degreeFormatter(kinematic.initialNeckLatFlexion, kinematic.initialNeckLatFlexionDir),
                neckLatFlexion: degreeFormatter(kinematic.neckLatFlexion, kinematic.neckLatFlexionDir),
                initialSpineFlexion: degreeFormatter(kinematic.initialSpineFlexion, kinematic.initialSpineFlexionDir),
                spineFlexion: degreeFormatter(kinematic.spineFlexion, kinematic.spineFlexionDir),
                initialSpineLatFlexion: degreeFormatter(kinematic.initialSpineLatFlexion, kinematic.initialSpineLatFlexionDir),
                spineLatFlexion: degreeFormatter(kinematic.spineLatFlexion, kinematic.spineLatFlexionDir),
                initialSpineRotation: degreeFormatter(kinematic.initialSpineRotation, kinematic.initialSpineRotationDir),
                spineRotation: degreeFormatter(kinematic.spineRotation, kinematic.spineRotationDir),
                initialLeftShoulderRotation: degreeFormatter(kinematic.initialLeftShoulderRotation),
                initialRightShoulderRotation: degreeFormatter(kinematic.initialRightShoulderRotation),
                leftShoulderRotation: degreeFormatter(kinematic.leftShoulderRotation),
                rightShoulderRotation: degreeFormatter(kinematic.rightShoulderRotation),
                initialLeftShoulderFlexion: degreeFormatter(kinematic.initialLeftShoulderFlexion),
                initialRightShoulderFlexion: degreeFormatter(kinematic.initialRightShoulderFlexion),
                leftShoulderFlexion: degreeFormatter(kinematic.leftShoulderFlexion),
                rightShoulderFlexion: degreeFormatter(kinematic.rightShoulderFlexion),
                initialLeftShoulderAbduction: degreeFormatter(kinematic.initialLeftShoulderAbduction),
                initialRightShoulderAbduction: degreeFormatter(kinematic.initialRightShoulderAbduction),
                leftShoulderAbduction: degreeFormatter(kinematic.leftShoulderAbduction),
                rightShoulderAbduction: degreeFormatter(kinematic.rightShoulderAbduction),
                initialLeftShoulderHorizontalAbduction: degreeFormatter(kinematic.initialLeftShoulderHorizontalAbduction),
                initialRightShoulderHorizontalAbduction: degreeFormatter(kinematic.initialRightShoulderHorizontalAbduction),
                leftShoulderHorizontalAbduction: degreeFormatter(kinematic.leftShoulderHorizontalAbduction),
                rightShoulderHorizontalAbduction: degreeFormatter(kinematic.rightShoulderHorizontalAbduction),
                initialLeftElbowFlexion: degreeFormatter(kinematic.initialLeftElbowFlexion),
                initialRightElbowFlexion: degreeFormatter(kinematic.initialRightElbowFlexion),
                leftElbowFlexion: degreeFormatter(kinematic.leftElbowFlexion),
                rightElbowFlexion: degreeFormatter(kinematic.rightElbowFlexion),

                initialSquatDepth: kinematic.initialSquatDepth == null ?
                    NoData
                    : jumpSquatFormatter(kinematic.initialSquatDepth),
                squatDepth: kinematic.squatDepth == null ?
                    NoData
                    : jumpSquatFormatter(kinematic.squatDepth),
                jumpHeight: kinematic.jumpHeight == null ?
                    NoData
                    : jumpSquatFormatter(kinematic.jumpHeight),
                
                initialShoulderRotation: degreeFormatter(kinematic.initialShoulderRotation, kinematic.initialShoulderRotationDir),
                shoulderRotation: degreeFormatter(kinematic.shoulderRotation, kinematic.shoulderRotationDir),
                initialThoracicRotation: degreeFormatter(kinematic.initialThoracicRotation, kinematic.initialThoracicRotationDir),
                thoracicRotation: degreeFormatter(kinematic.thoracicRotation, kinematic.thoracicRotationDir),
                initialLumbarRotation: degreeFormatter(kinematic.initialLumbarRotation, kinematic.initialLumbarRotationDir),
                lumbarRotation: degreeFormatter(kinematic.lumbarRotation, kinematic.lumbarRotationDir),
                initialTrunkLean: kinematic.initialTrunkLeanDeg == null || kinematic.initialTrunkLeanDeg == 0 ?
                    NoData
                    : degreeFormatter(kinematic.initialTrunkLeanDeg, kinematic.initialTrunkLeanDir),
                trunkLean: kinematic.trunkLeanDeg == null || kinematic.trunkLeanDeg == 0 ?
                    NoData
                    : degreeFormatter(kinematic.trunkLeanDeg, kinematic.trunkLeanDir),
                initialTrunkLatFlexion: kinematic.initialTrunkLatFlexionDeg == null || kinematic.initialTrunkLatFlexionDeg == 0 ?
                    NoData
                    : degreeFormatter(kinematic.initialTrunkLatFlexionDeg, kinematic.initialTrunkLatFlexion),
                trunkLatFlexion: kinematic.trunkLatFlexionDeg == null || kinematic.trunkLatFlexionDeg == 0 ?
                    NoData
                    : degreeFormatter(kinematic.trunkLatFlexionDeg, kinematic.trunkLatFlexion),
                initialPelvisRotation: degreeFormatter(kinematic.initialPelvisRotation, kinematic.initialPelvisRotationDir),
                pelvisRotation: degreeFormatter(kinematic.pelvisRotation, kinematic.pelvisRotationDir),
                initialLeftHipRotation: degreeFormatter(kinematic.initialLeftHipRotation, kinematic.initialLeftHipRotationDir),
                initialRightHipRotation: degreeFormatter(kinematic.initialRightHipRotation, kinematic.initialRightHipRotationDir),
                leftHipRotation: degreeFormatter(kinematic.leftHipRotation, kinematic.leftHipRotationDir),
                rightHipRotation: degreeFormatter(kinematic.rightHipRotation, kinematic.rightHipRotationDir),
                initialLeftKneeValgusOrVarus: degreeFormatter(kinematic.initialLeftKneeValgusVarusDeg, kinematic.initialLeftKneeValgusVarus),
                initialRightKneeValgusOrVarus: degreeFormatter(kinematic.initialRightKneeValgusVarusDeg, kinematic.initialRightKneeValgusVarus),
                leftKneeValgusOrVarus: degreeFormatter(kinematic.leftKneeValgusVarusDeg, kinematic.leftKneeValgusVarus),
                rightKneeValgusOrVarus: degreeFormatter(kinematic.rightKneeValgusVarusDeg, kinematic.rightKneeValgusVarus),
                initialLeftKneeFlexion: degreeFormatter(kinematic.initialLeftKneeFlexion),
                initialRightKneeFlexion: degreeFormatter(kinematic.initialRightKneeFlexion),
                leftKneeFlexion: degreeFormatter(kinematic.leftKneeFlexion),
                rightKneeFlexion: degreeFormatter(kinematic.rightKneeFlexion),
                initialLeftAnkleDorsiflexion: degreeFormatter(kinematic.initialLeftAnkleDorsiflexion),
                initialRightAnkleDorsiflexion: degreeFormatter(kinematic.initialRightAnkleDorsiflexion),
                leftAnkleDorsiflexion: degreeFormatter(kinematic.leftAnkleDorsiflexion),
                rightAnkleDorsiflexion: degreeFormatter(kinematic.rightAnkleDorsiflexion),
            })

            const kinematic = testStep.testResultKinematic;
            if (kinematic) {
                const tableData = buildKinematicEntry(kinematic);
                
                if (!tableData.initialStanceWidthRatio) {
                    delete tableData.initialStanceWidthRatio;
                }

                if (!tableData.stanceWidthRatio) {
                    delete tableData.stanceWidthRatio;
                }

                if (kinematic.plantFoot) {
                    tableData.plantFoot = kinematic.plantFoot;
                }

                testReportData.kinematic = Object.keys(tableData)
                    .reduce((a, currentKey) => ({
                        ...a,
                        // [dataMap[currentKey]]: tableData[currentKey] ?? NoData
                        [currentKey]: tableData[currentKey] ?? NoData
                    }), {});
                testReportData.hasKinematicData = true;

                if (prevStep && prevStep.testResultKinematic) {
                    const prevKinematic = prevStep.testResultKinematic;

                    diffReportData.kinematic = {
                        initialStanceWidthRatio: CalculatedDifference(
                            prevKinematic.initialStanceWidthRatio,
                            kinematic.initialStanceWidthRatio,
                            true
                        ),
                        stanceWidthRatio: CalculatedDifference(
                            prevKinematic.stanceWidthRatio,
                            kinematic.stanceWidthRatio,
                            true
                        ),
                        initialStanceWidthDistance: CalculatedDifference(
                            prevKinematic.initialStanceWidthDistance,
                            kinematic.initialStanceWidthDistance,
                            true
                        ),
                        stanceWidthDistance: CalculatedDifference(
                            prevKinematic.stanceWidthDistance,
                            kinematic.stanceWidthDistance,
                            true
                        ),
                        initialSquatDepth: CalculatedDifference(
                            prevKinematic.initialSquatDepth,
                            kinematic.initialSquatDepth
                        ),
                        squatDepth: CalculatedDifference(
                            prevKinematic.squatDepth,
                            kinematic.squatDepth
                        ),
                        jumpHeight: CalculatedDifference(
                            prevKinematic.jumpHeight,
                            kinematic.jumpHeight
                        ),
                        initialTrunkLean: CalculatedDifference(
                            prevKinematic.initialTrunkLeanDeg,
                            kinematic.initialTrunkLeanDeg,
                            true
                        ),
                        trunkLean: CalculatedDifference(
                            prevKinematic.trunkLeanDeg,
                            kinematic.trunkLeanDeg,
                            true
                        ),
                        initialTrunkLatFlexion: CalculatedDifference(
                            prevKinematic.initialTrunkLatFlexionDeg,
                            kinematic.initialTrunkLatFlexionDeg,
                            true
                        ),
                        trunkLatFlexion: CalculatedDifference(
                            prevKinematic.trunkLatFlexionDeg,
                            kinematic.trunkLatFlexionDeg,
                            true
                        ),
                        initialPelvisRotation: CalculatedDifference(
                            prevKinematic.initialPelvisRotation,
                            kinematic.initialPelvisRotation,
                            true
                        ),
                        pelvisRotation: CalculatedDifference(
                            prevKinematic.pelvisRotation,
                            kinematic.pelvisRotation,
                            true
                        ),
                        initialRightKneeValgusOrVarus: CalculatedDifference(
                            prevKinematic.initialRightKneeValgusVarusDeg,
                            kinematic.initialRightKneeValgusVarusDeg,
                            true
                        ),
                        rightKneeValgusOrVarus: CalculatedDifference(
                            prevKinematic.rightKneeValgusVarusDeg,
                            kinematic.rightKneeValgusVarusDeg,
                            true
                        ),
                        initialRightKneeFlexion: CalculatedDifference(
                            prevKinematic.initialRightKneeFlexion,
                            kinematic.initialRightKneeFlexion
                        ),
                        rightKneeFlexion: CalculatedDifference(
                            prevKinematic.rightKneeFlexion,
                            kinematic.rightKneeFlexion
                        ),
                        initialRightAnkleDorsiflexion: CalculatedDifference(
                            prevKinematic.initialRightAnkleDorsiflexion,
                            kinematic.initialRightAnkleDorsiflexion,
                            true
                        ),
                        rightAnkleDorsiflexion: CalculatedDifference(
                            prevKinematic.rightAnkleDorsiflexion,
                            kinematic.rightAnkleDorsiflexion,
                            true
                        ),
                        initialLeftKneeValgusOrVarus: CalculatedDifference(
                            prevKinematic.initialLeftKneeValgusVarusDeg,
                            kinematic.initialLeftKneeValgusVarusDeg,
                            true
                        ),
                        leftKneeValgusOrVarus: CalculatedDifference(
                            prevKinematic.leftKneeValgusVarusDeg,
                            kinematic.leftKneeValgusVarusDeg,
                            true
                        ),
                        initialLeftKneeFlexion: CalculatedDifference(
                            prevKinematic.initialLeftKneeFlexion,
                            kinematic.initialLeftKneeFlexion
                        ),
                        leftKneeFlexion: CalculatedDifference(
                            prevKinematic.leftKneeFlexion,
                            kinematic.leftKneeFlexion
                        ),
                        initialLeftAnkleDorsiflexion: CalculatedDifference(
                            prevKinematic.initialLeftAnkleDorsiflexion,
                            kinematic.initialLeftAnkleDorsiflexion,
                            true
                        ),
                        leftAnkleDorsiflexion: CalculatedDifference(
                            prevKinematic.leftAnkleDorsiflexion,
                            kinematic.leftAnkleDorsiflexion,
                            true
                        ),

                        initialNeckRotation: CalculatedDifference(
                            prevKinematic.initialNeckRotation,
                            kinematic.initialNeckRotation,
                            true
                        ),
                        neckRotation: CalculatedDifference(
                            prevKinematic.neckRotation,
                            kinematic.neckRotation,
                            true
                        ),
                        initialNeckFlexion: CalculatedDifference(
                            prevKinematic.initialNeckFlexion,
                            kinematic.initialNeckFlexion,
                            true
                        ),
                        neckFlexion: CalculatedDifference(
                            prevKinematic.neckFlexion,
                            kinematic.neckFlexion,
                            true
                        ),
                        initialNeckLatFlexion: CalculatedDifference(
                            prevKinematic.initialNeckLatFlexion,
                            kinematic.initialNeckLatFlexion,
                            true
                        ),
                        neckLatFlexion: CalculatedDifference(
                            prevKinematic.neckLatFlexion,
                            kinematic.neckLatFlexion,
                            true
                        ),
                        initialSpineFlexion: CalculatedDifference(
                            prevKinematic.initialSpineFlexion,
                            kinematic.initialSpineFlexion,
                            true
                        ),
                        spineFlexion: CalculatedDifference(
                            prevKinematic.spineFlexion,
                            kinematic.spineFlexion,
                            true
                        ),
                        initialSpineLatFlexion: CalculatedDifference(
                            prevKinematic.initialSpineLatFlexion,
                            kinematic.initialSpineLatFlexion,
                            true
                        ),
                        spineLatFlexion: CalculatedDifference(
                            prevKinematic.spineLatFlexion,
                            kinematic.spineLatFlexion,
                            true
                        ),
                        initialSpineRotation: CalculatedDifference(
                            prevKinematic.initialSpineRotation,
                            kinematic.initialSpineRotation,
                            true
                        ),
                        spineRotation: CalculatedDifference(
                            prevKinematic.spineRotation,
                            kinematic.spineRotation,
                            true
                        ),
                        initialLeftShoulderRotation: CalculatedDifference(
                            prevKinematic.initialLeftShoulderRotation,
                            kinematic.initialLeftShoulderRotation
                        ),
                        initialRightShoulderRotation: CalculatedDifference(
                            prevKinematic.initialRightShoulderRotation,
                            kinematic.initialRightShoulderRotation
                        ),
                        leftShoulderRotation: CalculatedDifference(
                            prevKinematic.leftShoulderRotation,
                            kinematic.leftShoulderRotation
                        ),
                        rightShoulderRotation: CalculatedDifference(
                            prevKinematic.rightShoulderRotation,
                            kinematic.rightShoulderRotation
                        ),
                        initialLeftShoulderFlexion: CalculatedDifference(
                            prevKinematic.initialLeftShoulderFlexion,
                            kinematic.initialLeftShoulderFlexion
                        ),
                        initialRightShoulderFlexion: CalculatedDifference(
                            prevKinematic.initialRightShoulderFlexion,
                            kinematic.initialRightShoulderFlexion
                        ),
                        leftShoulderFlexion: CalculatedDifference(
                            prevKinematic.leftShoulderFlexion,
                            kinematic.leftShoulderFlexion
                        ),
                        rightShoulderFlexion: CalculatedDifference(
                            prevKinematic.rightShoulderFlexion,
                            kinematic.rightShoulderFlexion
                        ),
                        initialLeftShoulderAbduction: CalculatedDifference(
                            prevKinematic.initialLeftShoulderAbduction,
                            kinematic.initialLeftShoulderAbduction
                        ),
                        initialRightShoulderAbduction: CalculatedDifference(
                            prevKinematic.initialRightShoulderAbduction,
                            kinematic.initialRightShoulderAbduction
                        ),
                        leftShoulderAbduction: CalculatedDifference(
                            prevKinematic.leftShoulderAbduction,
                            kinematic.leftShoulderAbduction
                        ),
                        rightShoulderAbduction: CalculatedDifference(
                            prevKinematic.rightShoulderAbduction,
                            kinematic.rightShoulderAbduction
                        ),
                        initialLeftShoulderHorizontalAbduction: CalculatedDifference(
                            prevKinematic.initialLeftShoulderHorizontalAbduction,
                            kinematic.initialLeftShoulderHorizontalAbduction
                        ),
                        initialRightShoulderHorizontalAbduction: CalculatedDifference(
                            prevKinematic.initialRightShoulderHorizontalAbduction,
                            kinematic.initialRightShoulderHorizontalAbduction
                        ),
                        leftShoulderHorizontalAbduction: CalculatedDifference(
                            prevKinematic.leftShoulderHorizontalAbduction,
                            kinematic.leftShoulderHorizontalAbduction
                        ),
                        rightShoulderHorizontalAbduction: CalculatedDifference(
                            prevKinematic.rightShoulderHorizontalAbduction,
                            kinematic.rightShoulderHorizontalAbduction
                        ),
                        initialLeftElbowFlexion: CalculatedDifference(
                            prevKinematic.initialLeftElbowFlexion,
                            kinematic.initialLeftElbowFlexion
                        ),
                        initialRightElbowFlexion: CalculatedDifference(
                            prevKinematic.initialRightElbowFlexion,
                            kinematic.initialRightElbowFlexion
                        ),
                        leftElbowFlexion: CalculatedDifference(
                            prevKinematic.leftElbowFlexion,
                            kinematic.leftElbowFlexion
                        ),
                        rightElbowFlexion: CalculatedDifference(
                            prevKinematic.rightElbowFlexion,
                            kinematic.rightElbowFlexion
                        ),
                        initialShoulderRotation: CalculatedDifference(
                            prevKinematic.initialShoulderRotation,
                            kinematic.initialShoulderRotation
                        ),
                        shoulderRotation: CalculatedDifference(
                            prevKinematic.shoulderRotation,
                            kinematic.shoulderRotation
                        ),
                        initialThoracicRotation: CalculatedDifference(
                            prevKinematic.initialThoracicRotation,
                            kinematic.initialThoracicRotation
                        ),
                        thoracicRotation: CalculatedDifference(
                            prevKinematic.thoracicRotation,
                            kinematic.thoracicRotation
                        ),
                        initialLumbarRotation: CalculatedDifference(
                            prevKinematic.initialLumbarRotation,
                            kinematic.initialLumbarRotation
                        ),
                        lumbarRotation: CalculatedDifference(
                            prevKinematic.lumbarRotation,
                            kinematic.lumbarRotation
                        ),
                        initialLeftHipRotation: CalculatedDifference(
                            prevKinematic.initialLeftHipRotation,
                            kinematic.initialLeftHipRotation
                        ),
                        initialRightHipRotation: CalculatedDifference(
                            prevKinematic.initialRightHipRotation,
                            kinematic.initialRightHipRotation
                        ),
                        leftHipRotation: CalculatedDifference(
                            prevKinematic.leftHipRotation,
                            kinematic.leftHipRotation
                        ),
                        rightHipRotation: CalculatedDifference(
                            prevKinematic.rightHipRotation,
                            kinematic.rightHipRotation
                        ),
                    };
                    diffReportData.hasKinematicData = true;
                    diffReportData.isDifference = true;
                }
            }

            const kinematicReps = testStep.testResultKinematicsReps;
            if (kinematicReps) {
                testReportData.kinematicReps = kinematicReps
                    .map(rep => {
                        const tableData = buildKinematicEntry(rep);
                        return {
                            label: t('testReport.kinematicRepetitionNamePattern', rep.repNumber),
                            data: Object.keys(tableData)
                                .reduce((a, currentKey) => ({
                                    ...a,
                                    // [dataMap[currentKey]]: tableData[currentKey] ?? NoData
                                    [currentKey]: tableData[currentKey] ?? NoData
                                }), {})
                        };
                    });
            }

            const buildMobilityEntry = (mobility) => ({
                reactionTimeAvg: currentStepMetrics.trackedReaction && mobility.unadjustedReactionTimeAvg != null ?
                `${mobility.unadjustedReactionTimeAvg.toFixed(2)} ${t('constants.units.secondsShort')}`
                : null,
                reactionTimeMin: currentStepMetrics.trackedReaction && mobility.unadjustedReactionTimeMin != null ?
                `${mobility.unadjustedReactionTimeMin.toFixed(2)} ${t('constants.units.secondsShort')}`
                : null,
                dynamicReactionTimeAvg: currentStepMetrics.trackedReaction && mobility.reactionTimeAvg != null ?
                `${mobility.reactionTimeAvg.toFixed(2)} ${t('constants.units.secondsShort')}`
                : null,
                dynamicReactionTimeMin: currentStepMetrics.trackedReaction && mobility.reactionTimeMin != null ?
                `${mobility.reactionTimeMin.toFixed(2)} ${t('constants.units.secondsShort')}`
                : null,
                speedAvg: currentStepMetrics.trackedSpeed && mobility.speedAvg != null ? speedFormatter(mobility.speedAvg): NoData,
                speedMax: currentStepMetrics.trackedSpeed && mobility.speedMax != null ? speedFormatter(mobility.speedMax): NoData,
                accelerationAvg: currentStepMetrics.trackedAcceleration && mobility.accelerationAvg != null ? accelerationFormatter(mobility.accelerationAvg) : NoData,
                accelerationMax: currentStepMetrics.trackedAcceleration && mobility.accelerationMax != null ? accelerationFormatter(mobility.accelerationMax) : NoData,
                decelerationAvg: currentStepMetrics.trackedDeceleration && mobility.decelerationAvg != null ? accelerationFormatter(mobility.decelerationAvg) : NoData,
                decelerationMax: currentStepMetrics.trackedDeceleration && mobility.decelerationMax != null ? accelerationFormatter(mobility.decelerationMax) : NoData,
                decelerationIndex: currentStepMetrics.trackedAcceleration && currentStepMetrics.trackedDeceleration && mobility.accelerationMax != null && mobility.decelerationMax != null && mobility.accelerationMax != 0 ? (mobility.decelerationMax / mobility.accelerationMax).toFixed(2) : NoData,
                hrAverage: currentStepMetrics.trackedHeartRate &&  mobility.hrAverage ? mobility.hrAverage.toFixed(2) : null,
                hrMaximum: currentStepMetrics.trackedHeartRate &&  mobility.hrMaximum ? mobility.hrMaximum.toFixed(2) : null,
                hrStarting: currentStepMetrics.trackedHeartRate &&  mobility.hrStarting ? mobility.hrStarting.toFixed(2) : null,
                jumpHeightAvg: currentStepMetrics.trackedJumpHeight && mobility.jumpHeightAvg && jumpSquatFormatter(mobility.jumpHeightAvg),
                jumpHeightMax: currentStepMetrics.trackedJumpHeight && mobility.jumpHeightMax && jumpSquatFormatter(mobility.jumpHeightMax),
                squatHeightAvg: currentStepMetrics.trackedSquatDepth && mobility.squatHeightAvg && jumpSquatFormatter(mobility.squatHeightAvg),
                squatHeightMin: currentStepMetrics.trackedSquatDepth && mobility.squatHeightMin && jumpSquatFormatter(mobility.squatHeightMin),
                minimumDistance: currentStepMetrics.trackedDistance && mobility.minimumDistance ? distanceFormatter(mobility.minimumDistance) : NoData,
                totalDistance: currentStepMetrics.trackedDistance && mobility.totalDistance ? distanceFormatter(mobility.totalDistance) : NoData,
            })

            const mobilityReps = testStep.testResultMobilityReps;
            if (mobilityReps) {
                testReportData.mobilityReps = mobilityReps
                    .map(rep => {
                        const tableData = buildMobilityEntry(rep);
                        return {
                            label: t('testReport.mobilityRepetitionNamePattern', rep.repNumber),
                            data: Object.keys(tableData)
                                .reduce((a, currentKey) => ({
                                    ...a,
                                    // [dataMap[currentKey]]: tableData[currentKey] ?? NoData
                                    [currentKey]: tableData[currentKey] ?? NoData
                                }), {})
                        };
                    });
            }

            const upperExtremity = testStep.testResultUpperExtremity;
            if (upperExtremity) {
                const reactionTimeMap = {
                    unadjustedReactionTimeForwardOFF: t('testReport.fieldName.forward'),
                    unadjustedReactionTimeForwardRightOFF: t('testReport.fieldName.forwardRight'),
                    unadjustedReactionTimeRight: t('testReport.fieldName.right'),
                    unadjustedReactionTimeBackwardRightOFF: t('testReport.fieldName.backwardRight'),
                    unadjustedReactionTimeBackwardOFF: t('testReport.fieldName.backward'),
                    unadjustedReactionTimeBackwardLeftOFF: t('testReport.fieldName.backwardLeft'),
                    unadjustedReactionTimeLeft: t('testReport.fieldName.left'),
                    unadjustedReactionTimeForwardLeftOFF: t('testReport.fieldName.forwardLeft'),
                    calculatedMappings: {
                        [t('testReport.fieldName.lrDifference')]: x => CalculatedReactionTimeLeftRightDifference(x.unadjustedReactionTimeLeft, x.unadjustedReactionTimeRight),
                    }
                };
                const dynamicReactionTimeMap = {
                    reactionTimeForwardOFF: t('testReport.fieldName.forward'),
                    reactionTimeForwardRightOFF: t('testReport.fieldName.forwardRight'),
                    reactionTimeRight: t('testReport.fieldName.right'),
                    reactionTimeBackwardRightOFF: t('testReport.fieldName.backwardRight'),
                    reactionTimeBackwardOFF: t('testReport.fieldName.backward'),
                    reactionTimeBackwardLeftOFF: t('testReport.fieldName.backwardLeft'),
                    reactionTimeLeft: t('testReport.fieldName.left'),
                    reactionTimeForwardLeftOFF: t('testReport.fieldName.forwardLeft'),
                    calculatedMappings: {
                        [t('testReport.fieldName.lrDifference')]: x => CalculatedReactionTimeLeftRightDifference(x.reactionTimeLeft, x.reactionTimeRight),
                    }
                };
                if (currentStepMetrics.trackedReaction) {
                    testReportData.chartReactionTimeData = buildRadarChartSectionData(convertUnits, upperExtremity, reactionTimeMap, true);
                    testReportData.chartDynamicReactionTimeData = buildRadarChartSectionData(convertUnits, upperExtremity, dynamicReactionTimeMap, true);
                }

                const leftTargetTotal = [
                    upperExtremity.targetsHitLeft180,
                    upperExtremity.targetsHitLeft45,
                    upperExtremity.targetsHitLeft90,
                    upperExtremity.targetsHitLeft135,
                    upperExtremity.targetsHitForwardLeft45,
                    upperExtremity.targetsHitForwardLeft90,
                    upperExtremity.targetsHitForwardLeft135,
                ].reduce((a, c) => a + (c || 0), 0);
                const rightTargetTotal = [
                    upperExtremity.targetsHitRight180,
                    upperExtremity.targetsHitRight45,
                    upperExtremity.targetsHitRight90,
                    upperExtremity.targetsHitRight135,
                    upperExtremity.targetsHitForwardRight45,
                    upperExtremity.targetsHitForwardRight90,
                    upperExtremity.targetsHitForwardRight135,
                ].reduce((a, c) => a + (c || 0), 0);
                // TODO: Refactor this! Table data should be returned as an array
                const tableData = [
                    'totalTargetsHit',
                    'targetsMissed',
                    'targetsHitLeft180',
                    'targetsHitLeft45',
                    'targetsHitLeft90',
                    'targetsHitLeft135',
                    'targetsHitForwardLeft45',
                    'targetsHitForwardLeft90',
                    'targetsHitForwardLeft135',
                    'targetsHitRight180',
                    'targetsHitRight45',
                    'targetsHitRight90',
                    'targetsHitRight135',
                    'targetsHitForwardRight45',
                    'targetsHitForwardRight90',
                    'targetsHitForwardRight135',
                    // lrTargetsHitDiff: CalculatedLeftRightDifference(leftTargetTotal, rightTargetTotal),
                    'unadjustedReactionTimeAvg',
                    'reactionTimeAvg',
                    // lrReactionTimeDiff: CalculatedLeftRightDifference(upperExtremity.reactionTimeLeft, upperExtremity.reactionTimeRight)
                ]
                    .reduce((a, key) => ({
                        ...a,
                        [key]: upperExtremity[key] ?? NoData
                    }), {
                        lrTargetsHitDiff:
                            CalculatedLeftRightDifference(leftTargetTotal, rightTargetTotal)
                            ?? NoData
                    });
                testReportData.upperExtremity = {
                    ...tableData,
                };

                if (prevStep && prevStep.testResultUpperExtremity) {
                    const prevUpperExtremity = prevStep.testResultUpperExtremity;
                    const tableData = [
                        'totalTargetsHit',
                        'targetsMissed',
                        'targetsHitLeft180',
                        'targetsHitLeft45',
                        'targetsHitLeft90',
                        'targetsHitLeft135',
                        'targetsHitForwardLeft45',
                        'targetsHitForwardLeft90',
                        'targetsHitForwardLeft135',
                        'targetsHitRight180',
                        'targetsHitRight45',
                        'targetsHitRight90',
                        'targetsHitRight135',
                        'targetsHitForwardRight45',
                        'targetsHitForwardRight90',
                        'targetsHitForwardRight135',
                        'unadjustedReactionTimeAvg',
                        'reactionTimeAvg',
                    ]
                        .reduce((a, key) => ({
                            ...a,
                            [key]: CalculatedDifference(prevUpperExtremity[key], upperExtremity[key], key.includes('reaction') ? true : false) ?? NoData
                        }), {
                            lrTargetsHitDiff: null
                        });
                    diffReportData.upperExtremity = tableData;
                    diffReportData.hasUpperExtremityData = true;
                    diffReportData.isDifference = true;

                    const buildUpperExtremityData = ({ calculatedMappings, ...mapping }, leftData, rightData, isInverse = false) => {
                        const tableData = {};
                        let hasData = false;
                        for (const key in mapping) {
                            const value = CalculatedDifference(leftData[key], rightData[key], isInverse);
                            tableData[mapping[key]] = value;
                            hasData ||= Boolean(leftData[key] || rightData[key]);
                        }

                        return !hasData ? null : {
                            chartData: null,
                            tableData
                        };
                    };

                    if (currentStepMetrics.trackedReaction) {
                        diffReportData.chartReactionTimeData = buildUpperExtremityData(reactionTimeMap, prevUpperExtremity, upperExtremity, true);
                        diffReportData.chartDynamicReactionTimeData = buildUpperExtremityData(dynamicReactionTimeMap, prevUpperExtremity, upperExtremity, true);
                    }
                }
            }

            const cognitive = testStep.testResultCognitive;
            if (cognitive
                && Object.values(cognitive)
                    .filter(x => x != null).length > 1
            ) {
                const calcTime = (time, num) => {
                    if (time != null && num) {
                        return `${(time / num).toFixed(2)}`;
                    }
                }

                let rowNames = {
                    leftCongruent: t('testReport.fieldName.leftCongruent'),
                    rightCongruent: t('testReport.fieldName.rightCongruent'),
                    leftIncongruent: t('testReport.fieldName.leftIncongruent'),
                    rightIncongruent: t('testReport.fieldName.rightIncongruent'),
                };
                const currentStepScriptedActivity = scriptedActivitySteps[testStep.scriptedActivityStepId];
                if (currentStepScriptedActivity) {
                    const stepName = currentStepScriptedActivity.name.toLowerCase();
                    if (!stepName.includes('flanker') && !stepName.includes('stroop')) {
                        rowNames = {
                            leftCongruent: t('testReport.fieldName.leftCongruent'),
                            rightCongruent: t('testReport.fieldName.rightCongruent'),
                        };
                    }
                }

                const scoresTableData = [
                    {
                        rowName: rowNames.rightCongruent,
                        correct: cognitive.numCongruentCorrectRight || 0,
                        incorrect: cognitive.numCongruentIncorrectRight || 0,
                    },
                    {
                        rowName: rowNames.rightIncongruent,
                        correct: cognitive.numIncongruentCorrectRight || 0,
                        incorrect: cognitive.numIncongruentIncorrectRight || 0,
                    },
                    {
                        rowName: rowNames.leftCongruent,
                        correct: cognitive.numCongruentCorrectLeft || 0,
                        incorrect: cognitive.numCongruentIncorrectLeft || 0,
                    },
                    {
                        rowName: rowNames.leftIncongruent,
                        correct: cognitive.numIncongruentCorrectLeft || 0,
                        incorrect: cognitive.numIncongruentIncorrectLeft || 0,
                    },
                    {
                        rowName: t('testReport.fieldName.total'),
                        correct: (cognitive.numCongruentCorrectRight || 0)
                            + (cognitive.numIncongruentCorrectRight || 0)
                            + (cognitive.numCongruentCorrectLeft || 0)
                            + (cognitive.numIncongruentCorrectLeft || 0),
                        incorrect: (cognitive.numCongruentIncorrectRight || 0)
                            + (cognitive.numIncongruentIncorrectRight || 0)
                            + (cognitive.numCongruentIncorrectLeft || 0)
                            + (cognitive.numIncongruentIncorrectLeft || 0),
                    },
                ].filter(x => x.rowName);

                const averageTimeToTargetTableData = [
                    {
                        rowName: rowNames.rightCongruent,
                        correct: calcTime(cognitive.timeCongruentCorrectRight, cognitive.numCongruentCorrectRight),
                        incorrect: calcTime(cognitive.timeCongruentIncorrectRight, cognitive.numCongruentIncorrectLeft)
                    },
                    {
                        rowName: rowNames.rightIncongruent,
                        correct: calcTime(cognitive.timeIncongruentCorrectRight, cognitive.numIncongruentCorrectRight),
                        incorrect: calcTime(cognitive.timeIncongruentIncorrectRight, cognitive.numIncongruentIncorrectLeft),
                    },
                    {
                        rowName: rowNames.leftCongruent,
                        correct: calcTime(cognitive.timeCongruentCorrectLeft, cognitive.numCongruentCorrectLeft),
                        incorrect: calcTime(cognitive.timeCongruentIncorrectLeft, cognitive.numCongruentIncorrectRight),
                    },
                    {
                        rowName: rowNames.leftIncongruent,
                        correct: calcTime(cognitive.timeIncongruentCorrectLeft, cognitive.numIncongruentCorrectLeft),
                        incorrect: calcTime(cognitive.timeIncongruentIncorrectLeft, cognitive.numIncongruentIncorrectRight),
                    },
                ].filter(x => x.rowName);

                const averageReactionTimeTableData = [
                    {
                        rowName: rowNames.rightCongruent,
                        correct: cognitive.unadjustedReactionTimeCongruentCorrectRight != null ? cognitive.unadjustedReactionTimeCongruentCorrectRight.toFixed(2) : NoData,
                        incorrect: cognitive.unadjustedReactionTimeCongruentIncorrectRight != null ? cognitive.unadjustedReactionTimeCongruentIncorrectRight.toFixed(2) : NoData
                    },
                    {
                        rowName: rowNames.rightIncongruent,
                        correct: cognitive.unadjustedReactionTimeIncongruentCorrectRight != null ? cognitive.unadjustedReactionTimeIncongruentCorrectRight.toFixed(2) : NoData,
                        incorrect: cognitive.unadjustedReactionTimeIncongruentIncorrectRight != null ? cognitive.unadjustedReactionTimeIncongruentIncorrectRight.toFixed(2) : NoData
                    },
                    {
                        rowName: rowNames.leftCongruent,
                        correct: cognitive.unadjustedReactionTimeCongruentCorrectLeft != null ? cognitive.unadjustedReactionTimeCongruentCorrectLeft.toFixed(2) : NoData,
                        incorrect: cognitive.unadjustedReactionTimeCongruentIncorrectLeft != null ? cognitive.unadjustedReactionTimeCongruentIncorrectLeft.toFixed(2) : NoData
                    },
                    {
                        rowName: rowNames.leftIncongruent,
                        correct: cognitive.unadjustedReactionTimeIncongruentCorrectLeft != null ? cognitive.unadjustedReactionTimeIncongruentCorrectLeft.toFixed(2) : NoData,
                        incorrect: cognitive.unadjustedReactionTimeIncongruentIncorrectLeft != null ? cognitive.unadjustedReactionTimeIncongruentIncorrectLeft.toFixed(2) : NoData
                    },
                ].filter(x => x.rowName);

                const averageDynamicReactionTimeTableData = [
                    {
                        rowName: rowNames.rightCongruent,
                        correct: cognitive.dynamicReactionTimeCongruentCorrectRight != null ? cognitive.dynamicReactionTimeCongruentCorrectRight.toFixed(2) : NoData,
                        incorrect: cognitive.dynamicReactionTimeCongruentIncorrectRight != null ? cognitive.dynamicReactionTimeCongruentIncorrectRight.toFixed(2) : NoData
                    },
                    {
                        rowName: rowNames.rightIncongruent,
                        correct: cognitive.dynamicReactionTimeIncongruentCorrectRight != null ? cognitive.dynamicReactionTimeIncongruentCorrectRight.toFixed(2) : NoData,
                        incorrect: cognitive.dynamicReactionTimeIncongruentIncorrectRight != null ? cognitive.dynamicReactionTimeIncongruentIncorrectRight.toFixed(2) : NoData
                    },
                    {
                        rowName: rowNames.leftCongruent,
                        correct: cognitive.dynamicReactionTimeCongruentCorrectLeft != null ? cognitive.dynamicReactionTimeCongruentCorrectLeft.toFixed(2) : NoData,
                        incorrect: cognitive.dynamicReactionTimeCongruentIncorrectLeft != null ? cognitive.dynamicReactionTimeCongruentIncorrectLeft.toFixed(2) : NoData
                    },
                    {
                        rowName: rowNames.leftIncongruent,
                        correct: cognitive.dynamicReactionTimeIncongruentCorrectLeft != null ? cognitive.dynamicReactionTimeIncongruentCorrectLeft.toFixed(2) : NoData,
                        incorrect: cognitive.dynamicReactionTimeIncongruentIncorrectLeft != null ? cognitive.dynamicReactionTimeIncongruentIncorrectLeft.toFixed(2) : NoData
                    },
                ].filter(x => x.rowName);

                testReportData.neurocognitive = {
                    promptTime: cognitive.promptTime,
                    scoresTableData,
                    averageTimeToTargetTableData,
                    averageReactionTimeTableData,
                    averageDynamicReactionTimeTableData
                };
            }

            // const activityName = scriptedActivityStep.name.toLowerCase();
            // collect summary data only from the first test
            if (mobility && cognitive) { // && !['balance', 'kinematics', 'upperextremity'].includes(activityName)) {
                const correctTotal = (cognitive.numCongruentCorrectLeft ?? 0)
                    + (cognitive.numCongruentCorrectRight ?? 0)
                    + (cognitive.numIncongruentCorrectLeft ?? 0)
                    + (cognitive.numIncongruentCorrectRight ?? 0);
                const correctPercentage = mobility.score ? (correctTotal / mobility.score) * 100 : -1;
                if (correctPercentage !== -1) {
                    testReportData.summary.completion = correctPercentage.toFixed(2) + '%';
                }
            }

            testReportData.hasMobilityData = Boolean(mobility);
            testReportData.hasBalanceData = Boolean(balance);
            testReportData.hasBalanceMissesData = Boolean(balanceMisses || testReportData.balanceMissesData);
            testReportData.hasMobilityTimelineData = Boolean(mobilityTimelines);
            testReportData.hasUpperExtremityData = Boolean(upperExtremity);
            testReportData.hasCognitiveData = Boolean(testReportData.neurocognitive);
            testReportData.scriptedActivityStepId = testStep.scriptedActivityStepId;
            testReportData.orderId = testStep.orderId;
            testReportData.addDisclaimer = testStep.addDisclaimer;
            currentTestResult.steps[uniqueStepKey] = testReportData;

            hasTimelineDataBySAStepId[testStep.scriptedActivityStepId] ||= testReportData.hasMobilityTimelineData;

            if (prevStep && isInDiffMode) {
                // TODO: ask if this is ever generated
                diffReportData.hasBalanceMissesData = false;

                // will never be generated
                diffReportData.hasMobilityTimelineData = false;
                diffReportData.hasCognitiveData = false;
                diffReportData.scriptedActivityStepId = testStep.scriptedActivityStepId;
                diffTestResult.steps[uniqueStepKey] = diffReportData;
            }


        }

        selectedTestsReportData.push(currentTestResult);
        if (prevTest && isInDiffMode) {
            selectedTestsReportData.push(diffTestResult);
        }

        prevTest = currentTest;
    }

    return {
        // historyData,
        testInfos,
        selectedTestsReportData,
        hasTimelineDataBySAStepId,
        availableSteps,
        firstAvailableStep,
        trackedMetrics
    };
};
