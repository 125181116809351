/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Button } from '../../CoreComponents/Button';
import { useTranslation } from '../../CoreComponents/Translation';
import { useHook } from '../../CoreComponents/Utils';
import { metersToFeet, parseTrackedSettings, shortDate, defaultSort } from '../../Utils/Common';
import { NoData, UnitType, SortOrder } from '../../Utils/Constants';
import { CalculatedLeftRightDifference, CalculatedReactionTimeLeftRightDifference } from '../Reports/TestReport/PrepareTestData';
import { CircularLoader } from "../../CoreComponents/Loaders";
import { AccelerationTab } from './AccelerationTab';
import { CaloriesTab } from './CaloriesTab';
import { CognitiveTab } from './CognitiveTab';
import { DecelerationTab } from './DecelerationTab';
import { DecelerationIndexTab } from './DecelerationIndexTab';
import { KinematicsTab } from './KinematicsTab';
import { MissesTab } from './MissesTab';
import { ReactionTimeTab } from './ReactionTimeTab';
import { DynamicReactionTimeTab } from './DynamicReactionTimeTab';
import { SpeedTab } from './SpeedTab';
import { SwaysTab } from './SwaysTab';
import { TestsTab } from './TestsTab';
import { TotalDistanceTab } from './TotalDistanceTab';
import { useSelector } from 'react-redux';

const searchResultLayoutStyles = {
    tabNavigation: css`
        display: flex;
        gap: 20px;
        margin-bottom: 28px;
        padding: 0 40px 0 41px;

        & .tests-tab {
            width: 168px;
        }

        & .data-types {
            width: calc(100% - 20px - 168px);
            border-collapse: separate;
            border-spacing: 0;
            border-radius: 8px;
            border: 1px solid #00ABA5;

            & td {
                width: calc(100% - 20px - 168px);
                border: 1px solid #00ABA5;
                padding: 4px;
                & > button {
                    border-radius: 0;
                    width: 100%;
                }
            }
            & tr:first-of-type > td:first-of-type {
                border-top-left-radius: 8px;
                & button {
                    border-top-left-radius: 4px;
                }
            }
            & tr:first-of-type > td:last-of-type {
                border-top-right-radius: 8px;
                & > button {
                    border-top-right-radius: 4px;
                }
            }
            & tr:last-of-type > td:first-of-type {
                border-bottom-left-radius: 8px;
                & > button {
                    border-bottom-left-radius: 4px;
                }
            }
            & tr:last-of-type > td:last-of-type {
                border-bottom-right-radius: 8px;
                & > button {
                    border-bottom-right-radius: 4px;
                }
            }
        }

        & .no-report-data {
            border: 1px solid #CBCBCB;

            & td {
                border: 1px solid #CBCBCB;
            }
        }
    `,
    tabContent: css`
        height: calc(100% - 123px);
        overflow: auto;
        padding-left: 29px;

        .low, .improvement {
            color: forestgreen;
        }

        .medium {
            color: #FFC107;
        }

        .high, .degradation {
            color: red;
        }
    `,
}

const ResultDataTabs = {
    ReactionTime: 1,
    DynamicReactionTime: 2,
    Speed: 3,
    Acceleration: 4,
    Deceleration: 5,
    DecelerationIndex: 6,
    TotalDistance: 7,
    Calories: 8,
    Cognitive: 9,
    Sways: 10,
    Misses: 11,
    Kinematics: 12,
}

export const SearchResultLayout = ({ $availableTests, $isGeneratingReportData }) => {
    const { t } = useTranslation();
    const $selectedTab = useHook(0);
    const $rawReportData = useHook([]);
    const $reportData = useHook(null);
    const analyticsState = useSelector((state) => state.analyticsState);
    const $cachedTestsList = useHook(analyticsState.tests.map(t => ({
        ...t,
        startTime: new Date(t.startTime),
        endTime: new Date(t.endTime),
        testSteps: (t.testSteps || []).map(ts => ({
            ...ts,
            startTime: t.startTime ? new Date(t.startTime) : null,
            endTime: t.endTime ? new Date(t.endTime) : null,
        })),
        testNote: t.testNote ? {
            ...t.testNote,
            dateCreated: t.testNote.dateCreated ? new Date(t.testNote.dateCreated) : null,
            dateModified: t.testNote.dateModified ? new Date(t.testNote.dateModified) : null,
        }
            :
            null
    })));
    // used only by tests tab and are here in order to
    // persist the data when tabs are changed
    const $selectedTests = useHook({});
    const $selectedUnitType = useHook(UnitType.Feet);

    const tabs = useMemo(() => ({
        [ResultDataTabs.ReactionTime]: {
            hasData: $reportData.value?.reactionTime?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.reactionTime',
            Component: ReactionTimeTab,
        },
        [ResultDataTabs.DynamicReactionTime]: {
            hasData: $reportData.value?.dynamicReactionTime?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.dynamicReactionTime',
            Component: DynamicReactionTimeTab,
        },
        [ResultDataTabs.Speed]: {
            hasData: $reportData.value?.speed?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.speed',
            Component: SpeedTab,
        },
        [ResultDataTabs.Acceleration]: {
            hasData: $reportData.value?.acceleration?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.acceleration',
            Component: AccelerationTab,
        },
        [ResultDataTabs.Deceleration]: {
            hasData: $reportData.value?.deceleration?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.deceleration',
            Component: DecelerationTab,
        },
        [ResultDataTabs.DecelerationIndex]: {
            hasData: $reportData.value?.deceleration?.hasData && $reportData.value?.acceleration?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.decelerationIndex',
            Component: DecelerationIndexTab,
        },
        [ResultDataTabs.TotalDistance]: {
            hasData: $reportData.value?.totalDistance?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.totalDistance',
            Component: TotalDistanceTab,
        },
        [ResultDataTabs.Calories]: {
            hasData: $reportData.value?.calories?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.calories',
            Component: CaloriesTab,
        },
        [ResultDataTabs.Cognitive]: {
            hasData: $reportData.value?.cognitive?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.cognitive',
            Component: CognitiveTab,
        },
        [ResultDataTabs.Sways]: {
            hasData: $reportData.value?.sway?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.sways',
            Component: SwaysTab,
        },
        [ResultDataTabs.Misses]: {
            hasData: $reportData.value?.misses?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.misses',
            Component: MissesTab,
        },
        [ResultDataTabs.Kinematics]: {
            hasData: $reportData.value?.kinematics?.hasData,
            titleTranslationKey: 'analyticsSearchResultLayout.tabs.kinematics',
            Component: KinematicsTab,
        },
    }), [$reportData.value]);

    const TabResultComponent = tabs[$selectedTab.value]?.Component;

    /**
     * @param {import('../../CodeSamples/TestByIdsReaction.json')} tests
     */
    const generateReportHandler = (tests) => {
        // eslint-disable-next-line no-unused-vars
        const convertUnits = (metersValue, fractionDigits = 4) =>
            ($selectedUnitType.value === UnitType.Meters ?
                metersValue
                : metersToFeet(metersValue)
            ).toFixed(fractionDigits);

        const generateDefaultDataResult = (includeDirectionalAverage = true) =>
            includeDirectionalAverage ?
                ({
                    hasData: false,
                    charts: {
                        distribution: [],
                        directionalAverage: {},
                        directionalAverageTemp: {
                            forward: { value: 0, count: 0 },
                            forwardRight: { value: 0, count: 0 },
                            right: { value: 0, count: 0 },
                            backwardRight: { value: 0, count: 0 },
                            backward: { value: 0, count: 0 },
                            backwardLeft: { value: 0, count: 0 },
                            left: { value: 0, count: 0 },
                            forwardLeft: { value: 0, count: 0 },
                        },
                        trend: []
                    },
                    table: []
                })
                : ({
                    hasData: false,
                    charts: {
                        distribution: [],
                        trend: []
                    },
                    table: []
                });

        const reactionTimeData = generateDefaultDataResult();
        const dynamicReactionTimeData = generateDefaultDataResult();
        const speedData = generateDefaultDataResult();
        const accelerationData = generateDefaultDataResult();
        const decelerationData = generateDefaultDataResult();
        const decelerationIndexData = generateDefaultDataResult();
        const totalDistanceData = generateDefaultDataResult(false);
        const caloriesData = generateDefaultDataResult(false);
        const cognitiveData = generateDefaultDataResult(false);
        const swayData = generateDefaultDataResult();
        const missesData = generateDefaultDataResult(false);
        // this has only table data for now
        const kinematicsData = generateDefaultDataResult(false);
        const generateDefaultKinematicChartData = () => ({
            trend: [],
            distribution: []
        });

        kinematicsData.charts.neckRotation = generateDefaultKinematicChartData();
        kinematicsData.charts.spineFlexion = generateDefaultKinematicChartData();
        kinematicsData.charts.spineRotation = generateDefaultKinematicChartData();
        kinematicsData.charts.leftShoulderFlexion = generateDefaultKinematicChartData();
        kinematicsData.charts.rightShoulderFlexion = generateDefaultKinematicChartData();
        kinematicsData.charts.leftShoulderAbduction = generateDefaultKinematicChartData();
        kinematicsData.charts.rightShoulderAbduction = generateDefaultKinematicChartData();
        kinematicsData.charts.leftElbowFlexion = generateDefaultKinematicChartData();
        kinematicsData.charts.rightElbowFlexion = generateDefaultKinematicChartData();

        kinematicsData.charts.trunkLean = generateDefaultKinematicChartData();
        kinematicsData.charts.pelvicTilt = generateDefaultKinematicChartData();
        kinematicsData.charts.pelvicRotation = generateDefaultKinematicChartData();
        kinematicsData.charts.shoulderRotation = generateDefaultKinematicChartData();
        kinematicsData.charts.thoracicRotation = generateDefaultKinematicChartData();
        kinematicsData.charts.lumbarRotation = generateDefaultKinematicChartData();
        kinematicsData.charts.leftHipRotation = generateDefaultKinematicChartData();
        kinematicsData.charts.rightHipRotation = generateDefaultKinematicChartData();
        kinematicsData.charts.leftKneeValgusVarus = generateDefaultKinematicChartData();
        kinematicsData.charts.rightKneeValgusVarus = generateDefaultKinematicChartData();
        kinematicsData.charts.leftKneeFlexion = generateDefaultKinematicChartData();
        kinematicsData.charts.rightKneeFlexion = generateDefaultKinematicChartData();
        kinematicsData.charts.leftAnkleDorsiflexion = generateDefaultKinematicChartData();
        kinematicsData.charts.rightAnkleDorsiflexion = generateDefaultKinematicChartData();

        tests = defaultSort(tests, 'startTime', SortOrder.Asc);

        for (const test of tests) {
            const fullName = test.userFullName;
            const username = test.username;
            const scriptedActivity = test.scriptedActivity.name;

            const scriptedActivityStepTrackedSettings = test.scriptedActivity.scriptedActivitySteps
                .reduce((a, c) => {
                    const uniqueStepKey = `${c.scriptedActivityStepId}_${c.orderId}`;
                    return {
                        ...a,
                        [uniqueStepKey]: parseTrackedSettings(c.settings),
                    }
                }, {});

            tests = defaultSort(test.testSteps, 'startTime', SortOrder.Asc);

            for (const step of test.testSteps) {
                const startDate = shortDate(step.startTime);
                const startDateTime = step.startTime;
                const scriptedActivityStep = test.scriptedActivity.scriptedActivitySteps.find(sas => sas.scriptedActivityStepId === step.scriptedActivityStepId)?.name;

                const uniqueStepKey = `${step.scriptedActivityStepId}_${step.orderId}`;
                const currentStepMetrics = scriptedActivityStepTrackedSettings[uniqueStepKey];

                const directionalAverageDataBuilder = (
                    dataAccumulator,
                    data,
                    averageValue,
                    mapping,
                    calculatedFields,
                    disableUnitConversion,
                    includeZeroValues
                ) => {
                    dataAccumulator.hasData = true;
                    const average = !averageValue || disableUnitConversion ?
                        averageValue
                        : Number(convertUnits(averageValue));

                    const shouldUseDataEntry = includeZeroValues ?
                        !isNaN(averageValue)
                        : averageValue;
                    if (shouldUseDataEntry) {
                        dataAccumulator.charts.distribution
                            .push(average);
                    }

                    dataAccumulator.charts.trend.push({
                        label: startDate,
                        value: average,
                    });

                    const tableMappedFields = {};
                    const directionalAverageTemp = dataAccumulator.charts.directionalAverageTemp;
                    for (const key in mapping) {
                        const mappedKey = mapping[key];
                        if (data[key]) {
                            const convertedValue = disableUnitConversion ?
                                data[key]
                                : convertUnits(data[key]);
                            directionalAverageTemp[mappedKey].value += Number(convertedValue);
                            directionalAverageTemp[mappedKey].count += 1;
                            tableMappedFields[mappedKey] = convertedValue;
                        }

                        tableMappedFields[mappedKey] = tableMappedFields[mappedKey] ?? NoData;
                    }

                    dataAccumulator.table.push({
                        testId: test.testId,
                        testStepId: step.testStepId,
                        date: startDateTime,
                        fullName,
                        username,
                        scriptedActivity,
                        scriptedActivityStep,
                        average: average ?? NoData,
                        ...tableMappedFields,
                        ...calculatedFields,
                    });
                };
                const mobility2ChartBuilder = (dataAccumulator, averageValue, disableUnitConversion, includeZeroValues) => {
                    dataAccumulator.hasData = true;
                    const average = !averageValue || disableUnitConversion ?
                        averageValue
                        : Number(convertUnits(averageValue));

                    const shouldUseDataEntry = includeZeroValues ?
                        !isNaN(averageValue)
                        : averageValue;
                    if (shouldUseDataEntry && isFinite(average)) {
                        dataAccumulator.charts.distribution
                            .push(average);
                    }

                    dataAccumulator.charts.trend.push({
                        label: startDate,
                        value: average,
                    });
                    dataAccumulator.table.push({
                        testId: test.testId,
                        testStepId: step.testStepId,
                        date: startDateTime,
                        fullName,
                        username,
                        scriptedActivity,
                        scriptedActivityStep,
                        average: !isNaN(average) && isFinite(average) && average ? average.toFixed(2) : NoData
                    });
                };

                const mobility = step.testResultMobility;
                if (mobility) {
                    if (currentStepMetrics?.trackReactionTime) {
                        const reactionTimeMapping = {
                            unadjustedReactionTimeForward: 'forward',
                            unadjustedReactionTimeForwardRight: 'forwardRight',
                            unadjustedReactionTimeRight: 'right',
                            unadjustedReactionTimeBackwardRight: 'backwardRight',
                            unadjustedReactionTimeBackward: 'backward',
                            unadjustedReactionTimeBackwardLeft: 'backwardLeft',
                            unadjustedReactionTimeLeft: 'left',
                            unadjustedReactionTimeForwardLeft: 'forwardLeft',
                        };
                        const dynamicReactionTimeMapping = {
                            reactionTimeForward: 'forward',
                            reactionTimeForwardRight: 'forwardRight',
                            reactionTimeRight: 'right',
                            reactionTimeBackwardRight: 'backwardRight',
                            reactionTimeBackward: 'backward',
                            reactionTimeBackwardLeft: 'backwardLeft',
                            reactionTimeLeft: 'left',
                            reactionTimeForwardLeft: 'forwardLeft',
                        };
                        const reactionTimeCalculatedFields = {
                            lrDifference: CalculatedReactionTimeLeftRightDifference(mobility.unadjustedReactionTimeLeft, mobility.unadjustedReactionTimeRight),
                            flFRDifference: CalculatedReactionTimeLeftRightDifference(mobility.unadjustedReactionTimeForwardLeft, mobility.unadjustedReactionTimeForwardRight),
                            blBRDifference: CalculatedReactionTimeLeftRightDifference(mobility.unadjustedReactionTimeBackwardLeft, mobility.unadjustedReactionTimeBackwardRight),
                        };
                        const dynamicReactionTimeCalculatedFields = {
                            lrDifference: CalculatedReactionTimeLeftRightDifference(mobility.reactionTimeLeft, mobility.reactionTimeRight),
                            flFRDifference: CalculatedReactionTimeLeftRightDifference(mobility.reactionTimeForwardLeft, mobility.reactionTimeForwardRight),
                            blBRDifference: CalculatedReactionTimeLeftRightDifference(mobility.reactionTimeBackwardLeft, mobility.reactionTimeBackwardRight),
                        };
                        directionalAverageDataBuilder(reactionTimeData, mobility, mobility.unadjustedReactionTimeAvg, reactionTimeMapping, reactionTimeCalculatedFields, true);
                        directionalAverageDataBuilder(dynamicReactionTimeData, mobility, mobility.reactionTimeAvg, dynamicReactionTimeMapping, dynamicReactionTimeCalculatedFields, true);
                    }

                    if (currentStepMetrics?.trackSpeed) {
                        const speedMapping = {
                            speedForward: 'forward',
                            speedForwardRight: 'forwardRight',
                            speedRight: 'right',
                            speedBackwardRight: 'backwardRight',
                            speedBackward: 'backward',
                            speedBackwardLeft: 'backwardLeft',
                            speedLeft: 'left',
                            speedForwardLeft: 'forwardLeft',
                        };
                        const speedCalculatedFields = {
                            lrDifference: CalculatedLeftRightDifference(mobility.speedLeft, mobility.speedRight),
                            flFRDifference: CalculatedLeftRightDifference(mobility.speedForwardLeft, mobility.speedForwardRight),
                            blBRDifference: CalculatedLeftRightDifference(mobility.speedBackwardLeft, mobility.speedBackwardRight),
                        };
                        directionalAverageDataBuilder(speedData, mobility, mobility.speedAvg, speedMapping, speedCalculatedFields, false);
                    }

                    if (currentStepMetrics?.trackAcceleration) {
                        const accelerationMapping = {
                            accelerationForward: 'forward',
                            accelerationForwardRight: 'forwardRight',
                            accelerationRight: 'right',
                            accelerationBackwardRight: 'backwardRight',
                            accelerationBackward: 'backward',
                            accelerationBackwardLeft: 'backwardLeft',
                            accelerationLeft: 'left',
                            accelerationForwardLeft: 'forwardLeft',
                        };
                        const accelerationCalculatedFields = {
                            lrDifference: CalculatedLeftRightDifference(mobility.accelerationLeft, mobility.accelerationRight),
                            flFRDifference: CalculatedLeftRightDifference(mobility.accelerationForwardLeft, mobility.accelerationForwardRight),
                            blBRDifference: CalculatedLeftRightDifference(mobility.accelerationBackwardLeft, mobility.accelerationBackwardRight),
                        };
                        directionalAverageDataBuilder(accelerationData, mobility, mobility.accelerationAvg, accelerationMapping, accelerationCalculatedFields, false);
                    }

                    if (currentStepMetrics?.trackDeceleration) {
                        const decelerationMapping = {
                            decelerationForward: 'forward',
                            decelerationForwardRight: 'forwardRight',
                            decelerationRight: 'right',
                            decelerationBackwardRight: 'backwardRight',
                            decelerationBackward: 'backward',
                            decelerationBackwardLeft: 'backwardLeft',
                            decelerationLeft: 'left',
                            decelerationForwardLeft: 'forwardLeft',
                        };
                        const decelerationCalculatedFields = {
                            lrDifference: CalculatedLeftRightDifference(mobility.decelerationLeft, mobility.decelerationRight),
                            flFRDifference: CalculatedLeftRightDifference(mobility.decelerationForwardLeft, mobility.decelerationForwardRight),
                            blBRDifference: CalculatedLeftRightDifference(mobility.decelerationBackwardLeft, mobility.decelerationBackwardRight),
                        };
                        directionalAverageDataBuilder(decelerationData, mobility, mobility.decelerationAvg, decelerationMapping, decelerationCalculatedFields, false);
                    }

                    if (currentStepMetrics?.trackAcceleration && currentStepMetrics?.trackDeceleration) {
                        mobility2ChartBuilder(decelerationIndexData, mobility.decelerationMax / mobility.accelerationMax, true, false);
                    }

                    if (currentStepMetrics?.trackDistance) {
                        mobility2ChartBuilder(totalDistanceData, mobility.totalDistance, false, false);
                    }

                    // TODO: Ask what tracking setting should be used
                    mobility2ChartBuilder(caloriesData, mobility.calories, true, false);
                }

                const cognitive = step.testResultCognitive;
                if (cognitive) {
                    const dataAccumulator = cognitiveData;
                    dataAccumulator.hasData = true;

                    let totalTargets = 0;
                    let totalCorrect = 0;
                    // correct
                    [
                        cognitive.numCongruentCorrectLeft,
                        cognitive.numIncongruentCorrectLeft,
                        cognitive.numCongruentCorrectRight,
                        cognitive.numIncongruentCorrectRight,
                    ].forEach(x => {
                        if (x) {
                            totalTargets++;
                            totalCorrect++;
                        }
                    });
                    // incorrect
                    [
                        cognitive.numCongruentIncorrectLeft,
                        cognitive.numIncongruentIncorrectLeft,
                        cognitive.numCongruentIncorrectRight,
                        cognitive.numIncongruentIncorrectRight,
                    ].forEach(x => {
                        if (x) {
                            totalTargets++;
                        }
                    });

                    const calculateAverageTime = (timeValue, numCount) => {
                        if (!timeValue || !numCount) {
                            return NoData;
                        }

                        return (timeValue / numCount).toFixed(2);
                    };

                    const tableData = {
                        promptTime: cognitive.promptTime,
                        congruentCorrectLeft: cognitive.numCongruentCorrectLeft,
                        averageTimeCongruentCorrectLeft: calculateAverageTime(cognitive.timeCongruentCorrectLeft, cognitive.numCongruentCorrectLeft),
                        congruentIncorrectLeft: cognitive.numCongruentIncorrectLeft,
                        averageTimeCongruentIncorrectLeft: calculateAverageTime(cognitive.timeCongruentIncorrectLeft, cognitive.numCongruentIncorrectLeft),
                        incongruentCorrectLeft: cognitive.numIncongruentCorrectLeft,
                        averageTimeIncongruentCorrectLeft: calculateAverageTime(cognitive.timeIncongruentCorrectLeft, cognitive.numIncongruentCorrectLeft),
                        incongruentIncorrectLeft: cognitive.numIncongruentIncorrectLeft,
                        averageTimeIncongruentIncorrectLeft: calculateAverageTime(cognitive.timeIncongruentIncorrectLeft, cognitive.numIncongruentIncorrectLeft),
                        congruentCorrectRight: cognitive.numCongruentCorrectRight,
                        averageTimeCongruentCorrectRight: calculateAverageTime(cognitive.timeCongruentCorrectRight, cognitive.numCongruentCorrectRight),
                        congruentIncorrectRight: cognitive.numCongruentIncorrectRight,
                        averageTimeCongruentIncorrectRight: calculateAverageTime(cognitive.timeCongruentIncorrectRight, cognitive.numCongruentIncorrectRight),
                        incongruentCorrectRight: cognitive.numIncongruentCorrectRight,
                        averageTimeIncongruentCorrectRight: calculateAverageTime(cognitive.timeIncongruentCorrectRight, cognitive.numIncongruentCorrectRight),
                        incongruentIncorrectRight: cognitive.numIncongruentIncorrectRight,
                        averageTimeIncongruentIncorrectRight: calculateAverageTime(cognitive.timeIncongruentIncorrectRight, cognitive.numIncongruentIncorrectRight),
                    };

                    if (totalTargets > 0) {
                        dataAccumulator.charts.distribution
                            .push((totalCorrect / totalTargets) * 100);
                    } else {
                        dataAccumulator.charts.distribution
                            .push(0);
                    }

                    dataAccumulator.charts.trend.push({
                        label: startDate,
                        value: totalTargets > 0 ?
                            parseFloat(((totalCorrect / totalTargets) * 100).toFixed(2))
                            : 0
                    });

                    const tableEntry = {
                        testId: test.testId,
                        testStepId: step.testStepId,
                        date: startDateTime,
                        fullName,
                        username,
                        scriptedActivity,
                        scriptedActivityStep,
                    };
                    for (const key in tableData) {
                        tableEntry[key] = tableData[key] ?? NoData;
                    }

                    dataAccumulator.table.push(tableEntry);
                }

                const balance = step.testResultBalance;
                if (balance) {
                    if (currentStepMetrics?.trackSway) {
                        const swayMapping = {
                            swayForward: 'forward',
                            swayForwardRight: 'forwardRight',
                            swayRight: 'right',
                            swayBackwardRight: 'backwardRight',
                            swayBackward: 'backward',
                            swayBackwardLeft: 'backwardLeft',
                            swayLeft: 'left',
                            swayForwardLeft: 'forwardLeft',
                        };
                        directionalAverageDataBuilder(swayData, balance, balance.totalSway, swayMapping, {}, false, true);
                    }

                    if (currentStepMetrics?.trackMisses) {
                        mobility2ChartBuilder(missesData, balance.totalMisses, true, true);
                    }
                }

                const kinematics = step.testResultKinematic;
                if (kinematics) {
                    if (
                        kinematics.rightKneeValgusVarusDeg == null
                        && kinematics.rightKneeFlexion == null
                        && kinematics.rightAnkleDorsiflexion == null
                        && kinematics.leftKneeValgusVarusDeg == null
                        && kinematics.leftKneeFlexion == null
                        && kinematics.leftAnkleDorsiflexion == null
                        && kinematics.neckRotation == null
                        && kinematics.spineFlexion == null
                        && kinematics.spineRotation == null
                        && kinematics.leftShoulderFlexion == null
                        && kinematics.rightShoulderFlexion == null
                        && kinematics.leftShoulderAbduction == null
                        && kinematics.rightShoulderAbduction == null
                        && kinematics.leftElbowFlexion == null
                        && kinematics.rightElbowFlexion == null
                        && kinematics.leftHipRotation == null
                        && kinematics.rightHipRotation == null
                    ) {
                        continue;
                    }

                    kinematicsData.hasData = true;
                    const degreeFormatter = (valueDeg, valueDir) => {
                        if (valueDeg == null || valueDeg == 0) {
                            return;
                        }

                        valueDeg = valueDeg.toFixed(0);
                        return valueDir ?
                            `${valueDeg}\u00B0 ${valueDir}`
                            : `${valueDeg}\u00B0`;
                    };

                    const pushKinematicChartData = (fieldName, data) => {
                        if (data == null || data == 0) {
                            return;
                        }

                        if (!kinematicsData.charts[fieldName]) {
                            kinematicsData.charts[fieldName] = generateDefaultKinematicChartData();
                        }

                        kinematicsData.charts[fieldName].distribution.push(data);
                        kinematicsData.charts[fieldName].trend.push({
                            label: startDate,
                            value: data
                        });
                    }

                    const tableData = {};

                    if (currentStepMetrics?.trackSpine) {
                        pushKinematicChartData('neckRotation', kinematics.neckRotation);
                        pushKinematicChartData('spineFlexion', kinematics.spineFlexion);
                        pushKinematicChartData('spineRotation', kinematics.spineRotation);
                        tableData.neckRotation = degreeFormatter(kinematics.neckRotation, kinematics.neckRotationDir);
                        tableData.spineFlexion = degreeFormatter(kinematics.spineFlexion, kinematics.spineFlexionDir);
                        tableData.spineRotation = degreeFormatter(kinematics.spineRotation, kinematics.spineRotationDir);
                    } else {
                        tableData.neckRotation = null;
                        tableData.spineFlexion = null;
                        tableData.spineRotation = null;
                    }

                    if (currentStepMetrics?.trackArms) {
                        pushKinematicChartData('leftShoulderFlexion', kinematics.leftShoulderFlexion);
                        pushKinematicChartData('rightShoulderFlexion', kinematics.rightShoulderFlexion);
                        pushKinematicChartData('leftShoulderAbduction', kinematics.leftShoulderAbduction);
                        pushKinematicChartData('rightShoulderAbduction', kinematics.rightShoulderAbduction);
                        pushKinematicChartData('leftElbowFlexion', kinematics.leftElbowFlexion);
                        pushKinematicChartData('rightElbowFlexion', kinematics.rightElbowFlexion);
                        tableData.leftShoulderFlexion = degreeFormatter(kinematics.leftShoulderFlexion);
                        tableData.rightShoulderFlexion = degreeFormatter(kinematics.rightShoulderFlexion);
                        tableData.leftShoulderAbduction = degreeFormatter(kinematics.leftShoulderAbduction);
                        tableData.rightShoulderAbduction = degreeFormatter(kinematics.rightShoulderAbduction);
                        tableData.leftElbowFlexion = degreeFormatter(kinematics.leftElbowFlexion);
                        tableData.rightElbowFlexion = degreeFormatter(kinematics.rightElbowFlexion);
                    } else {
                        tableData.leftShoulderFlexion = null;
                        tableData.rightShoulderFlexion = null;
                        tableData.leftShoulderAbduction = null;
                        tableData.rightShoulderAbduction = null;
                        tableData.leftElbowFlexion = null;
                        tableData.rightElbowFlexion = null;
                    }

                    if (currentStepMetrics?.trackTorso) {
                        pushKinematicChartData('trunkLean', kinematics.trunkLeanDeg);
                        pushKinematicChartData('pelvicTilt', kinematics.trunkLatFlexionDeg);
                        pushKinematicChartData('pelvicRotation', kinematics.pelvisRotation);
                        pushKinematicChartData('shoulderRotation', kinematics.shoulderRotation);
                        pushKinematicChartData('thoracicRotation', kinematics.thoracicRotation);
                        pushKinematicChartData('lumbarRotation', kinematics.lumbarRotation);
                        tableData.trunkLean = degreeFormatter(kinematics.trunkLeanDeg, kinematics.trunkLeanDir);
                        tableData.pelvicTilt = degreeFormatter(kinematics.trunkLatFlexionDeg, kinematics.trunkLatFlexion);
                        tableData.pelvicRotation = degreeFormatter(kinematics.pelvisRotation, kinematics.pelvisRotationDir);
                        tableData.shoulderRotation = degreeFormatter(kinematics.shoulderRotation, kinematics.shoulderRotationDir);
                        tableData.thoracicRotation = degreeFormatter(kinematics.thoracicRotation, kinematics.thoracicRotationDir);
                        tableData.lumbarRotation = degreeFormatter(kinematics.lumbarRotation, kinematics.lumbarRotationDir);
                    } else {
                        tableData.trunkLean = null;
                        tableData.pelvicTilt = null;
                        tableData.pelvicRotation = null;
                        tableData.shoulderRotation = null;
                        tableData.thoracicRotation = null;
                        tableData.lumbarRotation = null;
                    }

                    if (currentStepMetrics?.trackLegs) {
                        pushKinematicChartData('leftHipRotation', kinematics.leftHipRotation);
                        pushKinematicChartData('rightHipRotation', kinematics.rightHipRotation);
                        pushKinematicChartData('leftKneeValgusVarus', kinematics.leftKneeValgusVarusDeg);
                        pushKinematicChartData('rightKneeValgusVarus', kinematics.rightKneeValgusVarusDeg);
                        pushKinematicChartData('leftKneeFlexion', kinematics.leftKneeFlexion);
                        pushKinematicChartData('rightKneeFlexion', kinematics.rightKneeFlexion);
                        pushKinematicChartData('leftAnkleDorsiflexion', kinematics.leftAnkleDorsiflexion);
                        pushKinematicChartData('rightAnkleDorsiflexion', kinematics.rightAnkleDorsiflexion);
                        tableData.plantFoot = kinematics.plantFoot;
                        tableData.stanceWidthRatio = kinematics.stanceWidthRatio;
                        tableData.stanceWidthDistance = !kinematics.stanceWidthDistance ?
                            kinematics.stanceWidthDistance?.toFixed(4)
                            : convertUnits(kinematics.stanceWidthDistance, 4);
                        tableData.leftHipRotation = degreeFormatter(kinematics.leftHipRotation, kinematics.leftHipRotationDir);
                        tableData.rightHipRotation = degreeFormatter(kinematics.rightHipRotation, kinematics.rightHipRotationDir);
                        tableData.leftKneeValgusVarus = degreeFormatter(kinematics.leftKneeValgusVarusDeg, kinematics.leftKneeValgusVarus);
                        tableData.rightKneeValgusVarus = degreeFormatter(kinematics.rightKneeValgusVarusDeg, kinematics.rightKneeValgusVarus);
                        tableData.leftKneeFlexion = degreeFormatter(kinematics.leftKneeFlexion);
                        tableData.rightKneeFlexion = degreeFormatter(kinematics.rightKneeFlexion);
                        tableData.leftAnkleDorsiflexion = degreeFormatter(kinematics.leftAnkleDorsiflexion);
                        tableData.rightAnkleDorsiflexion = degreeFormatter(kinematics.rightAnkleDorsiflexion);
                    } else {
                        tableData.plantFoot = null;
                        tableData.stanceWidthRatio = null;
                        tableData.stanceWidthDistance = null;
                        tableData.leftHipRotation = null;
                        tableData.rightHipRotation = null;
                        tableData.leftKneeValgusVarus = null;
                        tableData.rightKneeValgusVarus = null;
                        tableData.leftKneeFlexion = null;
                        tableData.rightKneeFlexion = null;
                        tableData.leftAnkleDorsiflexion = null;
                        tableData.rightAnkleDorsiflexion = null;
                    }

                    const tableEntry = {
                        testId: test.testId,
                        testStepId: step.testStepId,
                        date: startDateTime,
                        fullName,
                        username,
                        scriptedActivity,
                        scriptedActivityStep,
                    };
                    for (const key in tableData) {
                        tableEntry[key] = tableData[key] ?? NoData;
                    }

                    kinematicsData.table.push(tableEntry);
                }
            }
        }

        const calculatedDirectionalAverage = (data) => {
            const directionalAverageTemp = data.charts.directionalAverageTemp;
            return {
                ...data,
                charts: {
                    distribution: data.charts.distribution,
                    trend: data.charts.trend,
                    directionalAverage: Object
                        .keys(directionalAverageTemp)
                        .map(key => ({
                            label: key,
                            value: directionalAverageTemp[key].count ?
                                directionalAverageTemp[key].value / directionalAverageTemp[key].count
                                : undefined
                        }))
                }
            };
        }

        const reportData = {
            unitType: $selectedUnitType.value,
        };
        if (reactionTimeData.hasData) {
            reportData.reactionTime = calculatedDirectionalAverage(reactionTimeData);
        }
        if (dynamicReactionTimeData.hasData) {
            reportData.dynamicReactionTime = calculatedDirectionalAverage(dynamicReactionTimeData);
        }
        if (speedData.hasData) {
            reportData.speed = calculatedDirectionalAverage(speedData);
        }
        if (accelerationData.hasData) {
            reportData.acceleration = calculatedDirectionalAverage(accelerationData);
        }
        if (decelerationData.hasData) {
            reportData.deceleration = calculatedDirectionalAverage(decelerationData);
        }
        if (decelerationIndexData.hasData) {
            reportData.decelerationIndex = decelerationIndexData;
        }
        if (totalDistanceData.hasData) {
            reportData.totalDistance = totalDistanceData;
        }
        if (caloriesData.hasData) {
            reportData.calories = caloriesData;
        }
        if (cognitiveData.hasData) {
            reportData.cognitive = cognitiveData;
        }
        if (swayData.hasData) {
            reportData.sway = calculatedDirectionalAverage(swayData);
        }
        if (missesData.hasData) {
            reportData.misses = missesData;
        }
        if (kinematicsData.hasData) {
            reportData.kinematics = kinematicsData;
        }
        $reportData.set(reportData);
    }

    const tabColumns = 6;
    const tabRows = Math.ceil(Object.keys(tabs).length / tabColumns);

    return (
        <>
            <div css={searchResultLayoutStyles.tabNavigation}>
                <Button
                    className="tests-tab"
                    onClick={() => $selectedTab.set(0)}
                >
                    {t('analyticsSearchResultLayout.tabs.tests')}
                </Button>
                {$isGeneratingReportData.value?<CircularLoader />:
                <table className={`data-types ${$reportData.value ? '' : 'no-report-data'}`}>
                    <tbody>
                        {[...Array(tabRows)].map((_, rowIndex) => {
                            const firstRowIndex = tabColumns * rowIndex;
                            return (
                                <tr key={firstRowIndex}>
                                    {Object.values(tabs).slice(firstRowIndex, firstRowIndex + tabColumns)
                                        .map((tab, columnIndex) => (
                                            <td key={tab.titleTranslationKey}>
                                                <Button
                                                    disabled={!$reportData.value || !tab.hasData}
                                                    variant={$selectedTab.value === (1 + firstRowIndex + columnIndex) ?
                                                        'contained'
                                                        : 'text'
                                                    }
                                                    onClick={() => {
                                                        $selectedTab.set(1 + firstRowIndex + columnIndex);
                                                    }}
                                                >
                                                    {t(tab.titleTranslationKey)}
                                                </Button>
                                            </td>
                                        ))
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            }
            </div>
            <div css={searchResultLayoutStyles.tabContent}>
                {$selectedTab.value === 0 ?
                    <TestsTab
                        $availableTests={$availableTests}
                        $selectedTests={$selectedTests}
                        $cachedTestsList={$cachedTestsList}
                        $selectedUnitType={$selectedUnitType}
                        $rawReportData={$rawReportData}
                        onGenerateReport={generateReportHandler}
                        $isGeneratingReportData = {$isGeneratingReportData}
                    />
                    : TabResultComponent ?
                        <TabResultComponent reportData={$reportData.value} />
                    : 'Something went wrong you should not see this! :('
                }
            </div>
        </>
    )
}
