/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect, useMemo } from "react"
import { useHook } from "../../../CoreComponents/Utils"
import { TableCSF } from "../../../CoreComponents/TableComponents/TableCSF";
import { AdminTypes, AdvancedSearchMatchType, AdvancedSearchType, SortOrder, TableOptionsTypes, TestStatusesTranslationKeysObj, DateRangeStartAndEndTimes } from "../../../Utils/Constants";
import { defaultSort, shortTime } from "../../../Utils/Common";
import { IconButton } from "../../../CoreComponents/Button";
import { TrashCanIcon } from "../../../CoreComponents/CustomIcons";
import { InternalLink } from "../../../CoreComponents/Layout";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { TestService } from "../../../Services/TestService"
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../../CoreComponents/Translation";
import { DeleteConfirmationModal } from "../../../CoreComponents/DeleteConfirmationModal";
import { setTests } from "../../../globalStates/tableDataState";

const style = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `
}

const mapTestData = (tests) => {
    return tests.map(t => ({
        ...t,
        startTime: new Date(t.startTime),
        endTime: new Date(t.endTime),
        SACategory: `${t.scriptedActivityName} (${t.categoryName})`,
        testSteps: (t.testSteps || []).map(ts => ({
            ...ts,
            startTime: t.startTime ? new Date(t.startTime) : null,
            endTime: t.endTime ? new Date(t.endTime) : null,
        }))
    }))
}

export const Tests = () => {
    const tableData = useSelector((state) => state.tableDataState);
    const $tests = useHook(mapTestData(tableData.tests));
    const { t } = useTranslation();
    const $testForDelete = useHook(null);
    const storeState = useSelector((state) => state.storeState);
    const sortOptions = useSelector((state) => state.tableSortState);
    const $isLoading = useHook(!Boolean(tableData.tests.length));
    const $scriptedActivityNames = useHook([]);
    const $categoryNames = useHook([]);
    const $scriptedActivitySites = useHook([]);
    const $scriptedActivityStatuses = useHook([]);
    const dispatch = useDispatch();
    let dateRangeOptionTemp = 5;

    if(sortOptions[TableOptionsTypes.Tests] && sortOptions[TableOptionsTypes.Tests].length > 6){
        dateRangeOptionTemp = sortOptions[TableOptionsTypes.Tests][6];
    }

    const $dateRangeOption = useHook(dateRangeOptionTemp);

    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const isLessThenSuperAdmin = storeState.currentUser.admin.adminTypeId < AdminTypes.SuperAdmin;
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId > AdminTypes.SuperAdmin;

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <IconButton
                        component={InternalLink}
                        to={`/reports/test/${rowData.testId}`}
                    >
                        <SignalCellularAltIcon />
                    </IconButton>
                );
            }
        },
        {
            id: 'SACategory',
            label: t('tests.table.scriptedActivity'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($scriptedActivityNames.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.scriptedActivityName;
            }
        },
        {
            id: 'scriptedActivityAlias',
            label: '',
            columnWidth: 0,
            CellRender: ({ rowData }) => {
                return '';
            }
        },
        {
            id: 'categoryName',
            label: t('tests.table.category'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($categoryNames.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.categoryName;
            }
        },
        {
            id: 'startTime',
            label: t('tests.table.time'),
            advancedSearch: {
                hidden: true
            },
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.startTime ? shortTime(new Date(rowData.startTime)) : '-';
            }
        },
        {
            id: 'userFullName',
            label: t('tests.table.name'),
            isSortable: true
        },
        {
            id: 'username',
            label: t('tests.table.username'),
            isSortable: true
        },
        {
            id: 'siteName',
            label: t('tests.table.site'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($scriptedActivitySites.value, 'value', SortOrder.Asc)
            },
            isSortable: true
        },
        {
            id: 'testStatus',
            label: t('tests.table.status'),
            advancedSearch: {
                type: AdvancedSearchType.Select,
                label: '',
                matchType: AdvancedSearchMatchType.Exact,
                values: defaultSort($scriptedActivityStatuses.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            textRenderer: ({ rowData }) => {
                return t(TestStatusesTranslationKeysObj[rowData.testStatus]);
            },
            CellRender: ({ rowData }) => {
                return t(TestStatusesTranslationKeysObj[rowData.testStatus]);
            }
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton disabled={isTrazerViewAdmin} onClick={() => $testForDelete.set(rowData)}>
                            <TrashCanIcon />
                        </IconButton>
                    </>
                )
            }
        },
    ], [t, $scriptedActivityNames.value, $scriptedActivitySites.value, $scriptedActivityStatuses.value])

    const deleteTest = (testId) => {
        TestService.deleteTest(testId)
            .then(() => {
                $tests.set($tests.value.filter(x => x.testId !== testId));
                $testForDelete.set(null);
            });
    }

    useEffect(() => {
        let siteIds = null;
        let customerIds = null;
        $isLoading.set(true);

        if (!isTrazerAdmin) {
            if (isLessThenSuperAdmin) {
                siteIds = storeState.currentUser.admin.adminSites.map(site => site.siteId);
            } else {
                customerIds = [storeState.currentUser.admin.customerId];
            }
        }

        TestService.getAllTestsNoMap(99999, null, customerIds, siteIds, DateRangeStartAndEndTimes[$dateRangeOption.value].startDate, DateRangeStartAndEndTimes[$dateRangeOption.value].endDate)
            .then((tests) => {
                const mappedTests = mapTestData(tests);

                const scriptedActivityNames = {};
                const scriptedActivityCategories = {};
                const scriptedActivitySites = {};
                const scriptedActivityStatuses = {};
                for (const test of mappedTests) {
                    scriptedActivityNames[test.SACategory] = 1;
                    scriptedActivityCategories[test.categoryName] = 1;
                    scriptedActivitySites[test.siteName] = 1;
                    scriptedActivityStatuses[test.testStatus] = 1;
                };
                $tests.set(mappedTests);
                dispatch(setTests(tests));
                $scriptedActivityNames.set(Object.keys(scriptedActivityNames).map(x => {
                    const value = x;
                    const label = x;
                    return { value, label };
                }))
                $categoryNames.set(Object.keys(scriptedActivityCategories).map(x => {
                    const value = x;
                    const label = x;
                    return { value, label };
                }))
                $scriptedActivitySites.set(Object.keys(scriptedActivitySites).map(x => {
                    return {
                        value: x,
                        label: x
                    }
                }))
                $scriptedActivityStatuses.set(Object.keys(scriptedActivityStatuses).map(x => {
                    return {
                        value: x,
                        label: t(TestStatusesTranslationKeysObj[x])
                    }
                }))
            })
            .finally(() => $isLoading.set(false))
    }, [storeState.currentUser, $dateRangeOption.value])

    return (
        <div css={style.root}>
            <TableCSF
                headCells={headCells}
                rows={$tests.value}
                rowKeySelector={r => r.testId}
                isLoading={$isLoading.value}
                showExport
                showDateRange={true}
                $dateRangeOption={$dateRangeOption}
                enablePersistingFilters
                pageName={TableOptionsTypes.Tests}
            />
            <DeleteConfirmationModal
                onDelete={() => deleteTest($testForDelete.value.testId)}
                open={Boolean($testForDelete.value)}
                onCancel={() => $testForDelete.set(null)}
                title={t('tests.deleteModal.title')}
                description={t('tests.deleteModal.description')}
                deleteLabel={t('actions.delete')}
            />
        </div>
    )
}
