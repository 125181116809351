/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Button } from '../../CoreComponents/Button';
import { Card } from '../../CoreComponents/Card';
import { TableCSF } from '../../CoreComponents/TableComponents/TableCSF';
import { useTranslation } from '../../CoreComponents/Translation';
import { useHook } from '../../CoreComponents/Utils';
import { DistributionChart } from '../../CoreComponents/ChartComponents/DistributionChart';
import { LineChart } from '../../CoreComponents/ChartComponents/LineChart';
import { shortTime } from '../../Utils/Common';

const cognitiveTabStyles = {
    root: css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    `,
    tabSelection: css`
        border: 2px solid #00ABA5;
        border-radius: 8px;
        padding: 2px;
        margin: 40px auto 20px auto;
        width: 256px;

        & > button {
            border-radius: 4px;
            width: 50%;
            min-width: unset;
        }
    `,
    tabContent: css`
        height: calc(100% - 80px);
        overflow: auto;
    `,
    graphTabContent: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 32px 0 12px;
        height: 100%;

        & > * {
            max-height: 600px;
        }
    `,
};

const SubTabs = {
    Graphs: 1,
    Table: 2
};

export const SubTabsTranslationKeysArr = [
    { value: SubTabs.Graphs, translationKey: 'analytics.subTabs.graphs' },
    { value: SubTabs.Table, translationKey: 'analytics.subTabs.table' },
];

export const CognitiveTab = ({ reportData }) => {
    const { t } = useTranslation();
    const $selectedTab = useHook(SubTabs.Graphs);
    const chartData = useMemo(() => {
        const { charts } = reportData.cognitive;
        return {
            distribution: charts.distribution,
            trend: [{
                label: t('analytics.charts.cognitiveTrend'),
                data: charts.trend,
                exportSettings: {
                    xAxis: t('analytics.chartExportDataField.date'),
                    yAxis: t('analytics.charts.cognitiveTrend'),
                },
            }],
        };
    }, [t, reportData.cognitive]);

    const headCells = useMemo(() => [
        {
            id: 'date',
            label: t('analytics.table.date'),
            columnWidth: 150,
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.date ? shortTime(rowData.date) : '-';
            }
        },
        {
            id: 'fullName',
            label: t('analytics.table.fullName'),
            columnWidth: 150,
            isSortable: true
        },
        {
            id: 'username',
            label: t('analytics.table.username'),
            columnWidth: 150,
            isSortable: true
        },
        {
            id: 'scriptedActivity',
            label: t('analytics.table.scriptedActivity'),
            columnWidth: 200,
            isSortable: true
        },
        {
            id: 'scriptedActivityStep',
            label: t('analytics.table.scriptedActivityStep'),
            columnWidth: 240,
            isSortable: true
        },
        {
            id: 'promptTime',
            label: t('analytics.table.promptTime'),
            columnWidth: 150,
            isSortable: true
        },
        {
            id: 'congruentCorrectLeft',
            label: t('analytics.table.congruentCorrectLeft'),
            columnWidth: 230,
            isSortable: true
        },
        {
            id: 'averageTimeCongruentCorrectLeft',
            label: t('analytics.table.averageTimeCongruentCorrectLeft'),
            columnWidth: 340,
            isSortable: true
        },
        {
            id: 'congruentIncorrectLeft',
            label: t('analytics.table.congruentIncorrectLeft'),
            columnWidth: 240,
            isSortable: true
        },
        {
            id: 'averageTimeCongruentIncorrectLeft',
            label: t('analytics.table.averageTimeCongruentIncorrectLeft'),
            columnWidth: 350,
            isSortable: true
        },
        {
            id: 'incongruentCorrectLeft',
            label: t('analytics.table.incongruentCorrectLeft'),
            columnWidth: 240,
            isSortable: true
        },
        {
            id: 'averageTimeIncongruentCorrectLeft',
            label: t('analytics.table.averageTimeIncongruentCorrectLeft'),
            columnWidth: 350,
            isSortable: true
        },
        {
            id: 'incongruentIncorrectLeft',
            label: t('analytics.table.incongruentIncorrectLeft'),
            columnWidth: 250,
            isSortable: true
        },
        {
            id: 'averageTimeIncongruentIncorrectLeft',
            label: t('analytics.table.averageTimeIncongruentIncorrectLeft'),
            columnWidth: 360,
            isSortable: true
        },
        {
            id: 'congruentCorrectRight',
            label: t('analytics.table.congruentCorrectRight'),
            columnWidth: 240,
            isSortable: true
        },
        {
            id: 'averageTimeCongruentCorrectRight',
            label: t('analytics.table.averageTimeCongruentCorrectRight'),
            columnWidth: 350,
            isSortable: true
        },
        {
            id: 'congruentIncorrectRight',
            label: t('analytics.table.congruentIncorrectRight'),
            columnWidth: 250,
            isSortable: true
        },
        {
            id: 'averageTimeCongruentIncorrectRight',
            label: t('analytics.table.averageTimeCongruentIncorrectRight'),
            columnWidth: 360,
            isSortable: true
        },
        {
            id: 'incongruentCorrectRight',
            label: t('analytics.table.incongruentCorrectRight'),
            columnWidth: 250,
            isSortable: true
        },
        {
            id: 'averageTimeIncongruentCorrectRight',
            label: t('analytics.table.averageTimeIncongruentCorrectRight'),
            columnWidth: 360,
            isSortable: true
        },
        {
            id: 'incongruentIncorrectRight',
            label: t('analytics.table.incongruentIncorrectRight'),
            columnWidth: 260,
            isSortable: true
        },
        {
            id: 'averageTimeIncongruentIncorrectRight',
            label: t('analytics.table.averageTimeIncongruentIncorrectRight'),
            columnWidth: 370,
            isSortable: true
        },
        {
            id: 'quick-fix',
            label: '',
            CellRender: () => null
        },
    ], [t]);

    const unitType = ''; // t('constants.units.percentage');
    const unitTypeShort = '%'; // t('constants.units.percentageShort');

    const { table } = reportData.cognitive;
    return (
        <div css={cognitiveTabStyles.root}>
            <div css={cognitiveTabStyles.tabContent}>
                {$selectedTab.value === SubTabs.Graphs ?
                    <div css={cognitiveTabStyles.graphTabContent}>
                        {chartData.distribution.length > 1 &&
                            <Card title={t('analytics.charts.cognitiveDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.cognitiveDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.cognitiveDistribution')}
                                    enableOptionsMenu
                                    data={chartData.distribution}
                                    bottomAxisTitle={t('analytics.charts.cognitiveDistribution.xAxis')}
                                />
                            </Card>
                        }
                        <Card title={t('analytics.charts.cognitiveTrend')}>
                            <LineChart
                                title={t('analytics.charts.cognitiveTrend')}
                                unitType={unitType}
                                exportFileName={t('analytics.charts.cognitiveTrend')}
                                enableOptionsMenu
                                datasets={chartData.trend}
                                xAxisLabel={t('analytics.charts.xAxis')}
                                yAxisLabel={t('analytics.charts.cognitiveTrend.yAxis')}
                            />
                        </Card>
                    </div>
                    :
                    <TableCSF
                        headCells={headCells}
                        rows={table}
                        totalItemsCount={table.length}
                        paginationSettingsPosition="bottom-left"
                        rowKeySelector={r => r.testStepId}
                        isLoading={false}
                        showAdvancedSearch={false}
                        showExport
                        exportFileNameGenerator={() => `cognitive-${(new Date()).toISOString().split('T')[0]}`}
                    />
                }
            </div>
            <div css={cognitiveTabStyles.tabSelection}>
                {SubTabsTranslationKeysArr.map(x => (
                    <Button
                        key={x.value}
                        variant={$selectedTab.value === x.value ?
                            'contained'
                            : 'text'
                        }
                        onClick={() => {
                            $selectedTab.set(x.value);
                        }}
                    >
                        {t(x.translationKey)}
                    </Button>
                ))}
            </div>
        </div>
    );
};
