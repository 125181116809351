// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useMemo } from "react";
import { useHook, useObjectHook } from "../Utils";
import { debounce } from "../../Utils/Common";
import { Input } from "../Input";
import { Button } from "../Button";
import { FilterCircleXmarkIcon, FilterIcon, MagnifyingGlassIcon, SortDownIcon, SortUpIcon } from "../CustomIcons";
import { Modal } from "../Modal";
import { SingleSelect } from "../SelectListComponents/SelectList";
import { SelectOption } from "../SelectListComponents/SelectOption";
import { MultiSelect, MultiSelectOption } from "../SelectListComponents/MultiSelect";
import { Menu, MenuItem, Typography, css } from "@mui/material";
import { AdvancedSearchDefaultValue, AdvancedSearchType, ExportTypesTranslationKeysArr, DateRangesTranslationKeysArr } from "../../Utils/Constants";
import { useTranslation } from "../Translation";

const styles = {
    clearFiltersButton: css`
        width: 100%;
        height: 100%;
        min-width: unset;
        border-radius: 0px 8px 8px 0px;
    `,
    advancedSearch: css`
        width: unset;
        height: 85vh;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > * {
            margin: 0;
            width: 100%;
        }

        & > h2 {
            width: 100%;
            padding: 0;
        }

        & > .filters {
            height: calc(100% - 146px);
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-top: 40px;
            margin-bottom: 40px;
            // margin: 40px 0;
            padding-right: 20px;

            & > * {
                margin: 0 0 30px 0;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        & > .actions {
            display: inline-flex;
            margin-top: auto;
            width: 100%;

            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }
    `,
    slim: css`
        & .selector-wrapper {
            min-width: 200px;
        }

        & ul {
            max-height: 350px;
        }
    `,
}

export const TableActions = ({
    headCells,
    showExport,
    showSearch,
    showAdvancedSearch,
    showDateRange,
    onExport,
    customActions,
    isOverflowing,
    sortOptions,
    $searchText,
    $advancedSearch,
    $headCells,
    $dateRangeOption
}) => {
    const { t } = useTranslation();
    const $advancedSearchTemp = useObjectHook(null);
    const $exportSelectionAnchor = useHook(null);
    const $searchInputText = useHook('');

    const advancedSearchSettings = useMemo(() =>
        headCells
            .filter(x => x.advancedSearch)
            .reduce(
                (accumulator, current) => ({ ...accumulator, [current.id]: current.advancedSearch }),
                {}
            )
        , [headCells]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandler = useCallback(
        debounce(v => $searchInputText.set(v), v => $searchText.set(v), 500)
        , []);

    const handleOpenExportMenu = (e) => $exportSelectionAnchor.set(e.currentTarget);
    const handleCloseExportMenu = () => $exportSelectionAnchor.set(null);

    const resetAdvancedSearch = () =>
        $advancedSearch.set(
            $headCells.reduce((a, c) => ({ ...a, [c.id]: c.advancedSearch?.hasOwnProperty('defaultValue') ? c.advancedSearch?.defaultValue : c.advancedSearch?.type === AdvancedSearchType.MultiSelect ? [] : AdvancedSearchDefaultValue }), {})
        );

    useEffect(() => {
        if (sortOptions) {
            return
        }
        resetAdvancedSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headCells]);

    return (
        <div className="actions" style={{ paddingRight: isOverflowing.height ? 42 : 32 }}>
            <div className="custom-actions">
                {customActions}
            </div>
            {showSearch &&
                <>
                    {showDateRange &&
                        <SingleSelect
                        key="testDateSelection"
                        label={t('manage.dateFilter')}
                        design="slim"
                        css={styles.slim}
                        $value={$dateRangeOption}
                        >
                            {DateRangesTranslationKeysArr.map(x => (
                                <SelectOption key={x.value} value={x.value}>{t(x.translationKey)}</SelectOption>
                            ))}
                        </SingleSelect>
                    }
                    <Input
                        className="search"
                        value={$searchInputText.value}
                        onChange={debouncedHandler}
                        placeholder={t('input.searchPlaceholder')}
                        endIcon={
                            ($searchInputText.value
                                || $searchText.value
                                || Object.keys($advancedSearch.value || {}).some(key => {
                                    if (advancedSearchSettings[key]?.hasOwnProperty('defaultValue')) {
                                        return $advancedSearch.value[key] !== advancedSearchSettings[key].defaultValue;
                                    }

                                    if (Array.isArray($advancedSearch.value[key])) {
                                        return $advancedSearch.value[key].length;
                                    }

                                    return $advancedSearch.value[key] !== AdvancedSearchDefaultValue;
                                }))
                                ?
                                <Button
                                    css={styles.clearFiltersButton}
                                    onClick={() => {
                                        resetAdvancedSearch();
                                        $searchText.set('');
                                        $searchInputText.set('');
                                    }}
                                >
                                    <FilterCircleXmarkIcon />
                                </Button>
                                :
                                <MagnifyingGlassIcon />
                        }
                    />
                    {showAdvancedSearch &&
                        <>
                            <Button onClick={() => $advancedSearchTemp.set({ ...$advancedSearch.value })}>
                                <FilterIcon />
                            </Button>
                            <Modal
                                open={!!$advancedSearchTemp.value}
                                onClose={() => $advancedSearchTemp.set(null)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <div css={styles.advancedSearch}>
                                    <h2>{t('coreComponents.table.advancedSearchTitle')}</h2>
                                    <div className="filters">
                                        {$advancedSearchTemp.value &&
                                            $headCells.map(hc =>
                                                ((hc.label || hc.advancedSearch?.label) && !hc.advancedSearch?.hidden)
                                                && (
                                                    hc.advancedSearch?.type === AdvancedSearchType.Select ?
                                                        <SingleSelect
                                                            key={hc.id}
                                                            $value={$advancedSearchTemp.getPropHook(hc.id)}
                                                            label={hc.advancedSearch?.label || hc.label}
                                                        >
                                                            {hc.advancedSearch.values.map(x =>
                                                                <SelectOption key={`${hc.id}_${x.value}`} value={x.value ?? AdvancedSearchDefaultValue}>{x.label}</SelectOption>)
                                                            }
                                                        </SingleSelect>
                                                        :
                                                        hc.advancedSearch?.type === AdvancedSearchType.MultiSelect ?
                                                            <MultiSelect
                                                                key={hc.id}
                                                                $value={$advancedSearchTemp.getPropHook(hc.id)}
                                                                label={hc.advancedSearch?.label || hc.label}
                                                            >
                                                                {hc.advancedSearch.values.map(x =>
                                                                    <MultiSelectOption
                                                                        key={`${hc.id}_${x.value}`}
                                                                        value={x.value}>
                                                                        {x.label}
                                                                    </MultiSelectOption>)
                                                                }
                                                            </MultiSelect>
                                                            :
                                                            <Input
                                                                key={hc.id}
                                                                label={hc.advancedSearch?.label || hc.label}
                                                                placeholder={t('input.placeholder')}
                                                                $value={$advancedSearchTemp.getPropHook(hc.id)}
                                                            />
                                                )
                                            )
                                        }
                                    </div>
                                    <div className="actions">
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                $advancedSearchTemp.set(null);
                                            }}
                                        >
                                            {t('actions.cancel')}
                                        </Button>
                                        <Button
                                            onClick={(event) => {
                                                event.target.disabled = true;
                                                $advancedSearch.set($advancedSearchTemp.value);
                                                $advancedSearchTemp.set(null);
                                            }}
                                        >
                                            {t('actions.search')}
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </>
                    }
                </>
            }
            {showExport &&
                <>
                    <Button
                        endIcon={$exportSelectionAnchor.value ? <SortDownIcon /> : <SortUpIcon />}
                        onClick={handleOpenExportMenu}
                    >
                        {t('actions.export')}
                    </Button>
                    <Menu
                        sx={{ mt: '32px' }}
                        id="language-appbar"
                        anchorEl={$exportSelectionAnchor.value}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean($exportSelectionAnchor.value)}
                        onClose={handleCloseExportMenu}
                    >
                        {ExportTypesTranslationKeysArr.map(x => (
                            <MenuItem
                                key={x.value}
                                // disabled={x.value !== ExportTypes.CSV}
                                onClick={() => {
                                    onExport(x.value);
                                    handleCloseExportMenu();
                                }}
                            >
                                <Typography textAlign="left">{t(x.translationKey)}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            }
        </div>
    )
}
