/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect } from "react";
import { Button } from "../../../CoreComponents/Button";
import { Checkbox } from "../../../CoreComponents/Checkbox";
import { useTranslation } from "../../../CoreComponents/Translation";
import { useHook } from "../../../CoreComponents/Utils";
import { SiteService } from "../../../Services/SiteService";
import { ActivityTemplateService } from "../../../Services/ActivityTemplateService";
import { AdminTypes } from "../../../Utils/Constants";
import { shortTime } from "../../../Utils/Common";
import { useSelector } from "react-redux";
import { ChevronDownIcon, ChevronUpIcon } from "../../../CoreComponents/CustomIcons";

const activityTemplateTabStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 1200px) {
            width: 900px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 10px 0;
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;

            & > button {
                border-radius: 8px;
                min-width: 136px;
                box-shadow: none;

                &:nth-of-type(2) {
                    margin-right: 20px;
                    margin-left: auto;
                    color: #858585;
                    background: #EEEEEE;
                    border: 2px solid;
                }
            }
        }
    `,
};

export const ActivityTemplateUnitsTab = ({ $selectedActivityTemplate, onCancel }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const $sites = useHook([]);
    const $customers = useHook([]);
    const $selectedTemplate = useHook($selectedActivityTemplate.value);
    const $isSaving = useHook(false);
    const $isLoading = useHook(false);

    useEffect(() => {
        $isLoading.set(true);
        SiteService.getAll()
            .then(sites => {
                $sites.set(sites);

                const customerSites = {};
                for (let site of sites) {
                    if (!customerSites[site.customerId]) {
                        customerSites[site.customerId] = {
                            ...site.customer
                        };
                    }

                    customerSites[site.customerId]
                        .sites
                        .push(site);
                }
                $customers.set(Object.values(customerSites));
                $isLoading.set(false);
            });
    }, []);

    const updateTemplateUnits = (syncChanges) => {
        $isSaving.set(true);
        const activeUnits = [];
        
        for (const key in $selectedTemplate.value.unitActivityTemplates) {
            activeUnits.push($selectedTemplate.value.unitActivityTemplates[key].unitId);
        }

        ActivityTemplateService.updateUnitTemplates(
            $selectedTemplate.value.templateId,
            activeUnits, syncChanges)
            .then(() => $selectedActivityTemplate.set({}))
            .then($isSaving.set(false));
    };

    return(
        <div css={activityTemplateTabStyles.root}>
            <h2>{t('activityTemplates.editModal.title')}</h2>

            <UnitsTab
                $selectedTemplate={$selectedTemplate}
                $customers={$customers}
            />

            <div className="actions">
                <Button disabled={$isSaving.value || isTrazerViewAdmin} onClick={() => updateTemplateUnits(true)}>{t('actions.sync')}</Button>
                <Button onClick={onCancel}>{t('actions.cancel')}</Button>
                <Button disabled={$isSaving.value || isTrazerViewAdmin} onClick={() => updateTemplateUnits(false)}>{t('actions.update')}</Button>
            </div>
        </div>
    )
};

const unitsStyles = {
    root: css`
        height: calc(100% - 130px);
        overflow-y: auto;
        margin-top: 28px;
        position: relative;

        & > .customer-section {
            padding-right: 40px;

            & > h2 {
                color: #858585;
                margin-bottom: 40px
            }

            & .site-section > div {
                background-color: #FFFFFF;
                border-radius: 8px;
                min-height: 50px;
                display: flex;
                align-items: center;
                padding: 0 100px 0 40px;
                justify-content: space-between;
                margin-top: 12px;
            }

            & .site-section > div:first-of-type {
                width: 100%;
                background-color: #00ABA5;
                position: relative;
            }
        }
    `,
    unit: css`
        margin-left: 40px;
    `,
    arrow: css`
        position: absolute;
        right: 24px;
        color: #858585;
    `,
    lastSynced: css`
        color: #858585;
    `
};

const UnitsTab = ({ $customers, $selectedTemplate }) => {
    return (
        <div css={unitsStyles.root}>
            {$customers.value.map(c =>
                <div key={c.customerId} className="customer-section">
                    {c.sites.length > 0 && c.sites.some(s => s.units.length) &&
                        <h2>{c.name}</h2>
                    }
                    {c.sites.map((s) => !!s.units.length &&
                        <SiteRow
                            key={s.siteId}
                            $selectedTemplate={$selectedTemplate}
                            name={s.name}
                            units={s.units}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

const SiteRow = ({ $selectedTemplate, name, units }) => {
    const $expanded = useHook(true);

    return (
        <div className="site-section">
            <div onClick={() => $expanded.set(!$expanded.value)}>
                <Checkbox
                    color="secondary"
                    checked={units.every(x =>
                        $selectedTemplate.value.unitActivityTemplates
                            ?.some(u => u.unitId === x.unitId)
                    )}
                    onChange={isChecked => {
                        const unitAT = $selectedTemplate.value.unitActivityTemplates
                            .filter(x => !units.some(u => u.unitId === x.unitId));
                        let newTemplate = null;
                        if (isChecked) {
                            newTemplate = {
                                ...$selectedTemplate.value,
                                unitActivityTemplates: unitAT.concat(units.map(u =>
                                ({
                                    unitId: u.unitId,
                                    templateId: $selectedTemplate.value.templateId,
                                    unit: u
                                }))),
                            };
                        } else {
                            newTemplate = {
                                ...$selectedTemplate.value,
                                unitActivityTemplates: unitAT
                            }
                        }

                        $selectedTemplate.set(newTemplate);
                        $expanded.set(true);
                    }}
                    label={name}
                />
                {$expanded.value ?
                    <ChevronUpIcon css={unitsStyles.arrow} />
                    :
                    <ChevronDownIcon css={unitsStyles.arrow} />
                }
            </div>
            {$expanded.value && units.map((u, index) =>
                <div key={u.unitId} css={unitsStyles.unit}>
                    <Checkbox
                        checked={$selectedTemplate.value.unitActivityTemplates.some(x => x.unitId === u.unitId)}
                        onChange={isChecked => {
                            let newTemplate = null;
                            if (isChecked) {
                                newTemplate = {
                                    ...$selectedTemplate.value,
                                    unitActivityTemplates: $selectedTemplate.value.unitActivityTemplates
                                        ?.concat([{
                                            unitId: u.unitId,
                                            templateId: $selectedTemplate.value.templateId,
                                            unit: u
                                        }])
                                }
                            } else {
                                newTemplate = {
                                    ...$selectedTemplate.value,
                                    unitActivityTemplates: $selectedTemplate.value.unitActivityTemplates
                                        ?.filter(x => x.unitId !== u.unitId)
                                }
                            }

                            $selectedTemplate.set(newTemplate);
                        }}
                        label={u.skuNumber} />
                    {$selectedTemplate.value.unitActivityTemplates?.find(x => x.unitId === u.unitId) && $selectedTemplate.value.unitActivityTemplates?.find(x => x.unitId === u.unitId).lastSynced &&
                        <span css={unitsStyles.lastSynced}>
                            {shortTime(new Date($selectedTemplate.value.unitActivityTemplates?.find(x => x.unitId === u.unitId).lastSynced))}
                        </span>
                    }
                </div>
            )}
        </div>
    )
}
