// WARN: this is written with double quotes because we can change it faster to a json
const DefaultTranslations = {
    "mainNavigation.overview": "Overview",
    "mainNavigation.manage": "Manage",
    "mainNavigation.library": "Library",
    "mainNavigation.analytics": "Analytics",
    "mainNavigation.dashboard": "Dashboard",
    "mainNavigation.profileSettingsTooltip": "Open Profile",
    "mainNavigation.profileSettingsProfile": "My Profile",
    "mainNavigation.profileSettingsHelp": "Help",
    "mainNavigation.profileSettingsDocumentation": "Documentation",
    "mainNavigation.profileSettingsContactSupport": "Contact Support",
    "mainNavigation.userView": "Change User View",
    "mainNavigation.userView.reset": "Reset User View",
    "mainNavigation.selectViewModal.title": "Select View Role",
    "manage.sideNavigation.categories": "Categories",
    "manage.sideNavigation.tags": "Tags",
    "manage.sideNavigation.pillars": "Pillars",
    "manage.sideNavigation.activityGroups": "Activity Groups",
    "manage.sideNavigation.activityTemplates": "Activity Templates",
    "manage.sideNavigation.baseActivities": "Base Activities",
    "manage.sideNavigation.scriptedActivities": "Scripted Activities",
    "manage.sideNavigation.customers": "Customers",
    "manage.sideNavigation.organization": "Organization",
    "manage.sideNavigation.sites": "Sites",
    "manage.sideNavigation.units": "Units",
    "manage.sideNavigation.userGroups": "User Groups",
    "manage.sideNavigation.users": "Users",
    "manage.sideNavigation.tests": "Tests",
    "manage.dateFilter": "Displaying Results",
    "coreComponents.table.paginationInfo": "{0}-{1} of {2}",
    "coreComponents.table.paginationInfoMenuTitle": "Show up to",
    "coreComponents.table.paginationInfoMenuItemTemplate": "{0} items",
    "coreComponents.table.noMatchingRecords": "No matching records found",
    "coreComponents.table.advancedSearchTitle": "Advanced Search",
    "coreComponents.table.visibleColumns": "Visible Columns",
    "coreComponents.select.noResultsSearchTemplate": "No results matching \"{0}\"",
    "constants.gender.nonBinary": "Non-binary",
    "constants.gender.female": "Female",
    "constants.gender.male": "Male",
    "constants.dominance.unknown": "Unknown",
    "constants.dominance.left": "Left",
    "constants.dominance.right": "Right",
    "constants.dominance.ambidextrous": "Ambidextrous",
    "constants.adminType.Operator": "Operator",
    "constants.adminType.TRAZERAdmin": "TRAZER Admin",
    "constants.adminType.TRAZERView": "TRAZER View",
    "constants.adminType.SuperAdmin": "Super Admin",
    "constants.adminType.SiteAdmin": "Site Admin",
    "constants.adminType.Provider": "Provider",
    "constants.adminType.User": "User",
    "constants.adminType.AuthorizedUser": "Authorized User",
    "constants.side.left": "Left",
    "constants.side.right": "Right",
    "constants.side.none": "NA",
    "constants.testStatuses.empty": "Empty",
    "constants.testStatuses.complete": "Complete",
    "constants.testStatuses.partial": "Partial",
    "constants.testStatuses.deleted": "Deleted",
    "constants.missTypes.leaningOrSway": "Leaning or Sway Detected",
    "constants.missTypes.stumbleOrFall": "Stumble Or Fall Detected",
    "constants.missTypes.leftHandOffHip": "Left Hand Off Hip",
    "constants.missTypes.rightHandOffHip": "Right Hand Off Hip",
    "constants.missTypes.step": "Step Detected",
    "constants.missTypes.leftToeRaised": "Left Toe Raised",
    "constants.missTypes.rightToeRaised": "Right Toe Raised",
    "constants.missTypes.leftHeelRaised": "Left Heel Raised",
    "constants.missTypes.rightHeelRaised": "Right Heel Raised",
    "constants.missTypes.plantToeRaised": "Plant Toe Raised",
    "constants.missTypes.plantHeelRaised": "Plant Heel Raised",
    "constants.missTypes.freeFootDropped": "Free Foot Dropped",
    "constants.missTypes.freeAnkleDropped": "Free Ankle Dropped",
    "constants.missTypes.freeKneeTooHigh": "Free Knee Too High",
    "constants.missTypes.freeKneeTooFar": "Free Knee Too Far Away",
    "constants.unitType.meters": "Meters (m)",
    "constants.unitType.feet": "Feet (ft)",
    "constants.unitType.metersShort": "m",
    "constants.unitType.feetShort": "ft",
    "constants.unitType.inchShort": "in",
    "constants.unitType.centimetersShort": "cm",
    "constants.unitType.poundShort": "lbs",
    "constants.unitType.units": "Units",
    "constants.unitType.calories": "kcal",
    "constants.units.count": "Count",

    "constants.units.seconds": "Seconds",
    "constants.units.secondsShort": "s",
    "constants.units.millisecondsShort": "ms",
    "constants.units.m/s": "m/s",
    "constants.units.ft/s": "ft/s",
    // "constants.units.m/s^2": "m/s<sup>2</sup>",
    // "constants.units.ft/s^2": "ft/s<sup>2</sup>",
    "constants.units.m/s^2": "m/s\u00B2",
    "constants.units.ft/s^2": "ft/s\u00B2",
    "constants.units.beatsPerMinute": "bpm",
    "constants.units.degrees": "\u00B0",
    "constants.units.calories": "Calories",
    "constants.units.caloriesShort": "cals",
    "constants.dateRanges.currentWeek": "Current Week",
    "constants.dateRanges.lastWeek": "Last Week",
    "constants.dateRanges.thisMonth": "This Month",
    "constants.dateRanges.lastMonth": "Last Month",
    "constants.dateRanges.last6Months": "Last 6 Months",
    "constants.dateRanges.thisYear": "This Year",
    "constants.dateRanges.lastYear": "Last Year",
    "constants.dateRanges.customRange": "Custom Range",
    "constants.dateRanges.all": "All",
    "constants.activityTypes.mobility": "Mobility",
    "constants.activityTypes.cognitive": "Cognitive",
    "constants.activityTypes.balance": "Balance",
    "constants.activityTypes.kinematics": "Kinematics",
    "constants.activityTypes.games": "Games",
    "constants.activityTypes.upperExtremity": "Upper Extremity",
    "constants.alertType.info": "Info",
    "constants.alertType.warning": "Warning",
    "constants.alertType.success": "Success",
    "constants.alertType.error": "Error",
    "constants.systemModes.provider": "Provider",
    "constants.systemModes.userLite": "User Lite",
    "constants.userLiteTimes.option1": "Off",
    "constants.userLiteTimes.option2": "15 Minutes",
    "constants.userLiteTimes.option3": "30 Minutes",
    "constants.userLiteTimes.option4": "45 Minutes",
    "constants.userLiteTimes.option5": "1 Hour",
    "input.placeholder": "Type here...",
    "input.placeholder.min": "Min",
    "input.placeholder.max": "Max",
    "input.searchPlaceholder": "Search...",
    "dropdown.emptyOption": "Select",
    "chart.options.viewInFullScreen": "View in Full Screen",
    "chart.options.exitFullScreen": "Exit Full Screen",
    "chart.options.print": "Print",
    "chart.options.downloadPngImage": "Download PNG Image",
    "chart.options.downloadJpgImage": "Download JPEG Image",
    "chart.options.downloadCsv": "Download CSV",
    "chart.options.downloadXls": "Download XLS",
    "chart.normalDistribution.leftAxisClarification": "Count",
    "chart.normalDistribution.rightAxisClarification": "Normal Distribution",
    "chart.normalDistribution.sizeInfo": "Size (n): {0}",
    "chart.normalDistribution.meanOrAverageInfo": "Mean/Average (μ): {0}{1}",
    "chart.normalDistribution.standardDeviationInfo": "Standard Deviation (σ): {0}{1}",
    "chart.normalDistribution.datasetNameHistogram": "Histogram",
    "chart.normalDistribution.datasetNameNormalDistribution": "Normal Distribution",
    "chart.normalDistribution.datasetNameData": "Data",
    "chart.normalDistribution.exportRangeTemplate": "{0} Range",
    "chart.normalDistribution.exportColumnCount": "Count",
    "actions.deactivate": "Deactivate",
    "actions.activate": "Activate",
    "actions.repurpose": "Repurpose",
    "actions.unregister": "Unregister",
    "actions.retry": "Retry",
    "actions.logout": "Log Out",
    "actions.login": "Login",
    "actions.update": "Update",
    "actions.add": "Add",
    "actions.cancel": "Cancel",
    "actions.delete": "Delete",
    "actions.apply": "Apply",
    "actions.edit": "Edit",
    "actions.done": "Done",
    "actions.changePassword": "Change Password",
    "actions.resendVerificationEmail": "Resend Email",
    "actions.export": "Export",
    "actions.exportCsv": "CSV",
    "actions.exportExcel": "MS-Excel",
    "actions.change": "Change",
    "actions.next": "Next",
    "actions.previous": "Previous",
    "actions.chooseFile": "Choose File",
    "actions.submit": "Submit",
    "actions.backToManage": "Back to manage",
    "actions.tryAgain": "Try Again",
    "actions.save": "Save",
    "actions.markAll": "Mark All",
    "actions.selectAll": "Select All",
    "actions.editUsers": "Edit Users",
    "actions.editName": "Edit Name",
    "actions.compareTests": "Tests",
    "actions.compareSteps": "Steps",
    "actions.compare": "Compare",
    "actions.setAsBaseline": "Set as Baseline",

    "actions.learnMore": "Learn More",
    "actions.search": "Search",
    "actions.showMoreFilters": "Show More Filters",
    "actions.showLessFilters": "Show Less Filters",
    "actions.generateReport": "Generate report",
    "actions.exportTestData": "Export Test Data",
    "actions.exportRepData": "Export Rep Data",
    "actions.viewAll": "View All",
    "actions.register": "Register",
    "actions.yes": "Yes",
    "actions.unblock": "Unblock",
    "actions.print": "Print",
    "actions.syncAdmin": "Sync Admin",
    "actions.send": "Send",
    "actions.sync": "Sync",
    "users.fieldName.firstName": "First Name",
    "users.fieldName.lastName": "Last Name",
    "users.fieldName.username": "Username",
    "users.fieldName.email": "Email",
    "users.fieldName.phoneNumber": "Phone Number",
    "users.fieldName.medicalRecordsNumber": "Medical Records Number",
    "users.fieldName.dateOfBirth": "Date of Birth",
    "users.fieldName.height": "Height",
    "users.fieldName.weight": "Weight",
    "users.fieldName.gender": "Gender",
    "users.fieldName.dominance": "Dominance",
    "users.fieldName.pin": "TRAZER PIN",
    "users.fieldName.optionalPin": "TRAZER PIN (optional)",
    "users.fieldName.customer": "Customer",
    "users.fieldName.site": "Site to attach user to",
    "users.fieldName.group": "Group to attach user to",
    "users.fieldName.password": "Password",
    "users.fieldName.newPassword": "New Password",
    "users.fieldName.confirmPassword": "Confirm Password",
    "users.fieldName.adminRole": "Admin Role",
    "users.fieldName.role": "Role",
    "users.fieldName.enableUserLogin": "Enable User Login",
    "users.fieldName.enableUserLogin.onOption": "On",
    "users.fieldName.enableUserLogin.offOption": "Off",
    "users.addOptions.user": "User",
    "users.addOptions.admin": "Admin",
    "users.importOptions.user": "User",
    "users.importOptions.admin": "Admin",
    "register.title": "Register User",
    "register.fieldName.code": "Code",
    "register.message.invalidCode": "Invalid code!",
    "register.message.usernameTaken": "Username is already taken!",
    "register.message.success": "Success!",
    "register.message.successDescription": "You have completed the registration process and can now use TRAZER with your username {0}.",
    "admins.fieldName.customer": "Customer",
    "admins.fieldName.site": "Site",
    "admins.fieldName.password": "Password",
    "admins.fieldName.user": "User",
    "admins.fieldName.adminRole": "Admin Role",
    "customers.fieldName.name": "Name",
    "users.import": "Import",
    "users.import.skip": "Skip",
    "users.importUsers": "Import Users",
    "users.editModal.title": "Edit User",
    "users.editModal.profileInformationTabName": "Profile Information",
    "users.editModal.adminSettingsTabName": "Admin Settings",
    "users.editModal.testsTabName": "Tests",
    "users.editModal.groupsTabName": "Groups",
    "users.editModal.connectedSitesTabName": "Connected Sites",
    "users.editModal.sportsTabName": "Sports",
    "users.editModal.injuryHistoryTabName": "Injury History",
    "customers.editModal.editCustomerTabName": "Customer",
    "customers.editModal.sitesTabName": "Sites",
    "sites.editModal.title": "Edit Site",
    "sites.editModal.sitesTab": "Site Information",
    "sites.editModal.siteUnitsTab": "Site Units",
    "site.deleteModal.title": "Delete Site",
    "site.deleteModal.description": "Are you sure you want to delete this site? This process cannot be undone.",
    "userGroups.removeGroupModal.title": "Delete Group",
    "userGroups.removeUserGroupModal.title": "Delete User from a Group",
    "userGroups.removeGroupModal.description": "Are you sure you want to delete this group? This process cannot be undone.",
    "userGroups.removeUserGroupModal.description": "Are you sure you want to remove the user from this group?",
    "userSport.deleteModal.title": "Delete Sport",
    "userSport.deleteModal.description": "Are you sure you want to delete this sport? This process cannot be undone.",
    "userInjury.deleteModal.title": "Delete Injury",

    "userInjury.deleteModal.description": "Are you sure you want to delete this injury? This process cannot be undone.",
    "tests.deleteModal.title": "Delete Test",
    "tests.deleteModal.description": "Are you sure you want to delete this test? This process cannot be undone.",
    "customers.table.createdOn": "Created On",
    "customers.table.name": "Name",
    "customers.table.sites": "Sites",
    "users.addModal.title": "Add User",
    "users.addGroupModal.title": "Add to a group",
    "users.deactivateModal.title": "Deactivate User",
    "users.deactivateModal.description": "Are you sure you want to deactivate this user?",
    "userGroups.deactivateModal.title": "Delete Group",
    "userGroups.deactivateModal.description": "Are you sure you want to delete this group? This process cannot be undone.",
    "admins.addModal.title": "Add Admin",
    "customers.addModal.title": "Add Customer",
    "sites.addModal.title": "Add Site",
    "groups.addModal.title": "Add Group",
    "sports.addModal.title": "Add Sport",
    "injury.addModal.title": "Add Injury",
    "injury.editModal.title": "Edit Injury",
    "changePassword.modal.title": "Change Password",
    "setPin.modal.title": "Create an Admin PIN",
    "setPin.modal.description": "Create a 4-digit PIN for your Admin Profile to access Offline Mode on TRAZER.",
    "users.table.username": "Username",
    "users.table.name": "Name",
    "users.table.medicalRecordNumber": "Medical Record Number",
    "users.table.sites": "Sites",
    "users.table.role": "Role",
    "users.table.createdOn": "Created On",
    "users.table.lastUnitLogin": "Last Login",
    "users.table.firstName": "First Name",
    "users.table.lastName": "Last Name",
    "users.table.email": "Email",
    "users.table.dateOfBirth": "Date of Birth",
    "users.table.gender": "Gender",
    "users.table.height": "Height",
    "users.table.weight": "Weight",
    "users.table.phoneNumber": "Phone Number",
    "users.table.timeAdded": "Time Added",
    "users.table.adminType": "Admin Type",
    "users.tableFilter.isActive": "Active",
    "users.tableFilterValue.all": "All",
    "users.tableFilterValue.activeOnly": "Active Only",
    "users.tableFilterValue.inactiveOnly": "Inactive Only",
    "importUsers.columnTemplate": "Column {0}",
    "importUsers.editEntriesStep.hint": "Select header for each column.",
    "importUsers.editEntriesStep.errorMessage": "One or more users have missing entries. Please upload a new file.",
    "importUsers.attachStep.hint": "Select site to attach users to",
    "importUsers.attachStep.customer": "Customer",
    "importUsers.attachStep.site": "Site",
    "importUsers.attachStep.userGroup": "User Group",
    "importUsers.importFileStep.hint": "Import comma-separated (.csv) or (.txt) file with user information",
    "importUsers.importFileStep.unitFormatsTitle": "Required User Information and Accepted Formats:",
    "importUsers.importFileStep.dragToUpload": "Drag file to upload, or",
    "importUsers.importFileStep.unitFormatsGender": "Gender",
    "importUsers.importFileStep.unitFormatsHeight": "Height",
    "importUsers.importFileStep.unitFormatsFirstName": "First Name",
    "importUsers.importFileStep.unitFormatsLastName": "Last Name",
    "importUsers.importFileStep.unitFormatsDateOfBirth": "Date of Birth",
    "importUsers.importFileStep.unitFormatsUsername": "Username",
    "importUsers.importFileStep.unitFormatsEmail": "Email",
    "importUsers.importFileStep.unitFormatsPhoneNumber": "Phone Number",
    "importUsers.importFileStep.unitFormatsMedicalRecordNumber": "Medical Record Number",
    "importUsers.importFileStep.unitFormatsTitleOptional": "Optional User Information:",
    "importUsers.importFileStep.unitFormatsHeightDescription": "(if no units, assumes units are inches)",
    "importUsers.importFileStep.unitFormatsWeight": "Weight",
    "importUsers.importFileStep.unitFormatsWeightDescription": "(if no units, assumes units are lbs)",
    "importUsers.importFileStep.unitFormatsAdminType": "Admin Type: ",

    "importUsers.importFileStep.unitFormatsAdminTypeDescription": " (if no admin type provided, assumes no admin role)",
    "importUsers.importFileStep.columnHeaderIncludedTitle": "Please indicate if column header row is included",
    "importUsers.importFileStep.columnHeaderIncludedCheckbox": "Column header row included",
    "importUsers.wrongUsers.errorMessage": "We've detected issues with the following users. Probable causes could be either missing or existing username and email.",
    "importUsers.successfullyAdded.successMessage": "Users added successfully!",
    "importUsers.steps.importFile": "Import File",
    "importUsers.steps.editEntries": "Edit Entries",
    "importUsers.steps.attach": "Attach",
    "tests.table.scriptedActivity": "Scripted Activity",
    "tests.table.category": "Category",
    "tests.table.time": "Time",
    "tests.table.name": "Name",
    "tests.table.username": "Username",
    "tests.table.site": "Site",
    "tests.table.status": "Status",
    "groups.table.group": "Group",
    "groups.table.customer": "Customer Name",
    "groups.table.site": "Site Name",
    "groups.fieldName.name": "Name",
    "groups.fieldName.customer": "Customer",
    "groups.fieldName.site": "Site",
    "groups.fieldName.group": "Group",
    "userGroup.deleteModal.title": "Delete User",
    "userGroup.deleteModal.description": "Are you sure you want to delete the user from this group?",
    "sites.fieldName.site": "Site",
    "sites.fieldName.customer": "Customer",
    "sites.fieldName.name": "Site Name",
    "sites.fieldName.contactName": "Contact Name",
    "sites.fieldName.phone": "Phone Number",
    "sites.fieldName.email": "Email",
    "sites.fieldName.address": "Address",
    "sites.fieldName.addressLine2": "Address Line 2",
    "sites.fieldName.city": "City",
    "sites.fieldName.state": "State/Province",
    "sites.fieldName.zipCode": "Zip Code",
    "sites.fieldName.country": "Country",
    "sites.fieldName.pin": "Site PIN",
    "sites.table.name": "Site Name",
    "sites.table.pin": "Site PIN",
    "sites.table.customer.name": "Customer Name",
    "sites.table.contactName": "Contact Name",
    "sites.table.phone": "Phone Number",
    "sites.table.email": "Email",
    "sports.table.sport": "Sport",
    "sports.table.position": "Position",
    "sports.table.level": "Level",
    "sport.fieldName.sport": "Sport",
    "sport.fieldName.position": "Position",
    "sport.fieldName.level": "Level",
    "units.table.serialNumber": "Serial Number",
    "units.table.skuNumber": "SKU Number",
    "units.table.createdOn": "Created On",
    "units.table.lastOnline": "Last Online",
    "units.table.customerName": "Customer",
    "units.table.siteName": "Site",
    "units.table.clientVersion": "Version",
    "units.table.isActive": "Active",
    "units.table.isRegistered": "Registered",
    "units.addModal.title": "Add Unit",
    "units.editModal.title": "Unit Information",
    "units.editModal.activityLibraryTab": "Activity Library",
    "units.editModal.unitSettingsTab": "Unit Settings",
    "units.editModal.activityLibraryTab.title": "Edit Activity Library",
    "units.editModal.activityLibraryTab.assessments": "Assessments",
    "units.editModal.activityLibraryTab.drillIndex": "Drill Index",
    "units.editModal.activityLibraryTab.games": "Games",
    "units.editModal.activityLibraryTab.rehabilitation": "Rehabilitation",
    "units.editModal.activityLibraryTab.sports": "Sports",
    "units.editModal.activityLibraryTab.workouts": "Workouts",
    "units.checkbox.searchByUsername": "Username",
    "units.checkbox.searchByFirstName": "First Name",
    "units.checkbox.searchByLastName": "Last Name",
    "units.switch.requireUserPIN": "Require User PIN",
    "units.switch.requireOrientationActivity": "Require Orientation Activity",
    "units.switch.displayFirstAndLastName": "Display First and Last Name (User Search)",
    "units.fieldName.customer": "Customer",
    "units.fieldName.site": "Site",
    "units.fieldName.skuNumber": "SKU Number",
    "units.fieldName.systemMode": "System Mode",
    "units.fieldName.notes": "Notes",
    "units.fieldName.defaultUnits": "Default Units",
    "units.fieldName.adminLogout": "Admin logout after the following minutes of inactivity:",
    "units.fieldName.userLogout": "User logout after the following minutes of inactivity:",
    "units.fieldName.verifyUser": "Alert for an inactive User after the following minutes:",
    "units.fieldName.userLiteTimeout": "Maximum Authorized User session time:",
    "units.fieldName.searchBy": "Search By (User Search)",
    "units.fieldName.templates": "Template",
    "units.activityTemplatesDropdown.defaultOption": "None",
    "units.tableFilterValue.all": "All",
    "units.tableFilterValue.activeOnly": "Active Only",
    "units.tableFilterValue.inactiveOnly": "Inactive Only",
    "units.validationErrors.adminAutoLogoutTime": "Has to be more than or equal to User Auto Logout Time",
    "units.validationErrors.userAutoLogoutTime": "Has to be less than or equal to Admin Auto Logout Time and has to more than or equal to User Inactive Time",
    "units.validationErrors.userInactiveTime": "Has to be less than or equal to User Auto Logout Time",
    "injury.table.location": "Injury Location",
    "injury.table.type": "Type",
    "injury.table.side": "Side",
    "injury.table.date": "Injury Date",
    "injury.table.status": "Status",
    "injury.table.surgeryDate": "Surgery Date",
    "injury.fieldName.location": "Injury Location",
    "injury.fieldName.type": "Injury Type",
    "injury.fieldName.side": "Injury Side",
    "injury.fieldName.dateOfInjury": "Injury Date",
    "injury.fieldName.status": "Injury Status",
    "injury.fieldName.dateOfSurgery": "Surgery Date",
    "injury.surgeryDate.error": "Date of surgery must be after date of injury",
    "customerReport.controls.dateRange": "Date Range",
    "customerReport.tableTitle.sitesOverview": "Sites Overview",
    "customerReport.tableTitle.mostPopularScriptedActivities": "Most Popular Scripted Activities",
    "customerReport.tableTitle.providerSummary": "Provider Summary",
    "customerReport.sitesOverviewTable.siteName": "Site Name",
    "customerReport.sitesOverviewTable.lastOnline": "Last Online",
    "customerReport.sitesOverviewTable.totalUsers": "New Users",
    "customerReport.sitesOverviewTable.totalSessions": "New Sessions",
    "customerReport.scriptedActivitiesTable.name": "Name",
    "customerReport.scriptedActivitiesTable.sessionsCount": "Sessions",
    "customerReport.providerTable.providerName": "Provider Name",
    "customerReport.providerTable.testsAdministered": "Tests Administered",
    "customerReport.chartExportDataField.date": "Date",
    "customerReport.chartExportDataField.siteName": "Site name",
    "customerReport.chartData.providers": "Providers",
    "customerReport.chartData.sessions": "Sessions",
    "customerReport.chartData.users": "Users",
    "customerReport.chartTitle.sessionsWeeklyTrend": "Sessions Weekly Trend",
    "customerReport.chartTitle.newUsersWeeklyTrend": "New Users Weekly Trend",
    "customerReport.chartTitle.usersAndSessionsBySite": "Users and Sessions by Site",
    "customerReport.chartTitle.providerSummary": "Provider Summary",
    "customerReport.sessionsWeeklyTrend.xAxis": "First Day of the week",
    "customerReport.newUsersWeeklyTrend.xAxis": "First Day of the week",
    "customerReport.usersAndSessionsBySite.xAxis": "Count",
    "customerReport.providerSummary.yAxis": "Providers",
    "customerReport.sessionsWeeklyTrend.yAxis": "Sessions",
    "customerReport.newUsersWeeklyTrend.yAxis": "Users",
    "customerReport.usersAndSessionsBySite.yAxis": "Sites",
    "customerReport.providerSummary.xAxis": "Sessions",
    "siteReport.sessionsWeeklyTrend.xAxis": "First Day of the week",
    "siteReport.newUsersWeeklyTrend.xAxis": "First Day of the week",
    "siteReport.sessionsWeeklyTrend.yAxis": "Sessions",
    "siteReport.newUsersWeeklyTrend.yAxis": "Users",
    "siteReport.overviewTitle.siteOverview": "Site Overview",
    "tags.table.name": "Name",
    "tags.fieldName.name": "Tag Name",
    "tags.addModal.title": "Add Tag",
    "tags.editModal.title": "Edit Tag",
    "pillars.table.name": "Name",
    "pillars.fieldName.name": "Pillar Name",
    "pillars.addModal.title": "Add Pillar",
    "pillars.editModal.title": "Edit Pillar",
    "activityGroups.table.name": "Name",
    "activityGroups.fieldName.name": "Group Name",
    "activityGroups.addModal.title": "Add Group",
    "activityGroups.editModal.title": "Edit Group",
    "activityTemplates.table.name": "Name",
    "activityTemplates.fieldName.name": "Template Name",
    "activityTemplates.addModal.title": "Add Template",
    "activityTemplates.editModal.title": "Edit Template",
    "activityTemplates.editModal.activityTemplateLibraryTab": "Template Library",
    "activityTemplates.editModal.activityTemplateUnitsTab": "Activity Template Units",
    "activityTemplates.editModal.activityTemplateLibraryTab.title": "Edit Activity Library",
    "categories.table.name": "Name",
    "categories.fieldName.name": "Category Name",
    "categories.addModal.title": "Add Category",
    "categories.editModal.title": "Edit Category",
    "scriptedActivities.table.name": "Name",
    "scriptedActivities.table.alias": "Alias",
    "scriptedActivities.table.category": "Category",
    "scriptedActivities.table.tags": "Tags",
    "scriptedActivities.table.pillars": "Pillars",
    "scriptedActivities.table.groups": "Groups",
    "scriptedActivities.table.templates": "Templates",

    "unitScriptedActivities.table.name": "Name",
    "unitScriptedActivities.table.isDeprecated": "Deprecated",
    "unitScriptedActivities.table.visible": "Visible",
    "pillarScriptedActivities.table.name": "Name",
    "pillarScriptedActivities.table.isDeprecated": "Deprecated",
    "groupScriptedActivities.table.name": "Name",
    "groupScriptedActivities.table.isDeprecated": "Deprecated",
    "templateScriptedActivities.table.name": "Name",
    "templateScriptedActivities.table.isDeprecated": "Deprecated",
    "templateScriptedActivities.table.visible": "Visible",
    "scriptedActivities.edit.title": "Edit Scripted Activity",
    "scriptedActivities.edit.information": "Information",
    "scriptedActivities.edit.units": "Units",
    "scriptedActivities.edit.steps": "Steps",
    "scriptedActivity.addModal.title": "Add Scripted Activity",
    "scriptedActivity.fieldName.name": "Name",
    "scriptedActivity.fieldName.alias": "Alias",
    "scriptedActivity.fieldName.category": "Category",
    "scriptedActivity.fieldName.tags": "Tags",
    "scriptedActivity.fieldName.pillars": "Pillars",
    "scriptedActivity.fieldName.groups": "Groups",
    "scriptedActivity.fieldName.templates": "Templates",
    "scriptedActivity.fieldName.summary": "Summary",
    "scriptedActivitiesSteps.table.order": "Order",
    "scriptedActivitiesSteps.table.name": "Name",
    "scriptedActivitiesSteps.table.baseActivity": "Base Activity",
    "activitySteps.addModal.stepInformation": "Step Information",
    "activitySteps.addModal.settings": "Settings",
    "activitySteps.addModal.title": "Add Step",
    "activitySteps.fieldName.name": "Name",
    "activitySteps.fieldName.order": "Order",
    "scriptedActivity.fieldError.existingOrderNumber": "Order number is already taken",
    "activitySteps.fieldName.isSkipped": "Skip",
    "activitySteps.fieldName.activity": "Activity",
    "activitySteps.fieldName.instructions": "Instructions",
    "activitySteps.editModal.stepInformation": "Step Information",
    "activitySteps.editModal.settings": "Settings",
    "activitySteps.editModal.title": "Edit Step",
    "scriptedActivityStep.deleteModal.title": "Delete Step",
    "scriptedActivityStep.deleteModal.description": "Are you sure you want to delete this step? This process cannot be undone.",
    "userReport.section.userInformation": "User Overview",
    "userReport.userInfo.name": "Name",
    "userReport.userInfo.totalTime": "Total Time on TRAZER",
    "userReport.userInfo.totalDistance": "Total Distance Traveled",
    "userReport.userInfo.totalCalories": "Total Calories Burned",
    "userReport.userInfo.totalTargets": "Total Targets Hit",
    "userReport.controls.dateRange": "Date Range:",
    "userReport.controls.unitType": "Units:",
    "userReport.chartExportDataField.date": "Date",
    "userReport.chartData.distanceFeet": "Distance Traveled (ft)",
    "userReport.chartData.distanceMeters": "Distance Traveled (m)",
    "userReport.chartData.sessions": "Sessions",
    "userReport.chartData.calories": "Calories Burned",
    "userReport.chartData.targets": "Targets Hit",
    "userReport.chartTitle.distance": "Distance Traveled Weekly Trend",
    "userReport.chartTitle.sessions": "Sessions Weekly Trend",
    "userReport.chartTitle.calories": "Calories Burned Weekly Trend",
    "userReport.chartTitle.targets": "Targets Hit Weekly Trend",
    "userReport.distance.xAxis": "First Day of the week",
    "userReport.sessions.xAxis": "First Day of the week",
    "userReport.calories.xAxis": "First Day of the week",
    "userReport.targets.xAxis": "First Day of the week",
    "userReport.distance.yAxis": "Distance ({0})",
    "userReport.sessions.yAxis": "Sessions",
    "userReport.calories.yAxis": "Calories",
    "userReport.targets.yAxis": "Targets",
    "userReport.section.weekly": "Weekly",
    "userReport.section.history": "History",
    "userReport.section.stats": "Stats",
    "baseActivities.addModal.title": "Add Base Activity",
    "baseActivities.editModal.title": "Edit Base Activity",
    "baseActivities.fieldName.name": "Name",
    "baseActivities.fieldName.type": "Type",
    "userGroups.table.group": "Group",
    "userGroups.table.customer": "Customer",
    "userGroups.table.site": "Site",
    "userGroups.editModal.title": "Change Name",
    "userGroups.fieldName.newName": "New Name",
    "testReport.stepNamePattern": "Step #{0}",
    "testReport.testNamePattern": "Test #{0}",
    "testReport.testNameBaseline": "Baseline",
    "testReport.testNameDifference": "Difference",
    "testReport.kinematicRepetitionNamePattern": "Rep #{0}",
    "testReport.mobilityRepetitionNamePattern": "Rep #{0}",

    "testReport.cognitiveType": "Cognitive",
    "testReport.testComparison.testDate": "{0} Date",
    "testReport.stepComparison.stepName": "Step #{0} Name",
    "testReport.testInfo.testNumber": "Test Number",
    "testReport.testInfo.username": "Username",
    "testReport.testInfo.name": "Name",
    "testReport.testInfo.medicalRecordNumber": "Medical record Number",
    "testReport.testInfo.provider": "Provider",
    "testReport.testInfo.date": "Date",
    "testReport.testInfo.duration": "Duration",
    "testReport.testInfo.location": "Location",
    "testReport.testInfo.status": "Status",
    "testReport.testInfo.scriptedActivity": "Scripted Activity",
    "testReport.testNotes.createdBy": "Created By",
    "testReport.testNotes.modifiedBy": "Last Update By",
    "testReport.testNotes.dateModified": "Last Update On",
    "testReport.compareTests.title": "Compare Tests",
    "testReport.compareTestsTable.baseline": "Baseline",
    "testReport.compareTestsTable.date": "Date",
    "testReport.compareTestsTable.status": "Status",
    "testReport.compareSteps.title": "Compare Steps",
    "testReport.compareStepsTable.name": "Step Name",
    "testReport.compareStepsTable.order": "Order",
    "testReport.actions.previousTest": "Previous test",
    "testReport.actions.nextTest": "Next test",
    "testReport.controls.timelineInterval": "Interval",
    "testReport.controls.step": "Step",
    "testReport.controls.unitType": "Units",
    "testReport.section.stepSummary": "Step Summary",
    "testReport.section.averages": "Averages",
    "testReport.section.sway": "Sway",
    "testReport.section.misses": "Misses",
    "testReport.section.details": "Details",
    "testReport.section.timeline": "Timeline",
    "testReport.section.history": "History",
    "testReport.section.testComparison": "Test Comparison",
    "testReport.section.stepComparison": "Step Comparison",
    "testReport.section.testInformation": "Test Information",
    "testReport.section.testNotes": "Test Notes",
    "testReport.section.kinematicAverages": "Averages",
    "testReport.section.kinematicReps": "Reps",
    "testReport.section.mobilityReps": "Reps",
    "testReport.section.upperExtremityAverages": "Averages",
    "testReport.section.neurocognitive": "Cognitive",
    "testReport.section.totalDistance": "Distance",
    "testReport.section.decelerationIndex": "Deceleration Index",
    "testReport.section.reactionTime": "Reaction Time",
    "testReport.section.dynamicReactionTime": "Dynamic Reaction Time",
    "testReport.section.speed": "Speed",
    "testReport.section.acceleration": "Acceleration",
    "testReport.section.deceleration": "Deceleration",
    "testReport.section.heartRate": "Heart Rate",
    "testReport.section.totalTargets": "Total Targets Hit",
    "testReport.section.neckRotation": "Neck Rotation",
    "testReport.section.neckFlexion": "Neck Flexion",
    "testReport.section.neckLatFlexion": "Neck Lateral Flexion",
    "testReport.section.spineRotation": "Spine Rotation",
    "testReport.section.spineFlexion": "Spine Flexion",
    "testReport.section.lrShoulderRotation": "Shoulder Rotation",
    "testReport.section.shoulderFlexion": "Shoulder Flexion",
    "testReport.section.shoulderAbduction": "Shoulder Abduction",
    "testReport.section.shoulderHorizontalAbduction": "Shoulder Horizontal Abduction",
    "testReport.section.elbowFlexion": "Elbow Flexion",
    "testReport.section.torso": "Torso",
    "testReport.section.pelvicRotation": "Pelvic Rotation",
    "testReport.section.squatDepth": "Squat Depth",
    "testReport.section.jumpHeight": "Jump Height",
    "testReport.section.kneeFlexion": "Knee Flexion",
    "testReport.section.kneeFlexionValgusOrVarus": "Knee Valgus/Varus",
    "testReport.section.dorsiflexion": "Dorsiflexion",
    "testReport.section.shoulderRotation": "Mid-Thoracic Rotation",
    "testReport.section.thoracicRotation": "Lower Thoracic Rotation",
    "testReport.section.lumbarRotation": "Lumbar Rotation",
    "testReport.section.hipRotation": "Hip Rotation",
    "testReport.section.timeline.xAxis": "Elapsed Time (s)",
    "testReport.section.history.xAxis": "Date",
    "testReport.section.timeline.reactionTime.yAxis": "Reaction Time (s)",
    "testReport.section.timeline.dynamicReactionTime.yAxis": "Dynamic Reaction Time (s)",
    "testReport.section.timeline.speed.yAxis": "Speed ({0})",
    "testReport.section.timeline.acceleration.yAxis": "Acceleration ({0})",
    "testReport.section.timeline.deceleration.yAxis": "Deceleration ({0})",
    "testReport.section.timeline.heartRate.yAxis": "Heart Rate",
    "testReport.section.history.totalTargets.yAxis": "Total Targets Hit",
    "testReport.section.history.sway.yAxis": "Total Sway ({0})",
    "testReport.section.history.misses.yAxis": "Misses",
    "testReport.section.history.totalDistance.yAxis": "Total Distance ({0})",
    "testReport.section.history.reactionTime.yAxis": "Reaction Time (s)",
    "testReport.section.history.dynamicReactionTime.yAxis": "Dynamic Reaction Time (s)",
    "testReport.section.history.speed.yAxis": "Speed ({0})",
    "testReport.section.history.acceleration.yAxis": "Acceleration ({0})",
    "testReport.section.history.deceleration.yAxis": "Deceleration ({0})",

    "testReport.section.history.heartRate.yAxis": "Heart Rate (bpm)",
    "testReport.section.history.neck.yAxis": "Neck (Degrees \u00B0)",
    "testReport.section.history.spine.yAxis": "Spine (Degrees \u00B0)",
    "testReport.section.history.lrShoulderRotation.yAxis": "Shoulder Rotation (Degrees \u00B0)",
    "testReport.section.history.shoulderFlexion.yAxis": "Shoulder Flexion (Degrees \u00B0)",
    "testReport.section.history.shoulderAbduction.yAxis": "Shoulder Abduction (Degrees \u00B0)",
    "testReport.section.history.shoulderHorizontalAbduction.yAxis": "Shoulder Horizontal Abduction (Degrees \u00B0)",
    "testReport.section.history.elbowFlexion.yAxis": "Elbow Flexion (Degrees \u00B0)",
    "testReport.section.history.torso.yAxis": "Torso (Degrees \u00B0)",
    "testReport.section.history.pelvicRotation.yAxis": "Pelvic Rotation (Degrees \u00B0)",
    "testReport.section.history.squatDepth.yAxis": "Squat Depth ({0})",
    "testReport.section.history.jumpHeight.yAxis": "Jump Height ({0})",
    "testReport.section.history.kneeFlexion.yAxis": "Knee Flexion (Degrees \u00B0)",
    "testReport.section.history.kneeFlexionValgusOrVarus.yAxis": "Knee Valgus/Varus (Degrees \u00B0)",
    "testReport.section.history.dorsiflexion.yAxis": "Dorsiflexion (Degrees \u00B0)",
    "testReport.section.history.shoulderRotation.yAxis": "Mid-Thoracic Rotation (Degrees \u00B0)",
    "testReport.section.history.thoracicRotation.yAxis": "Lower Thoracic Rotation (Degrees \u00B0)",
    "testReport.section.history.lumbarRotation.yAxis": "Lumbar Rotation (Degrees \u00B0)",
    "testReport.section.history.hipRotation.yAxis": "Hip Rotation (Degrees \u00B0)",
    "testReport.fieldName.start": "Start",
    "testReport.fieldName.min": "Min",
    "testReport.fieldName.average": "Average",
    "testReport.fieldName.max": "Max",
    "testReport.fieldName.end": "End",
    "testReport.fieldName.forward": "Forward",
    "testReport.fieldName.forwardRight": "Forward Right",
    "testReport.fieldName.right": "Right",
    "testReport.fieldName.backwardRight": "Backward Right",
    "testReport.fieldName.backward": "Backward",
    "testReport.fieldName.backwardLeft": "Backward Left",
    "testReport.fieldName.left": "Left",
    "testReport.fieldName.forwardLeft": "Forward Left",
    "testReport.fieldName.lrDifference": "L/R Difference",
    "testReport.fieldName.flFRDifference": "FL/FR Difference",
    "testReport.fieldName.blBRDifference": "BL/BR Difference",
    "testReport.fieldName.totalSway": "Total Sway",
    "testReport.fieldName.duration": "Duration",
    "testReport.fieldName.targets": "Targets",
    "testReport.fieldName.score": "Score",
    "testReport.fieldName.totalDistance": "Total Distance",
    "testReport.fieldName.decelerationIndex": "Deceleration Index",
    "testReport.fieldName.calories": "Calories",
    "testReport.fieldName.averageHR": "Avg HR",
    "testReport.fieldName.maxHR": "Max HR",
    "testReport.fieldName.averageJumpHeight": "Avg Jump Height",
    "testReport.fieldName.maxJumpHeight": "Max Jump Height",
    "testReport.fieldName.averageSquatDepth": "Avg Squat Depth",
    "testReport.fieldName.maxSquatDepth": "Max Squat Depth",
    "testReport.fieldName.correct": "Correct",
    "testReport.fieldName.incorrect": "Incorrect",
    "testReport.fieldName.total": "Total",
    "testReport.fieldName.stanceWidthRatio": "Stance Width Ratio",
    "testReport.fieldName.plantFoot": "Plant Foot",
    "testReport.fieldName.stanceWidthDistance": "Stance Width Distance",
    "testReport.fieldName.rotation": "Shoulder Rotation",
    "testReport.fieldName.abduction": "Shoulder Abduction",
    "testReport.fieldName.horizontalAbduction": "Shoulder Horizontal Abduction",
    "testReport.fieldName.shoulderFlexion": "Shoulder Flexion",
    "testReport.fieldName.elbowFlexion": "Elbow Flexion",
    "testReport.fieldName.flexion": "Flexion",
    "testReport.fieldName.dorsiflexion": "Dorsiflexion",
    "testReport.fieldName.trunkLean": "Trunk Lean",
    "testReport.fieldName.pelvicTilt": "Pelvic Drop",
    "testReport.fieldName.pelvicRotation": "Pelvic Rotation",
    "testReport.fieldName.jumpHeight": "Jump Height",
    "testReport.fieldName.squatDepth": "Squat Depth",
    "testReport.fieldName.rightKneeValgusOrVarus": "Right Knee Valgus/Varus",
    "testReport.fieldName.rightKneeFlexion": "Right Knee Flexion",
    "testReport.fieldName.rightAnkleDorsiflexion": "Right Ankle Dorsiflexion",
    "testReport.fieldName.rightDorsiflexion": "Right Dorsiflexion",
    "testReport.fieldName.leftKneeValgusOrVarus": "Left Knee Valgus/Varus",
    "testReport.fieldName.leftKneeFlexion": "Left Knee Flexion",
    "testReport.fieldName.leftAnkleDorsiflexion": "Left Ankle Dorsiflexion",
    "testReport.fieldName.leftDorsiflexion": "Left Dorsiflexion",
    "testReport.fieldName.neckRotation": "Neck Rotation",
    "testReport.fieldName.neckFlexion": "Neck Flexion",
    "testReport.fieldName.neckLatFlexion": "Neck Lateral Flexion",
    "testReport.fieldName.spineFlexion": "Spine Flexion",
    "testReport.fieldName.spineLatFlexion": "Spine Lateral Flexion",
    "testReport.fieldName.shoulderRotation": "Mid-Thoracic Rotation",
    "testReport.fieldName.thoracicRotation": "Lower Thoracic Rotation",
    "testReport.fieldName.lumbarRotation": "Lumbar Rotation",
    "testReport.fieldName.leftHipRotation": "Left Hip Rotation",
    "testReport.fieldName.rightHipRotation": "Right Hip Rotation",
    "testReport.fieldName.rotationDirection": "Rotation Direction",

    "testReport.fieldName.spineRotation": "Spine Rotation",
    "testReport.fieldName.leftShoulderRotation": "Left Shoulder Rotation",
    "testReport.fieldName.rightShoulderRotation": "Right Shoulder Rotation",
    "testReport.fieldName.leftShoulderFlexion": "Left Shoulder Flexion",
    "testReport.fieldName.rightShoulderFlexion": "Right Shoulder Flexion",
    "testReport.fieldName.leftShoulderAbduction": "Left Shoulder Abduction",
    "testReport.fieldName.rightShoulderAbduction": "Right Shoulder Abduction",
    "testReport.fieldName.leftShoulderHorizontalAbduction": "Left Shoulder Horizontal Abduction",
    "testReport.fieldName.rightShoulderHorizontalAbduction": "Right Shoulder Horizontal Abduction",
    "testReport.fieldName.leftElbowFlexion": "Left Elbow Flexion",
    "testReport.fieldName.rightElbowFlexion": "Right Elbow Flexion",
    "testReport.fieldName.totalTargetsHit": "Total Targets Hit",
    "testReport.fieldName.targetsMissed": "Total Targets Missed",
    "testReport.fieldName.targetsHitLeft180": "Overhead Left Targets 180\u00B0",
    "testReport.fieldName.targetsHitLeft45": "Lateral Left Targets 45\u00B0",
    "testReport.fieldName.targetsHitLeft90": "Lateral Left Targets 90\u00B0",
    "testReport.fieldName.targetsHitLeft135": "Lateral Left Targets 135\u00B0",
    "testReport.fieldName.targetsHitForwardLeft45": "Forward Left Targets 45\u00B0",
    "testReport.fieldName.targetsHitForwardLeft90": "Forward Left Targets 90\u00B0",
    "testReport.fieldName.targetsHitForwardLeft135": "Forward Left Targets 135\u00B0",
    "testReport.fieldName.targetsHitRight180": "Overhead Right Targets 180\u00B0",
    "testReport.fieldName.targetsHitRight45": "Lateral Right Targets 45\u00B0",
    "testReport.fieldName.targetsHitRight90": "Lateral Right Targets 90\u00B0",
    "testReport.fieldName.targetsHitRight135": "Lateral Right Targets 135\u00B0",
    "testReport.fieldName.targetsHitForwardRight45": "Forward Right Targets 45\u00B0",
    "testReport.fieldName.targetsHitForwardRight90": "Forward Right Targets 90\u00B0",
    "testReport.fieldName.targetsHitForwardRight135": "Forward Right Targets 135\u00B0",
    "testReport.fieldName.lrTargetsHitDiff": "L/R Targets Hit Diff.",
    "testReport.fieldName.reactionTimeAvg": "Average Reaction Time",
    "testReport.fieldName.reactionTimeLeft": "Left Reaction Time",
    "testReport.fieldName.reactionTimeRight": "Right Reaction Time",
    "testReport.fieldName.lrReactionTimeDiff": "L/R Reaction Time Diff.",
    "testReport.fieldName.dynamicReactionTimeAvg": "Average Dynamic Reaction Time",
    "testReport.fieldName.dynamicReactionTimeLeft": "Left Dynamic Reaction Time",
    "testReport.fieldName.dynamicReactionTimeRight": "Right Dynamic Reaction Time",
    "testReport.fieldName.lrDynamicReactionTimeDiff": "L/R Dynamic Reaction Time Diff.",
    "testReport.fieldName.leftCongruent": "Left Congruent",
    "testReport.fieldName.rightCongruent": "Right Congruent",
    "testReport.fieldName.leftIncongruent": "Left Incongruent",
    "testReport.fieldName.rightIncongruent": "Right Incongruent",
    "testReport.fieldName.totalTargets": "Total Targets Hit",
    "testReport.fieldName.misses": "Misses",
    "testReport.fieldName.averageReactionTime": "Avg RT",
    "testReport.fieldName.avgReactionTime": "Avg Reaction Time",
    "testReport.fieldName.averageDynamicReactionTime": "Avg Dynamic RT",
    "testReport.fieldName.avgDynamicReactionTime": "Avg Dynamic Reaction Time",
    "testReport.fieldName.averageAcceleration": "Avg Acceleration",
    "testReport.fieldName.averageDeceleration": "Avg Deceleration",
    "testReport.fieldName.averageSpeed": "Avg Speed",
    "testReport.fieldName.averageHeartRate": "Avg HR",
    "testReport.fieldName.totalDistanceAvg": "Total Distance",
    "testReport.fieldName.maxAcceleration": "Max Acceleration",
    "testReport.fieldName.maxDeceleration": "Max Deceleration",
    "testReport.fieldName.maxSpeed": "Max Speed",
    "testReport.fieldName.minReactionTime": "Min Reaction Time",
    "testReport.fieldName.minDynamicReactionTime": "Min Dynamic Reaction Time",
    "testReport.fieldName.minSquatDepth": "Min Squat Depth",
    "testReport.fieldName.minDistance": "Min Distance",
    "testReport.fieldName.startingHR": "Start HR",
    "testReport.averages.reactionTime": "Reaction Time",
    "testReport.averages.dynamicReactionTime": "Dynamic Reaction Time",
    "testReport.averages.speed": "Speed",
    "testReport.averages.acceleration": "Acceleration",
    "testReport.averages.deceleration": "Deceleration",
    "testReport.neurocognitive.promptTime": "Prompt Time",
    "testReport.neurocognitive.scores": "Response Accuracy",
    "testReport.neurocognitive.averageTimeToTarget": "Response Time (s)",
    "testReport.neurocognitive.averageReactionTime": "Reaction Time (s)",
    "testReport.neurocognitive.averageDynamicReactionTime": "Dynamic Reaction Time (s)",
    "testReport.kinematic.left": "Left",
    "testReport.kinematic.right": "Right",
    "testReport.print.stepDisclaimer": "*The reaction times in this activity are intended for comparison purposes only.",
    "testReport.print.selectOptions": "Select Sections to Print",

    "testReport.print.testInfo": "Test Information",
    "testReport.print.testSummary": "Test Summary",
    "testReport.print.testDetails": "Test Details",
    "testReport.print.testTimeline": "Timeline",
    "testReport.print.testHistory": "History",
    "testReport.print.testNotes": "Test Notes",
    "testReport.print.errorMessage": "Please select at least one option!",
    "testReport.email.selectOptions": "Select Who to Send the Report to",
    "testReport.email.providerOption": "Send to the current admin signed in",
    "testReport.email.userOption": "Send to the user who performed the activity",
    "testReport.chartTooltip.test": "Test",
    "testReport.initialPrefix.jumpActivity": "Pre ",
    "testReport.initialPrefix.flexionExtensionActivity": "Flexion ",
    "testReport.initialPrefix.abductionAdductionActivity": "Abduction ",
    "testReport.initialPrefix.leftRightActivity": "L ",
    "testReport.initialPrefix.internalExternalActivity": "External ",
    "testReport.postPrefix.jumpActivity": "Post ",
    "testReport.postPrefix.flexionExtensionActivity": "Extension ",
    "testReport.postPrefix.abductionAdductionActivity": "Adduction ",
    "testReport.postPrefix.leftRightActivity": "R ",
    "testReport.postPrefix.internalExternalActivity": "Internal ",
    "library.noMatchingActivities": "No matching activities found",
    "analytics.filters.customer": "Customer",
    "analytics.filters.site": "Site",
    "analytics.filters.scriptedActivity": "Scripted Activity",
    "analytics.filters.dateRange": "Date Range",
    "analytics.filters.gender": "Gender",
    "analytics.filters.rangeTemplate": "to",
    "analytics.filters.heightInches": "Height (inches)",
    "analytics.filters.weightLbs": "Weight (lbs)",
    "analytics.filters.ageYears": "Age (years)",
    "analytics.filters.userGroup": "User Group",
    "analytics.filters.sport": "Sport",
    "analytics.filters.position": "Position",
    "analytics.filters.injury": "Injury Location",
    "analytics.filters.injuryType": "Injury Type",
    "analytics.filters.dominance": "Dominance",
    "analytics.filters.excludeSectionLabel": "Exclude",
    "analytics.filters.partials": "Partials",
    "analytics.filters.testUsers": "Test Users",
    "analytics.subTabs.graphs": "Graphs",
    "analytics.subTabs.table": "Table",
    "analyticsTestsTab.table.scriptedActivity": "Scripted Activity",
    "analyticsTestsTab.table.name": "Name",
    "analyticsTestsTab.table.username": "Username",
    "analyticsTestsTab.table.date": "Date",
    "analyticsTestsTab.table.status": "Status",
    "analyticsTestsTab.controls.unitType": "Units",
    "analyticsSearchResultLayout.tabs.reactionTime": "Reaction Time",
    "analyticsSearchResultLayout.tabs.dynamicReactionTime": "Dynamic Reaction Time",
    "analyticsSearchResultLayout.tabs.speed": "Speed",
    "analyticsSearchResultLayout.tabs.tests": "Tests",
    "analyticsSearchResultLayout.tabs.acceleration": "Acceleration",
    "analyticsSearchResultLayout.tabs.deceleration": "Deceleration",
    "analyticsSearchResultLayout.tabs.decelerationIndex": "Deceleration Index",
    "analyticsSearchResultLayout.tabs.totalDistance": "Total Distance",
    "analyticsSearchResultLayout.tabs.calories": "Calories",
    "analyticsSearchResultLayout.tabs.cognitive": "Cognitive",
    "analyticsSearchResultLayout.tabs.sways": "Sway",
    "analyticsSearchResultLayout.tabs.misses": "Misses",
    "analyticsSearchResultLayout.tabs.kinematics": "Kinematics",
    "analytics.table.fullName": "Name",
    "analytics.table.username": "Username",
    "analytics.table.scriptedActivity": "Scripted Activity",
    "analytics.table.scriptedActivityStep": "Scripted Activity Step",
    "analytics.table.avg": "Avg",
    "analytics.table.forward": "F",
    "analytics.table.forwardRight": "F/R",
    "analytics.table.right": "R",
    "analytics.table.backwardRight": "B/R",
    "analytics.table.backward": "B",
    "analytics.table.backwardLeft": "B/L",
    "analytics.table.left": "L",
    "analytics.table.forwardLeft": "F/L",
    "analytics.table.lrDifference": "L/R Difference",
    "analytics.table.flFRDifference": "FL/FR Difference",
    "analytics.table.blBRDifference": "BL/BR Difference",
    "analytics.table.date": "Date",
    "analytics.table.decelerationIndex": "Deceleration Index",
    "analytics.table.totalDistance": "Total Distance",
    "analytics.table.calories": "Calories",
    "analytics.table.promptTime": "Prompt Time",
    "analytics.table.congruentCorrectLeft": "Congruent Correct Left",
    "analytics.table.averageTimeCongruentCorrectLeft": "Average Time Congruent Correct Left",
    "analytics.table.congruentIncorrectLeft": "Congruent Incorrect Left",
    "analytics.table.averageTimeCongruentIncorrectLeft": "Average Time Congruent Incorrect Left",
    "analytics.table.incongruentCorrectLeft": "Incongruent Correct Left",
    "analytics.table.averageTimeIncongruentCorrectLeft": "Average Time Incongruent Correct Left",
    "analytics.table.incongruentIncorrectLeft": "Incongruent Incorrect Left",
    "analytics.table.averageTimeIncongruentIncorrectLeft": "Average Time Incongruent Incorrect Left",

    "analytics.table.congruentCorrectRight": "Congruent Correct Right",
    "analytics.table.averageTimeCongruentCorrectRight": "Average Time Congruent Correct Right",
    "analytics.table.congruentIncorrectRight": "Congruent Incorrect Right",
    "analytics.table.averageTimeCongruentIncorrectRight": "Average Time Congruent Incorrect Right",
    "analytics.table.incongruentCorrectRight": "Incongruent Correct Right",
    "analytics.table.averageTimeIncongruentCorrectRight": "Average Time Incongruent Correct Right",
    "analytics.table.incongruentIncorrectRight": "Incongruent Incorrect Right",
    "analytics.table.averageTimeIncongruentIncorrectRight": "Average Time Incongruent Incorrect Right",
    "analytics.table.totalSway": "Total Sway",
    "analytics.table.totalMisses": "Total Misses",
    "analytics.table.plantFoot": "Plant Foot/Rotation Direction",
    "analytics.table.stanceWidthRatio": "Stance Width Ratio",
    "analytics.table.stanceWidthDistance": "Stance Width Distance",
    "analytics.table.trunkLean": "Trunk Lean",
    "analytics.table.pelvicTilt": "Pelvic Drop",
    "analytics.table.pelvicRotation": "Pelvic Rotation",
    "analytics.table.rightKneeValgusVarus": "Right Knee Valgus/Varus",
    "analytics.table.rightKneeFlexion": "Right Knee Flexion",
    "analytics.table.rightAnkleDorsiflexion": "Right Ankle Dorsiflexion",
    "analytics.table.leftKneeValgusVarus": "Left Knee Valgus/Varus",
    "analytics.table.leftKneeFlexion": "Left Knee Flexion",
    "analytics.table.leftAnkleDorsiflexion": "Left Ankle Dorsiflexion",
    "analytics.table.neckRotation": "Neck Rotation",
    "analytics.table.spineFlexion": "Spine Flexion",
    "analytics.table.spineRotation": "Spine Rotation",
    "analytics.table.leftShoulderFlexion": "Left Shoulder Flexion",
    "analytics.table.rightShoulderFlexion": "Right Shoulder Flexion",
    "analytics.table.leftShoulderAbduction": "Left Shoulder Abduction",
    "analytics.table.rightShoulderAbduction": "Right Shoulder Abduction",
    "analytics.table.leftElbowFlexion": "Left Elbow Flexion",
    "analytics.table.rightElbowFlexion": "Right Elbow Flexion",
    "analytics.table.shoulderRotation": "Mid-Thoracic Rotation",
    "analytics.table.thoracicRotation": "Lower Thoracic Rotation",
    "analytics.table.lumbarRotation": "Lumbar Rotation",
    "analytics.table.rightHipRotation": "Right Hip Rotation",
    "analytics.table.leftHipRotation": "Left Hip Rotation",
    "analytics.table.rotationDirection": "Rotation Direction",
    "analytics.export.testStartTime": "Test Date",
    "analytics.export.fullName": "Name",
    "analytics.export.username": "Username",
    "analytics.export.scriptedActivity": "Scripted Activity",
    "analytics.export.testStepDate": "Test Step Date",
    "analytics.export.scriptedActivityStep": "Scripted Activity Step",
    "analytics.export.stepNumber": "Step Number",
    "analytics.export.status": "Status",
    "analytics.export.duration": "Duration",
    "analytics.export.targetsHit": "Targets Hit",
    "analytics.export.reactionTimeAverage": "Reaction Time Average (s)",
    "analytics.export.reactionTimeForward": "Reaction Time Forward (s)",
    "analytics.export.reactionTimeForwardRight": "Reaction Time Forward Right (s)",
    "analytics.export.reactionTimeRight": "Reaction Time Right (s)",
    "analytics.export.reactionTimeBackwardRight": "Reaction Time Backward Right (s)",
    "analytics.export.reactionTimeBackward": "Reaction Time Backward (s)",
    "analytics.export.reactionTimeBackwardLeft": "Reaction Time Backward Left (s)",
    "analytics.export.reactionTimeLeft": "Reaction Time Left (s)",
    "analytics.export.reactionTimeForwardLeft": "Reaction Time Forward Left (s)",
    "analytics.export.reactionTimeLRDifference": "Reaction Time L/R Difference",
    "analytics.export.reactionTimeFLFRDifference": "Reaction Time FL/FR Difference",
    "analytics.export.reactionTimeBLBRDifference": "Reaction Time BL/BR Difference",
    "analytics.export.dynamicReactionTimeAverage": "Dynamic Reaction Time Average (s)",
    "analytics.export.dynamicReactionTimeForward": "Dynamic Reaction Time Forward (s)",
    "analytics.export.dynamicReactionTimeForwardRight": "Dynamic Reaction Time Forward Right (s)",
    "analytics.export.dynamicReactionTimeRight": "Dynamic Reaction Time Right (s)",
    "analytics.export.dynamicReactionTimeBackwardRight": "Dynamic Reaction Time Backward Right (s)",
    "analytics.export.dynamicReactionTimeBackward": "Dynamic Reaction Time Backward (s)",
    "analytics.export.dynamicReactionTimeBackwardLeft": "Dynamic Reaction Time Backward Left (s)",
    "analytics.export.dynamicReactionTimeLeft": "Dynamic Reaction Time Left (s)",
    "analytics.export.dynamicReactionTimeForwardLeft": "Dynamic Reaction Time Forward Left (s)",
    "analytics.export.dynamicReactionTimeLRDifference": "Dynamic Reaction Time L/R Difference",
    "analytics.export.dynamicReactionTimeFLFRDifference": "Dynamic Reaction Time FL/FR Difference",
    "analytics.export.dynamicReactionTimeBLBRDifference": "Dynamic Reaction Time BL/BR Difference",
    "analytics.export.speedAverage": "Speed Average ({0})",
    "analytics.export.speedForward": "Speed Forward ({0})",
    "analytics.export.speedForwardRight": "Speed Forward Right ({0})",
    "analytics.export.speedRight": "Speed Right ({0})",
    "analytics.export.speedBackwardRight": "Speed Backward Right ({0})",
    "analytics.export.speedBackward": "Speed Backward ({0})",
    "analytics.export.speedBackwardLeft": "Speed Backward Left ({0})",
    "analytics.export.speedLeft": "Speed Left ({0})",

    "analytics.export.speedForwardLeft": "Speed Forward Left ({0})",
    "analytics.export.speedLRDifference": "Speed L/R Difference",
    "analytics.export.speedFLFRDifference": "Speed FL/FR Difference",
    "analytics.export.speedBLBRDifference": "Speed BL/BR Difference",
    "analytics.export.accelerationAverage": "Acceleration Average ({0})",
    "analytics.export.accelerationForward": "Acceleration Forward ({0})",
    "analytics.export.accelerationForwardRight": "Acceleration Forward Right ({0})",
    "analytics.export.accelerationRight": "Acceleration Right ({0})",
    "analytics.export.accelerationBackwardRight": "Acceleration Backward Right ({0})",
    "analytics.export.accelerationBackward": "Acceleration Backward ({0})",
    "analytics.export.accelerationBackwardLeft": "Acceleration Backward Left ({0})",
    "analytics.export.accelerationLeft": "Acceleration Left ({0})",
    "analytics.export.accelerationForwardLeft": "Acceleration Forward Left ({0})",
    "analytics.export.accelerationLRDifference": "Acceleration L/R Difference",
    "analytics.export.accelerationFLFRDifference": "Acceleration FL/FR Difference",
    "analytics.export.accelerationBLBRDifference": "Acceleration BL/BR Difference",
    "analytics.export.decelerationAverage": "Deceleration Average ({0})",
    "analytics.export.decelerationForward": "Deceleration Forward ({0})",
    "analytics.export.decelerationForwardRight": "Deceleration Forward Right ({0})",
    "analytics.export.decelerationRight": "Deceleration Right ({0})",
    "analytics.export.decelerationBackwardRight": "Deceleration Backward Right ({0})",
    "analytics.export.decelerationBackward": "Deceleration Backward ({0})",
    "analytics.export.decelerationBackwardLeft": "Deceleration Backward Left ({0})",
    "analytics.export.decelerationLeft": "Deceleration Left ({0})",
    "analytics.export.decelerationForwardLeft": "Deceleration Forward Left ({0})",
    "analytics.export.decelerationLRDifference": "Deceleration L/R Difference",
    "analytics.export.decelerationFLFRDifference": "Deceleration FL/FR Difference",
    "analytics.export.decelerationBLBRDifference": "Deceleration BL/BR Difference",
    "analytics.export.decelerationIndex": "Deceleration Index",
    "analytics.export.totalDistance": "Total Distance ({0})",
    "analytics.export.calories": "Calories",
    "analytics.export.promptTime": "Prompt Time (ms)",
    "analytics.export.congruentCorrectLeft": "Congruent Correct Left",
    "analytics.export.averageTimeCongruentCorrectLeft": "Average Time Congruent Correct Left (s)",
    "analytics.export.congruentIncorrectLeft": "Congruent Incorrect Left",
    "analytics.export.averageTimeCongruentIncorrectLeft": "Average Time Congruent Incorrect Left (s)",
    "analytics.export.incongruentCorrectLeft": "Incongruent Correct Left",
    "analytics.export.averageTimeIncongruentCorrectLeft": "Average Time Incongruent Correct Left (s)",
    "analytics.export.incongruentIncorrectLeft": "Incongruent Incorrect Left",
    "analytics.export.averageTimeIncongruentIncorrectLeft": "Average Time Incongruent Incorrect Left (s)",
    "analytics.export.congruentCorrectRight": "Congruent Correct Right",
    "analytics.export.averageTimeCongruentCorrectRight": "Average Time Congruent Correct Right (s)",
    "analytics.export.congruentIncorrectRight": "Congruent Incorrect Right",
    "analytics.export.averageTimeCongruentIncorrectRight": "Average Time Congruent Incorrect Right (s)",
    "analytics.export.incongruentCorrectRight": "Incongruent Correct Right",
    "analytics.export.averageTimeIncongruentCorrectRight": "Average Time Incongruent Correct Right (s)",
    "analytics.export.incongruentIncorrectRight": "Incongruent Incorrect Right",
    "analytics.export.averageTimeIncongruentIncorrectRight": "Average Time Incongruent Incorrect Right (s)",
    "analytics.export.totalSway": "Total Sway ({0})",
    "analytics.export.swayForward": "Sway Forward ({0})",
    "analytics.export.swayForwardRight": "Sway Forward Right ({0})",

    "analytics.export.swayRight": "Sway Right ({0})",
    "analytics.export.swayBackwardRight": "Sway Backward Right ({0})",
    "analytics.export.swayBackward": "Sway Backward ({0})",
    "analytics.export.swayBackwardLeft": "Sway Backward Left ({0})",
    "analytics.export.swayLeft": "Sway Left ({0})",
    "analytics.export.swayForwardLeft": "Sway Forward Left ({0})",
    "analytics.export.totalMisses": "Total Misses",
    "analytics.export.neckRotation": "Neck Rotation",
    "analytics.export.spineFlexion": "Spine Flexion",
    "analytics.export.spineRotation": "Spine Rotation",
    "analytics.export.leftShoulderFlexion": "Left Shoulder Flexion",
    "analytics.export.rightShoulderFlexion": "Right Shoulder Flexion",
    "analytics.export.leftShoulderAbduction": "Left Shoulder Abduction",
    "analytics.export.rightShoulderAbduction": "Right Shoulder Abduction",
    "analytics.export.leftElbowFlexion": "Left Elbow Flexion",
    "analytics.export.rightElbowFlexion": "Right Elbow Flexion",
    "analytics.export.plantFoot": "Plant Foot/Rotation Direction",
    "analytics.export.stanceWidthRatio": "Stance Width Ratio",
    "analytics.export.stanceWidthDistance": "Stance Width Distance ({0})",
    "analytics.export.trunkLean": "Trunk Lean",
    "analytics.export.pelvicTilt": "Pelvic Tilt",
    "analytics.export.pelvicRotation": "Pelvic Rotation",
    "analytics.export.rightKneeValgusVarus": "Right Knee Valgus/Varus",
    "analytics.export.rightKneeFlexion": "Right Knee Flexion",
    "analytics.export.rightAnkleDorsiflexion": "Right Ankle Dorsiflexion",
    "analytics.export.leftKneeValgusVarus": "Left Knee Valgus/Varus",
    "analytics.export.leftKneeFlexion": "Left Knee Flexion",
    "analytics.export.leftAnkleDorsiflexion": "Left Ankle Dorsiflexion",
    "analytics.export.shoulderRotation": "Mid-Thoracic Rotation",
    "analytics.export.thoracicRotation": "Lower Thoracic Rotation",
    "analytics.export.lumbarRotation": "Lumbar Rotation",
    "analytics.export.rightHipRotation": "Right Hip Rotation",
    "analytics.export.leftHipRotation": "Left Hip Rotation",
    "analytics.export.rotationDirection": "Rotation Direction",
    "analytics.export.repNumber": "Rep Number",
    "analytics.export.reactionTimeAvg": "Reaction Time Average (s)",
    "analytics.export.reactionTimeMin": "Reaction Time Min (s)",
    "analytics.export.dynamicReactionTimeAvg": "Dynamic Reaction Time Average (s)",
    "analytics.export.dynamicReactionTimeMin": "Dynamic Reaction Time Min (s)",
    "analytics.export.speedAvg": "Speed Average ({0})",
    "analytics.export.speedMax": "Speed Max ({0})",
    "analytics.export.accelerationAvg": "Acceleration Average ({0})",
    "analytics.export.accelerationMax": "Acceleration Max ({0})",
    "analytics.export.decelerationAvg": "Deceleration Average ({0})",
    "analytics.export.decelerationMax": "Deceleration Max ({0})",
    "analytics.export.jumpHeightAvg": "Jump Height Average ({0})",
    "analytics.export.jumpHeightMax": "Jump Height Max ({0})",
    "analytics.export.squatHeightAvg": "Squat Depth Average ({0})",
    "analytics.export.squatHeightMin": "Squat Depth Min ({0})",
    "analytics.export.minimumDistance": "Minimum Distance ({0})",
    "analytics.chartData.forward": "Forward",
    "analytics.chartData.forwardRight": "Forward Right",
    "analytics.chartData.right": "Right",
    "analytics.chartData.backwardRight": "Backward Right",
    "analytics.chartData.backward": "Backward",
    "analytics.chartData.backwardLeft": "Backward Left",
    "analytics.chartData.left": "Left",
    "analytics.chartData.forwardLeft": "Forward Left",
    "analytics.chartExportDataField.date": "Date",
    "analytics.chartExportDataField.direction": "Direction",
    "analytics.charts.reactionTimeTrend": "Reaction Time Trend",
    "analytics.charts.reactionTimeDirectionalAverage": "Reaction Time Directional Average",
    "analytics.charts.reactionTimeDistribution": "Reaction Time Distribution",
    "analytics.charts.dynamicReactionTimeTrend": "Dynamic Reaction Time Trend",
    "analytics.charts.dynamicReactionTimeDirectionalAverage": "Dynamic Reaction Time Directional Average",
    "analytics.charts.dynamicReactionTimeDistribution": "Dynamic Reaction Time Distribution",
    "analytics.charts.speedTrend": "Speed Trend",
    "analytics.charts.speedDirectionalAverage": "Speed Directional Average",
    "analytics.charts.speedDistribution": "Speed Distribution",
    "analytics.charts.accelerationTrend": "Acceleration Trend",
    "analytics.charts.accelerationDirectionalAverage": "Acceleration Directional Average",
    "analytics.charts.accelerationDistribution": "Acceleration Distribution",
    "analytics.charts.decelerationTrend": "Deceleration Trend",
    "analytics.charts.decelerationDirectionalAverage": "Deceleration Directional Average",
    "analytics.charts.decelerationDistribution": "Deceleration Distribution",
    "analytics.charts.decelerationIndexTrend": "Deceleration Index Trend",
    "analytics.charts.decelerationIndexDirectionalAverage": "Deceleration Index Directional Average",
    "analytics.charts.decelerationIndexDistribution": "Deceleration Index Distribution",

    "analytics.charts.totalDistanceTrend": "Total Distance Trend",
    "analytics.charts.totalDistanceDistribution": "Total Distance Distribution",
    "analytics.charts.caloriesTrend": "Calories Trend",
    "analytics.charts.caloriesDistribution": "Calories Distribution",
    "analytics.charts.cognitiveTrend": "Cognitive Trend",
    "analytics.charts.cognitiveDistribution": "Cognitive Distribution",
    "analytics.charts.swayTrend": "Sway Trend",
    "analytics.charts.swayDirectionalAverage": "Sway Directional Average",
    "analytics.charts.swayDistribution": "Sway Distribution",
    "analytics.charts.missesTrend": "Misses Trend",
    "analytics.charts.missesDistribution": "Misses Distribution",
    "analytics.charts.kinematicsNeckRotationDistribution": "Neck Rotation Distribution",
    "analytics.charts.kinematicsNeckRotationDistribution.xAxis": "Neck Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsNeckRotationTrend": "Neck Rotation Trend",
    "analytics.charts.kinematicsNeckRotationTrend.yAxis": "Neck Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsSpineFlexionDistribution": "Spine Flexion Distribution",
    "analytics.charts.kinematicsSpineFlexionDistribution.xAxis": "Spine Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsSpineFlexionTrend": "Spine Flexion Trend",
    "analytics.charts.kinematicsSpineFlexionTrend.yAxis": "Spine Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsSpineRotationDistribution": "Spine Rotation Distribution",
    "analytics.charts.kinematicsSpineRotationDistribution.xAxis": "Spine Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsSpineRotationTrend": "Spine Rotation Trend",
    "analytics.charts.kinematicsSpineRotationTrend.yAxis": "Spine Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftShoulderFlexionDistribution": "Left Shoulder Flexion Distribution",
    "analytics.charts.kinematicsLeftShoulderFlexionDistribution.xAxis": "Left Shoulder Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftShoulderFlexionTrend": "Left Shoulder Flexion Trend",
    "analytics.charts.kinematicsLeftShoulderFlexionTrend.yAxis": "Left Shoulder Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsRightShoulderFlexionDistribution": "Right Shoulder Flexion Distribution",
    "analytics.charts.kinematicsRightShoulderFlexionDistribution.xAxis": "Right Shoulder Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsRightShoulderFlexionTrend": "Right Shoulder Flexion Trend",
    "analytics.charts.kinematicsRightShoulderFlexionTrend.yAxis": "Right Shoulder Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftShoulderAbductionDistribution": "Left Shoulder Abduction Distribution",
    "analytics.charts.kinematicsLeftShoulderAbductionDistribution.xAxis": "Left Shoulder Abduction (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftShoulderAbductionTrend": "Left Shoulder Abduction Trend",
    "analytics.charts.kinematicsLeftShoulderAbductionTrend.yAxis": "Left Shoulder Abduction (Degrees \u00B0)",
    "analytics.charts.kinematicsRightShoulderAbductionDistribution": "Right Shoulder Abduction Distribution",
    "analytics.charts.kinematicsRightShoulderAbductionDistribution.xAxis": "Right Shoulder Abduction (Degrees \u00B0)",
    "analytics.charts.kinematicsRightShoulderAbductionTrend": "Right Shoulder Abduction Trend",
    "analytics.charts.kinematicsRightShoulderAbductionTrend.yAxis": "Right Shoulder Abduction (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftElbowFlexionDistribution": "Left Elbow Flexion Distribution",
    "analytics.charts.kinematicsLeftElbowFlexionDistribution.xAxis": "Left Elbow Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftElbowFlexionTrend": "Left Elbow Flexion Trend",
    "analytics.charts.kinematicsLeftElbowFlexionTrend.yAxis": "Left Elbow Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsRightElbowFlexionDistribution": "Right Elbow Flexion Distribution",
    "analytics.charts.kinematicsRightElbowFlexionDistribution.xAxis": "Right Elbow Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsRightElbowFlexionTrend": "Right Elbow Flexion Trend",
    "analytics.charts.kinematicsRightElbowFlexionTrend.yAxis": "Right Elbow Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsTrunkLeanDistribution": "Trunk Lean Distribution",
    "analytics.charts.kinematicsTrunkLeanDistribution.xAxis": "Trunk Lean (Degrees \u00B0)",
    "analytics.charts.kinematicsTrunkLeanTrend": "Trunk Lean Trend",
    "analytics.charts.kinematicsTrunkLeanTrend.yAxis": "Trunk Lean (Degrees \u00B0)",
    "analytics.charts.kinematicsPelvicTiltDistribution": "Pelvic Drop Distribution",
    "analytics.charts.kinematicsPelvicTiltDistribution.xAxis": "Pelvic Drop (Degrees \u00B0)",
    "analytics.charts.kinematicsPelvicTiltTrend": "Pelvic Drop Trend",
    "analytics.charts.kinematicsPelvicTiltTrend.yAxis": "Pelvic Drop (Degrees \u00B0)",
    "analytics.charts.kinematicsPelvicRotationDistribution": "Pelvic Rotation Distribution",
    "analytics.charts.kinematicsPelvicRotationDistribution.xAxis": "Pelvic Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsPelvicRotationTrend": "Pelvic Rotation Trend",
    "analytics.charts.kinematicsPelvicRotationTrend.yAxis": "Pelvic Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftKneeValgusVarusDistribution": "Left Knee Valgus/Varus Distribution",
    "analytics.charts.kinematicsLeftKneeValgusVarusDistribution.xAxis": "Left Knee Valgus/Varus (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftKneeValgusVarusTrend": "Left Knee Valgus/Varus Trend",
    "analytics.charts.kinematicsLeftKneeValgusVarusTrend.yAxis": "Left Knee Valgus/Varus (Degrees \u00B0)",
    "analytics.charts.kinematicsRightKneeValgusVarusDistribution": "Right Knee Valgus/Varus Distribution",
    "analytics.charts.kinematicsRightKneeValgusVarusDistribution.xAxis": "Right Knee Valgus/Varus (Degrees \u00B0)",
    "analytics.charts.kinematicsRightKneeValgusVarusTrend": "Right Knee Valgus/Varus Trend",
    "analytics.charts.kinematicsRightKneeValgusVarusTrend.yAxis": "Right Knee Valgus/Varus (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftKneeFlexionDistribution": "Left Knee Flexion Distribution",
    "analytics.charts.kinematicsLeftKneeFlexionDistribution.xAxis": "Left Knee Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftKneeFlexionTrend": "Left Knee Flexion Trend",
    "analytics.charts.kinematicsLeftKneeFlexionTrend.yAxis": "Left Knee Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsRightKneeFlexionDistribution": "Right Knee Flexion Distribution",
    "analytics.charts.kinematicsRightKneeFlexionDistribution.xAxis": "Right Knee Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsRightKneeFlexionTrend": "Right Knee Flexion Trend",
    "analytics.charts.kinematicsRightKneeFlexionTrend.yAxis": "Right Knee Flexion (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftAnkleDorsiflexionDistribution": "Left Ankle Dorsiflexion Distribution",
    "analytics.charts.kinematicsLeftAnkleDorsiflexionDistribution.xAxis": "Left Ankle Dorsiflexion (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftAnkleDorsiflexionTrend": "Left Ankle Dorsiflexion Trend",
    "analytics.charts.kinematicsLeftAnkleDorsiflexionTrend.yAxis": "Left Ankle Dorsiflexion (Degrees \u00B0)",
    "analytics.charts.kinematicsRightAnkleDorsiflexionDistribution": "Right Ankle Dorsiflexion Distribution",
    "analytics.charts.kinematicsRightAnkleDorsiflexionDistribution.xAxis": "Right Ankle Dorsiflexion (Degrees \u00B0)",
    "analytics.charts.kinematicsRightAnkleDorsiflexionTrend": "Right Ankle Dorsiflexion Trend",
    "analytics.charts.kinematicsRightAnkleDorsiflexionTrend.yAxis": "Right Ankle Dorsiflexion (Degrees \u00B0)",
    "analytics.charts.kinematicsShoulderRotationDistribution": "Mid-Thoracic Rotation Distribution",
    "analytics.charts.kinematicsShoulderRotationDistribution.xAxis": "Mid-Thoracic Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsShoulderRotationTrend": "Mid-Thoracic Rotation Trend",
    "analytics.charts.kinematicsShoulderRotationTrend.yAxis": "Mid-Thoracic Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsThoracicRotationDistribution": "Lower Thoracic Rotation Distribution",
    "analytics.charts.kinematicsThoracicRotationDistribution.xAxis": "Lower Thoracic Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsThoracicRotationTrend": "Lower Thoracic Rotation Trend",
    "analytics.charts.kinematicsThoracicRotationTrend.yAxis": "Lower Thoracic Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsLumbarRotationDistribution": "Lumbar Rotation Distribution",
    "analytics.charts.kinematicsLumbarRotationDistribution.xAxis": "Lumbar Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsLumbarRotationTrend": "Lumbar Rotation Trend",
    "analytics.charts.kinematicsLumbarRotationTrend.yAxis": "Lumbar Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsRightHipRotationDistribution": "Right Hip Rotation Distribution",
    "analytics.charts.kinematicsRightHipRotationDistribution.xAxis": "Right Hip Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsRightHipRotationTrend": "Right Hip Rotation Trend",
    "analytics.charts.kinematicsRightHipRotationTrend.yAxis": "Right Hip Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftHipRotationDistribution": "Left Hip Rotation Distribution",
    "analytics.charts.kinematicsLeftHipRotationDistribution.xAxis": "Left Hip Rotation (Degrees \u00B0)",
    "analytics.charts.kinematicsLeftHipRotationTrend": "Left Hip Rotation Trend",
    "analytics.charts.kinematicsLeftHipRotationTrend.yAxis": "Left Hip Rotation (Degrees \u00B0)",
    "analytics.charts.reactionTimeTrend.yAxis": "Reaction Time Average (s)",
    "analytics.charts.reactionTimeDistribution.xAxis": "Reaction Time Average (s)",
    "analytics.charts.dynamicReactionTimeTrend.yAxis": "Dynamic Reaction Time Average (s)",
    "analytics.charts.dynamicReactionTimeDistribution.xAxis": "Dynamic Reaction Time Average (s)",

    "analytics.charts.speedTrend.yAxis": "Speed Average ({0})",
    "analytics.charts.speedDistribution.xAxis": "Speed Average ({0})",
    "analytics.charts.accelerationTrend.yAxis": "Acceleration Average ({0})",
    "analytics.charts.accelerationDistribution.xAxis": "Acceleration Average ({0})",
    "analytics.charts.decelerationTrend.yAxis": "Deceleration Average ({0})",
    "analytics.charts.decelerationDistribution.xAxis": "Deceleration Average ({0})",
    "analytics.charts.decelerationIndexTrend.yAxis": "Deceleration Index Average",
    "analytics.charts.decelerationIndexDistribution.xAxis": "Deceleration Index Average",
    "analytics.charts.totalDistanceTrend.yAxis": "Distance ({0})",
    "analytics.charts.totalDistanceDistribution.xAxis": "Distance ({0})",
    "analytics.charts.caloriesTrend.yAxis": "Calories",
    "analytics.charts.caloriesDistribution.xAxis": "Calories",
    "analytics.charts.cognitiveTrend.yAxis": "Correct %",
    "analytics.charts.cognitiveDistribution.xAxis": "Correct %",
    "analytics.charts.swayTrend.yAxis": "Sway ({0})",
    "analytics.charts.swayDistribution.xAxis": "Sway ({0})",
    "analytics.charts.missesTrend.yAxis": "Misses",
    "analytics.charts.missesDistribution.xAxis": "Misses",
    "analytics.charts.xAxis": "Date",
    "overview.section.monthlySessionsTrend": "Monthly Sessions Trend",
    "overview.section.sessions": "Sessions",
    "overview.section.monthlyUsersTrend": "Monthly New Users Trend",
    "overview.section.users": "Users",
    "overview.section.units": "Units",
    "overview.section.date": "Month-Year",
    "overview.section.recentlyAddedUsers": "Recently Added Users",
    "overview.section.recentSessions": "Recent Sessions",
    "overview.chartExportDataField.date": "Date",
    "footer.privacyPolicy": "Privacy Policy",
    "footer.protectedBy": "Protected by US Patent Nos: 9,078,598 and 7,864,168. Other Patents Pending.",
    "footer.TrazerInc": "© {0} - Trazer Inc. All Rights Reserved.",
    "privacyPolicy.title": "TRAZER Portal Privacy Policy",
    "privacyPolicy.lastUpdated": "Last Updated: October 2023",
    "privacyPolicy.section1": "Introduction",
    "privacyPolicy.intro": "This policy outlines how Trazer Inc. collects, uses, stores, and protects the data within the TRAZER Portal. We are committed to safeguarding the privacy of our Customers and their Users’ (athletes, clients, patients, residents, and/or any individual in the care of our customers) privacy and ensuring the security of data. By using our services and interacting with our databases, you agree to the practices described in this policy.",
    "privacyPolicy.section2": "Data Collection",
    "privacyPolicy.section2.info": "We collect and store data in our databases in the following ways:",
    "privacyPolicy.section2.subsection1": "Information provided when using the TRAZER system.",
    "privacyPolicy.section2.subsection2": "Data generated or collected automatically during interactions with the TRAZER system.",
    "privacyPolicy.section2.subsection3": "Data obtained from third parties in compliance with applicable laws.",
    "privacyPolicy.section3": "Data Usage",
    "privacyPolicy.section3.info": "We use the data stored in the TRAZER Portal for the following purposes:",
    "privacyPolicy.section3.subsection1": "Providing, maintaining, and improving our services.",
    "privacyPolicy.section3.subsection2": "Personalizing user experiences.",
    "privacyPolicy.section3.subsection3": "Analyzing and optimizing our operations.",
    "privacyPolicy.section3.subsection4": "Complying with legal and regulatory requirements.",
    "privacyPolicy.section3.subsection5": "Safeguarding the rights, safety, and property of our Customers and their Users.",
    "privacyPolicy.section4": "Data Security",
    "privacyPolicy.section4.info": "At Trazer Inc., we prioritize security. We implement technical and organizational measures to protect data from unauthorized access, disclosure, alteration, and destruction. Access to data is restricted to authorized personnel only. Please refer to the Trazer Inc. ",
    "privacyPolicy.section4.securityLink": "Security Statement ",
    "privacyPolicy.section4.info2": "for more information.",
    "privacyPolicy.section5": "Data Sharing",
    "privacyPolicy.section5.info": "Trazer Inc. does not sell, trade, or rent personal data to third parties. However, we may share data with:",
    "privacyPolicy.section5.subsection1": "Trusted service providers who help us deliver our services",
    "privacyPolicy.section5.subsection2": "Law enforcement or regulatory authorities when required by law.",
    "privacyPolicy.section5.subsection3": "Third parties with explicit consent.",
    "privacyPolicy.section6": "Data Retention",
    "privacyPolicy.section6.info": "We retain data only for as long as necessary for the purposes outlined in this policy or as required by law.",
    "privacyPolicy.section7": "Customer Rights",
    "privacyPolicy.section7.info": "Customers have the right to:",
    "privacyPolicy.section7.subsection1": "Access their data.",
    "privacyPolicy.section7.subsection2": "Correct inaccurate data.",
    "privacyPolicy.section7.subsection3": "Delete data in certain circumstances.",
    "privacyPolicy.section7.subsection4": "Object to or restrict the processing of data.",
    "privacyPolicy.section7.subsection5": "Withdraw consent when applicable.",
    "privacyPolicy.section7.info2": "To exercise these rights, please contact us using the information provided in Section 9.",
    "privacyPolicy.section8": "Changes to the Policy",
    "privacyPolicy.section8.info": "Trazer Inc. reserves the right to update this policy periodically to reflect changes in our practices or legal requirements. Any updates will be posted on our website, and the revised policy will apply from the date of posting.",
    "privacyPolicy.section9": "Contact Us",
    "privacyPolicy.section9.info": "If you have any questions or concerns about this policy or how we handle data, please contact us at:",
    "privacyPolicy.footer.line1": "Trazer Inc.",
    "privacyPolicy.footer.line2": "629 Euclid Ave",
    "privacyPolicy.footer.line3": "11th Floor, Suite 1101",
    "privacyPolicy.footer.line4": "Cleveland, OH 44114",
    "privacyPolicy.footer.line5": "privacy@trazer.com",
    "privacyPolicy.footer.line6": "(440) 835-9191",
    "accessDenied.title": "Access Denied",
    "accessDenied.line1": "You do not have access to this page",
    "accessDenied.line2": "If you believe this is a mistake please contact TRAZER Support",
    "validatorErrors.emptyFormWarning": "Please fill out all required fields",
    "validatorErrors.invalidDataFormWarning": "Please fill out all fields with valid data",
    "validatorErrors.userInactive": "This user is inactive you cannot edit his admin settings.",
    "widgets.actions.configure": "Configure",
    "widgets.fieldName.customer": "Customer",
    "widgets.fieldName.user": "User",
    "myProfile.editModal.title": "My Profile",
    "support.title": "Contact Support",
    "portalVersion.title": "Please select your version",
    "login.wrongAlert": "Wrong Username or PIN",
    "login.username": "Username",
    "login.pin": "PIN",
};

export default DefaultTranslations;
