/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTranslation } from '../../CoreComponents/Translation';
import { TabLayout } from '../../CoreComponents/Layout';
import { Categories } from './Categories/Categories';
import { Customers } from './Customers/Customers';
import { Units } from './Units/Units';
import { Users } from './Users/Users';
import { BaseActivities } from './BaseActivities/BaseActivities';
import { ScriptedActivities } from './ScriptedActivities/ScriptedActivities';
import { Tags } from './Tags/Tags';
import { Pillars } from './Pillars/Pillars';
import { ActivityGroups } from './ActivityGroups/ActivityGroups';
import { ActivityTemplates } from './ActivityTemplates/ActivityTemplates';
import { Sites } from './Sites/Sites';
import { Tests } from './Tests/Tests';
import { AdminTypes } from '../../Utils/Constants';
import { UserGroups } from './UserGroups/UserGroups';
import { useSelector } from 'react-redux';

const styles = {
    layout: css`
        display: flex;
        flex-direction: row;
        width: 100%;

        & > div {
            padding: 8px;
        }
    `,
};

export const  ManagePage = () => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const adminType = storeState.currentUser.admin.adminTypeId;
    const isTrazerAdmin = adminType === AdminTypes.TRAZERAdmin || adminType === AdminTypes.TRAZERView;
    const isSuperAdmin = adminType === AdminTypes.SuperAdmin;

    const pages = useMemo(() => [
            isTrazerAdmin ?
                { name: t('manage.sideNavigation.categories'), path: 'categories', ContentComponent: Categories }
                : null,
            isTrazerAdmin ?
                { name: t('manage.sideNavigation.tags'), path: 'tags', ContentComponent: Tags }
                : null,
            isTrazerAdmin ?
                { name: t('manage.sideNavigation.pillars'), path: 'pillars', ContentComponent: Pillars }
                : null,
            isTrazerAdmin ?
                { name: t('manage.sideNavigation.activityGroups'), path: 'activityGroups', ContentComponent: ActivityGroups }
                : null,
            isTrazerAdmin ?
                { name: t('manage.sideNavigation.activityTemplates'), path: 'activityTemplates', ContentComponent: ActivityTemplates }
                : null,
            isTrazerAdmin ?
                { name: t('manage.sideNavigation.baseActivities'), path: 'base-activities', ContentComponent: BaseActivities }
                : null,
            isTrazerAdmin ?
                { name: t('manage.sideNavigation.scriptedActivities'), path: 'scripted-activities', ContentComponent: ScriptedActivities }
                : null,
            isTrazerAdmin || isSuperAdmin ?
                { name: isSuperAdmin ? t('manage.sideNavigation.organization') : t('manage.sideNavigation.customers'), path: 'customers', ContentComponent: Customers }
                : null,
            { name: t('manage.sideNavigation.sites'), path: 'sites', ContentComponent: Sites},
            { name: t('manage.sideNavigation.units'), path: 'units', ContentComponent: Units },
            { name: t('manage.sideNavigation.userGroups'), path: 'user-groups', ContentComponent: UserGroups },
            { name: t('manage.sideNavigation.users'), path: 'users', ContentComponent: Users},
            { name: t('manage.sideNavigation.tests'), path: 'tests', ContentComponent: Tests, isDefault: true },
        ].filter(x => x),
        [t, storeState.currentUser]
    );
    return <TabLayout items={pages} isPageWide type="router" css={styles.layout} />;
};
