/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Button } from '../../CoreComponents/Button';
import { Card } from '../../CoreComponents/Card';
import { TableCSF } from '../../CoreComponents/TableComponents/TableCSF';
import { useTranslation } from '../../CoreComponents/Translation';
import { useHook } from '../../CoreComponents/Utils';
import { DistributionChart } from '../../CoreComponents/ChartComponents/DistributionChart';
import { RadarChart } from '../../CoreComponents/ChartComponents/RadarChart';
import { LineChart } from '../../CoreComponents/ChartComponents/LineChart';
import { shortTime } from '../../Utils/Common';

const reactionTimeTabStyles = {
    root: css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    `,
    tabSelection: css`
        border: 2px solid #00ABA5;
        border-radius: 8px;
        padding: 2px;
        margin: 40px auto 20px auto;
        width: 256px;

        & > button {
            border-radius: 4px;
            width: 50%;
            min-width: unset;
        }
    `,
    tabContent: css`
        height: calc(100% - 80px);
        overflow: auto;
    `,
    graphTabContent: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 32px 0 12px;
        height: 100%;

        & > * {
            max-height: 600px;
        }
    `,
};

const SubTabs = {
    Graphs: 1,
    Table: 2
};

export const SubTabsTranslationKeysArr = [
    { value: SubTabs.Graphs, translationKey: 'analytics.subTabs.graphs' },
    { value: SubTabs.Table, translationKey: 'analytics.subTabs.table' },
];

export const ReactionTimeTab = ({ reportData }) => {
    const { t } = useTranslation();
    const $selectedTab = useHook(SubTabs.Graphs);
    const chartData = useMemo(() => {
        const { charts } = reportData.reactionTime;
        const dataMap = {
            forward: t('analytics.chartData.forward'),
            forwardRight: t('analytics.chartData.forwardRight'),
            right: t('analytics.chartData.right'),
            backwardRight: t('analytics.chartData.backwardRight'),
            backward: t('analytics.chartData.backward'),
            backwardLeft: t('analytics.chartData.backwardLeft'),
            left: t('analytics.chartData.left'),
            forwardLeft: t('analytics.chartData.forwardLeft'),
        };

        return {
            distribution: charts.distribution,
            directionalAverage: [{
                label: t('analytics.charts.reactionTimeDirectionalAverage'),
                data: charts.directionalAverage.map(x => ({ label: dataMap[x.label], value: x.value })),
                exportSettings: {
                    xAxis: t('analytics.chartExportDataField.direction'),
                    yAxis: t('analytics.charts.reactionTimeDirectionalAverage'),
                },
            }],
            trend: [{
                label: t('analytics.charts.reactionTimeTrend'),
                data: charts.trend,
                exportSettings: {
                    xAxis: t('analytics.chartExportDataField.date'),
                    yAxis: t('analytics.charts.reactionTimeTrend'),
                },
            }],
        };
    }, [t, reportData.reactionTime]);

    const headCells = useMemo(() => [
        {
            id: 'date',
            label: t('analytics.table.date'),
            columnWidth: 150,
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.date ? shortTime(rowData.date) : '-';
            }
        },
        {
            id: 'fullName',
            label: t('analytics.table.fullName'),
            columnWidth: 150,
            isSortable: true
        },
        {
            id: 'username',
            label: t('analytics.table.username'),
            columnWidth: 150,
            isSortable: true
        },
        {
            id: 'scriptedActivity',
            label: t('analytics.table.scriptedActivity'),
            columnWidth: 200,
            isSortable: true
        },
        {
            id: 'scriptedActivityStep',
            label: t('analytics.table.scriptedActivityStep'),
            columnWidth: 240,
            isSortable: true
        },
        {
            id: 'average',
            label: t('analytics.table.avg'),
            columnWidth: 80,
            isSortable: true
        },
        {
            id: 'forward',
            label: t('analytics.table.forward'),
            columnWidth: 80,
            isSortable: true
        },
        {
            id: 'forwardRight',
            label: t('analytics.table.forwardRight'),
            columnWidth: 80,
            isSortable: true
        },
        {
            id: 'right',
            label: t('analytics.table.right'),
            columnWidth: 80,
            isSortable: true
        },
        {
            id: 'backwardRight',
            label: t('analytics.table.backwardRight'),
            columnWidth: 80,
            isSortable: true
        },
        {
            id: 'backward',
            label: t('analytics.table.backward'),
            columnWidth: 80,
            isSortable: true
        },
        {
            id: 'backwardLeft',
            label: t('analytics.table.backwardLeft'),
            columnWidth: 80,
            isSortable: true
        },
        {
            id: 'left',
            label: t('analytics.table.left'),
            columnWidth: 80,
            isSortable: true
        },
        {
            id: 'forwardLeft',
            label: t('analytics.table.forwardLeft'),
            columnWidth: 80,
            isSortable: true
        },
        {
            id: 'lrDifference',
            label: t('analytics.table.lrDifference'),
            columnWidth: 190,
            isSortable: true
        },
        {
            id: 'flFRDifference',
            label: t('analytics.table.flFRDifference'),
            columnWidth: 190,
            isSortable: true
        },
        {
            id: 'blBRDifference',
            label: t('analytics.table.blBRDifference'),
            columnWidth: 190,
            isSortable: true
        },
        {
            id: 'quick-fix',
            label: '',
            CellRender: () => null
        },
    ], [t]);

    const { table } = reportData.reactionTime;
    return (
        <div css={reactionTimeTabStyles.root}>
            <div css={reactionTimeTabStyles.tabContent}>
                {$selectedTab.value === SubTabs.Graphs ?
                    <div css={reactionTimeTabStyles.graphTabContent}>
                        {chartData.distribution.length > 1 &&
                            <Card title={t('analytics.charts.reactionTimeDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.reactionTimeDistribution')}
                                    unitType={t('constants.units.seconds')}
                                    unitTypeShort={t('constants.units.secondsShort')}
                                    exportFileName={t('analytics.charts.reactionTimeDistribution')}
                                    enableOptionsMenu
                                    data={chartData.distribution}
                                    bottomAxisTitle={t('analytics.charts.reactionTimeDistribution.xAxis')}
                                />
                            </Card>
                        }
                        <Card title={t('analytics.charts.reactionTimeDirectionalAverage')} unitType={t('constants.units.seconds')}>
                            <RadarChart
                                title={t('analytics.charts.reactionTimeDirectionalAverage')}
                                unitType={t('constants.units.seconds')}
                                exportFileName={t('analytics.charts.reactionTimeDirectionalAverage')}
                                enableOptionsMenu
                                datasets={chartData.directionalAverage}
                            />
                        </Card>
                        <Card title={t('analytics.charts.reactionTimeTrend')}>
                            <LineChart
                                title={t('analytics.charts.reactionTimeTrend')}
                                unitType={t('constants.units.seconds')}
                                exportFileName={t('analytics.charts.reactionTimeTrend')}
                                enableOptionsMenu
                                datasets={chartData.trend}
                                xAxisLabel={t('analytics.charts.xAxis')}
                                yAxisLabel={t('analytics.charts.reactionTimeTrend.yAxis')}
                            />
                        </Card>
                    </div>
                    :
                    <TableCSF
                        headCells={headCells}
                        rows={table}
                        totalItemsCount={table.length}
                        paginationSettingsPosition="bottom-left"
                        rowKeySelector={r => r.testStepId}
                        isLoading={false}
                        showAdvancedSearch={false}
                        showExport
                        exportFileNameGenerator={() => `reaction-time-${(new Date()).toISOString().split('T')[0]}`}
                    />
                }
            </div>
            <div css={reactionTimeTabStyles.tabSelection}>
                {SubTabsTranslationKeysArr.map(x => (
                    <Button
                        key={x.value}
                        variant={$selectedTab.value === x.value ?
                            'contained'
                            : 'text'
                        }
                        onClick={() => {
                            $selectedTab.set(x.value);
                        }}
                    >
                        {t(x.translationKey)}
                    </Button>
                ))}
            </div>
        </div>
    );
};
