import { format, parse, intervalToDuration, formatDuration } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import * as Papa from 'papaparse';
import * as XLSX from "xlsx";
import { Genders, SortOrder } from './Constants';

/**
 * @param {'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'} method
 * @param {any} data
 * @returns {RequestInit}
 */
export function makeFetchData(method, data) {
    // Default options are marked with *
    return {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: data ? JSON.stringify(data) : null // body data type must match "Content-Type" header
    }
}

/**
 * @param {string} url
 * @param {RequestInit} request
 * @returns {Promise<any>}
 */
function fetchData(url, request) {
    return window.authorizedFetch(url, request);
}

/**
 * @param {string} url
 * @returns {Promise<any>}
 */
export function getData(url = '') {
    return fetchData(url, makeFetchData('GET'));
}

/**
 * @param {string} url
 * @param {any} data
 * @returns {Promise<any>}
 */
export function postData(url = '', data = {}) {
    return fetchData(url, makeFetchData('POST', data));
}

/**
 * @param {string} url
 * @param {any} data
 * @returns {Promise<any>}
 */
export function patchData(url = '', data = {}) {
    return fetchData(url, makeFetchData('PATCH', data));
}

/**
 * @param {string} url
 * @param {any} data
 * @returns {Promise<any>}
 */
export function putData(url = '', data = {}) {
    return fetchData(url, makeFetchData('PUT', data));
}

/**
 * @param {string} url
 * @param {any} data
 * @returns {Promise<any>}
 */
export function deleteData(url = '', data = {}) {
    return fetchData(url, makeFetchData('DELETE', data));
}

// postData('https://example.com/answer', { answer: 42 })
//     .then(data => {
//         console.log(data); // JSON data parsed by `data.json()` call
//     });

export function urlQueryString(params, removeNulls) {
    const urlSearchParams = new URLSearchParams();
    for (const paramName in params) {
        if (removeNulls
            && (params[paramName] == null
                || params[paramName] === ''
            )
        ) {
            continue
        }

        if (Array.isArray(params[paramName])) {
            for (const paramValue of params[paramName]) {
                urlSearchParams.append(paramName, paramValue);
            }
        } else {
            urlSearchParams.append(paramName, params[paramName]);
        }
    }

    return urlSearchParams.toString();
}

/**
 * @param {number} minOrMaxValue
 * @param {number | undefined | null} maxValue
 * @returns {number}
 */
export function randomInt(minOrMaxValue, maxValue) {
    if (!maxValue) {
        return Math.floor(Math.random() * minOrMaxValue);
    }

    return Math.floor(minOrMaxValue + Math.random() * (maxValue - minOrMaxValue));
}

/**
 * Debounce a call to a function
 * @param {(...args)} onInput
 * @param {(...args)} func
 * @param {number} wait
 * @returns {(...args)}
 */
export function debounce(onInput, func, wait = 300) {
    let timeout;
    function debounced(...args) {
        const later = () => {
            func.apply(this, args);
        };

        clearTimeout(timeout);
        onInput.apply(this, args);
        timeout = setTimeout(later, wait);
    }

    debounced.clear = () => {
        clearTimeout(timeout);
    };

    return debounced;
}

export function isValidDateValue(date) {
    return date instanceof Date && !isNaN(date);
}

export function parseDate(dateString, formatString='MM/dd/yyyy') {
    return parse(dateString, formatString, new Date(), {
        useAdditionalWeekYearTokens: true
    });
}

export function dateAndMonth(date) {
    return format(date, 'MM/dd');
}

export function isoDate(date) {
    return format(date, 'yyyy-MM-dd');
}

export function shortDate(date) {
    return format(date, 'MM/dd/yyyy');
}

export function shortTime(date) {
    return format(date, 'Pp');
}

export function monthYearDate(date){
    return format (date, 'MMM-yy')
}

const formatDistanceLocale = { xHours: '{{count}} h', xMinutes: '{{count}} m', xSeconds: '{{count}} s' }
const shortEnLocale = { formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', count) }
export function durationInHours(seconds) {
    const duration = intervalToDuration({ start: 0, end: (seconds * 1000) })
    return formatDuration(duration, {
        format: ['hours', 'minutes', 'seconds'],
        zero: true,
        locale: shortEnLocale
    });
}

export function durationInMinutes(seconds) {
    const totalMinutes = Math.floor(seconds / 60);
    const totalSeconds = seconds % 60;
    return `${totalMinutes}:${totalSeconds < 10 ? '0' + totalSeconds : totalSeconds}`;
}

export function metersToFeet(meters) {
    return (3.28084 * meters);
}

export function metersToInches(meters) {
    return (39.3701 * meters);
}

export function metersToCentimeters(meters) {
    return (100 * meters);
}

export function newGUID() {
    return uuidv4();
}

export function defaultSort(collection, sortBy, sortDirection) {
    const newCollection = collection.slice(0);
    const sortFunction = (a, b) => {
        //date
        const regexDate = /^(\d{2,4}[-/]\d{2}[-/]\d{2,4})/; 
        const getDatePart = (value) => {
            if (typeof value !== 'string') {
                return null;
            }

            const match = value.match(regexDate);
            return match ? parseDate(match[1]) : null;
        };

        const dateA = getDatePart(a[sortBy]);
        const dateB = getDatePart(b[sortBy]);

        // If one of the values doesn't have a num part, it comes first
        if (dateA === null && dateB !== null) {
            return sortDirection === SortOrder.Asc ? -1 : 1;
        }
        if (dateB === null && dateA !== null) {
            return sortDirection === SortOrder.Asc ? 1 : -1;
        }

        // Compare date parts
        if (dateA < dateB) {
            return sortDirection === SortOrder.Asc ? -1 : 1;
        } else if (dateA > dateB) {
            return sortDirection === SortOrder.Asc ? 1 : -1;
        }

        //numerical
        const regexNum = /^(\d+(\.\d+)?)/; // Regular expression to separate num part
        const getNumPart = (value) => {
            let numPart = null;
            if (value && value.type === "span") {
                numPart = value.props.children[0];
            }else if(typeof value === 'string'){
                numPart = value;
            }else if(typeof value === 'number'){
                return value;
            }else{
                return null;
            }

            const match = numPart.match(regexNum);
            return match ? parseFloat(match[1]) : null;
        };

        const numA = getNumPart(a[sortBy]);
        const numB = getNumPart(b[sortBy]);

        // If one of the values doesn't have a num part, it comes first
        if (numA === null && numB !== null) {
            return sortDirection === SortOrder.Asc ? -1 : 1;
        }
        if (numB === null && numA !== null) {
            return sortDirection === SortOrder.Asc ? 1 : -1;
        }

        // Compare num parts
        if (numA < numB) {
            return sortDirection === SortOrder.Asc ? -1 : 1;
        } else if (numA > numB) {
            return sortDirection === SortOrder.Asc ? 1 : -1;
        }

        // If num parts are equal, compare as string
        const getCompareValue = (value) => {
            return typeof (value) === 'string' ? value.toLowerCase() : value
        };
        const aVal = getCompareValue(a[sortBy]);
        const bVal = getCompareValue(b[sortBy]);
        if (aVal === bVal) {
            return 0;
        }

        if ((sortDirection === SortOrder.Asc) === ((aVal < bVal) || !Boolean(aVal))) {
            return -1;
        }

        if ((sortDirection === SortOrder.Asc) === ((aVal > bVal) || !Boolean(bVal))) {
            return 1;
        }

        return 0;
    };

    newCollection.sort(sortFunction);

    return newCollection;
}

// WARN: if exportExcel can't reuse this method then we should move it to exportCSV
export function downloadFile(data, fileName, fileType) {
    const utf8Flags = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const fileData = new File(
        [utf8Flags, data],
        fileName,
        { type: fileType }
    );

    const url = window.URL.createObjectURL(fileData);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // do we need this?
    a.click();
    a.remove(); // do we need this?

    // let csvData = new Blob([csv], { type: 'text/csv' });
    // let csvUrl = URL.createObjectURL(csvData);

    // let hiddenElement = document.createElement('a');
    // hiddenElement.href = csvUrl;
    // hiddenElement.target = '_blank';
    // hiddenElement.download = fileName + '.csv';
    // hiddenElement.click();
}

export function exportCSV(data, fileName, delimiter = ',') {
    const csvData = Papa.unparse(
        data,
        // { columns, delimiter }
    );
    downloadFile(csvData, `${fileName}.csv`, 'text/plain');
}

export function exportExcel(data, fileName) {
    const worksheet = XLSX.utils.json_to_sheet(data, {});
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    XLSX.writeFile(workbook, fileName + '.xlsx');
}

export function parseCSV(file, customHeaders) {
    return new Promise((resolve, reject) => {
        const config = {
            header: true,
            skipEmptyLines: true,
            complete: resolve
        }
        if (customHeaders) {
            file.text().then(text =>
                Papa.parse(
                    `${customHeaders.join(',')}\n${text}`,
                    config
                )
            );
            return;
        }
        Papa.parse(
            file,
            config
        )
    })
}

export function downloadCanvasAsImage(targetCanvas, fileName, fileFormat) {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', fileName + '.' + fileFormat);
    const destinationCanvas = document.createElement("canvas");
    destinationCanvas.width = targetCanvas.width;
    destinationCanvas.height = targetCanvas.height;
    const destCtx = destinationCanvas.getContext('2d');

    //create a rectangle with the desired color
    destCtx.fillStyle = "#FFFFFF";
    destCtx.fillRect(0, 0, targetCanvas.width, targetCanvas.height);

    //draw the original canvas onto the destination canvas
    destCtx.drawImage(targetCanvas, 0, 0);

    //finally use the destinationCanvas.toDataURL() method to get the desired output;
    destinationCanvas.toDataURL()

    destinationCanvas.toBlob(function (blob) {
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.click();
    }, `image/${fileFormat}`);
}

export function parseHeight(height) {
    if (!isNaN(height)) {
        //in case it's only number
        return Math.round(height);
    }
    const regex = /(?<value>\d+\.{0,1}\d*)\s*(?<unit>in|inch|inches|ft|feet|m|meters|cm|centimeters)\s*$/gm;
    const result = regex.exec(height.toLowerCase());
    if (!result) {
        //error
        return null;
    }
    switch (result.groups.unit) {
        case 'ft':
        case 'feet':
            return Math.round(result.groups.value * 12)
        case 'm':
        case 'meters':
            return Math.round(result.groups.value * 39.37)
        case 'cm':
        case 'centimeters':
            return Math.round(result.groups.value * 0.393700787)
        case 'in':
        case 'inch':
        case 'inches':
        default:
            return Math.round(result.groups.value)
    }
}

export function parseWeight(weight) {
    if (!isNaN(weight)) {
        //in case it's only number
        return Math.round(weight);
    }
    const regex = /(?<value>\d+\.{0,1}\d*)\s*(?<unit>lb|lbs|pounds|kg|kilograms)\s*$/gm;
    const result = regex.exec(weight.toLowerCase());
    if (!result) {
        //error
        return null;
    }
    switch (result.groups.unit) {
        case 'kg':
        case 'kilograms':
            return Math.round(result.groups.value * 2.205)
        case 'pounds':
        case 'lb':
        case 'lbs':
        default:
            return Math.round(result.groups.value)
    }
}

export function parseGender(gender) {
    switch (gender.toLowerCase()) {
        case "nb":
        case "non-binary":
            return Genders.NonBinary
        case "f":
        case "female":
            return Genders.Female;
        case "m":
        case "male":
        default:
            return Genders.Male;
    }
}

export function toCamelCase(inputArray) {
    let result = "";
    for (let i = 0, len = inputArray.length; i < len; i++) {
        let currentStr = inputArray[i];

        let tempStr = currentStr.toLowerCase();

        if (i !== 0) {
            // convert first letter to upper case (the word is in lowercase)
            tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
        }

        result += tempStr;
    }
    return result;
}

export function twiceInArray(array, value) {
    let count = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i] === value) {
            count++;
        }
    }
    return count > 1;
}

export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

/**
 * @typedef {{
 *     trackReactionTime: boolean;
 *     trackSpeed: boolean;
 *     trackAcceleration: boolean;
 *     trackDeceleration: boolean;
 *     trackArms: boolean;
 *     trackDistance: boolean;
 *     trackHeartRate: boolean;
 *     trackJumpHeight: boolean;
 *     trackLegs: boolean;
 *     trackMisses: boolean;
 *     trackSpine: boolean;
 *     trackSquatDepth: boolean;
 *     trackSway: boolean;
 *     trackTorso: boolean;
 * }} TrackedSettings
 * @param {string} settingsString
 * @returns {TrackedSettings}
 */
export function parseTrackedSettings(settingsString) {
    const settings = settingsString ?
        JSON.parse(settingsString)
        : {};
    const childSettings = settings.TrackedMetrics?.ChildSettings
    const hasTrackedMetrics = settings.TrackedMetrics && settings.TrackedMetrics.SelectedValue.toLowerCase() === 'on' ;
    return {
        trackReactionTime: hasTrackedMetrics && childSettings?.ReactionTime.SelectedValue.toLowerCase() === 'on',
        trackSpeed: hasTrackedMetrics && childSettings?.Speed.SelectedValue.toLowerCase() === 'on',
        trackAcceleration: hasTrackedMetrics && childSettings?.Acceleration.SelectedValue.toLowerCase() === 'on',
        trackDeceleration: hasTrackedMetrics && childSettings?.Deceleration.SelectedValue.toLowerCase() === 'on',
        trackArms: hasTrackedMetrics && childSettings?.Arms.SelectedValue.toLowerCase() === 'on',
        trackDistance: hasTrackedMetrics && childSettings?.Distance.SelectedValue.toLowerCase() === 'on',
        trackHeartRate: hasTrackedMetrics && childSettings?.HeartRate.SelectedValue.toLowerCase() === 'on',
        trackJumpHeight: hasTrackedMetrics && childSettings?.JumpHeight.SelectedValue.toLowerCase() === 'on',
        trackLegs: hasTrackedMetrics && childSettings?.Legs.SelectedValue.toLowerCase() === 'on',
        trackMisses: hasTrackedMetrics && childSettings?.Misses.SelectedValue.toLowerCase() === 'on',
        trackSpine: hasTrackedMetrics && childSettings?.Spine.SelectedValue.toLowerCase() === 'on',
        trackSquatDepth: hasTrackedMetrics && childSettings?.SquatDepth.SelectedValue.toLowerCase() === 'on',
        trackSway: hasTrackedMetrics && childSettings?.Sway.SelectedValue.toLowerCase() === 'on',
        trackTorso: hasTrackedMetrics && childSettings?.Torso.SelectedValue.toLowerCase() === 'on'
    };
}
