import { defaultSort, exportCSV, exportExcel, shortTime } from '../../Utils/Common';
import { getTextContent, useHook } from '../Utils';
import {
    AdvancedSearchDefaultValue,
    AdvancedSearchMatchType,
    ExportTypes,
    ExportTypesTranslationKeysObj
} from '../../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setSortOptions } from '../../globalStates/tableSortState';
import { useEffect, useMemo } from 'react';
import { Table } from './Table';

export const TableCSF = ({
    rows,
    isLoading,
    headCells,
    rowKeySelector,
    additionalData,
    isRowSelected,
    onSortEvent,
    showExport,
    showSearch = true,
    showAdvancedSearch = true,
    showDateRange = false,
    $dateRangeOption,
    exportFileNameGenerator,
    customActions,
    tableContainerHeight,
    enablePersistingFilters,
    pageName,
    ...props
}) => {
    const sortOptions = useSelector((state) => state.tableSortState);
    const dispatch = useDispatch();

    const $sortForFirstTime = useHook(true);
    const $displayedRows = useHook([]);
    const $filteredRows = useHook([]);
    const $lastSortOptions = useHook((pageName && sortOptions[pageName]) || null);
    const fields = useMemo(() => headCells.map(x => x.id), [headCells]);
    const advancedSearchSettings = useMemo(() =>
        headCells
            .filter(x => x.advancedSearch)
            .reduce(
                (accumulator, current) => ({ ...accumulator, [current.id]: current.advancedSearch }),
                {}
            )
        , [headCells]);
    const textRenderers = useMemo(() =>
        headCells
            .filter(x => x.textRenderer)
            .reduce(
                (accumulator, current) => ({ ...accumulator, [current.id]: current.textRenderer }),
                {}
            )
        , [headCells]);

    const onSort = (sortByValue, sortDirectionValue, pageValue, itemsPerPageValue, searchTextValue, advancedSearchValue, dateRangeValue) => {
        const persistedOptions = pageName && sortOptions[pageName];
        const currentOptions = [sortByValue, sortDirectionValue, pageValue, itemsPerPageValue, searchTextValue, advancedSearchValue, dateRangeValue];

        const checkForChange = (persistedOptions, currentOptions) => {
            if (!persistedOptions || !currentOptions) {
                return true;
            }

            const defaultValues = [null, 'asc', 0, 10, ''];
            const advancedSearchIndex = 5;
            for (let i = 0; i < currentOptions.length - 2; i++) {
                // eslint-disable-next-line eqeqeq
                if (currentOptions[i] != persistedOptions[i] && currentOptions[i] != defaultValues[i]) {
                    return true;
                }
            }

            const currentAdvancedSearch = Object.values(currentOptions[advancedSearchIndex]);
            const persistedAdvancedSearch = Object.values(persistedOptions[advancedSearchIndex]);
            for (let i = 0; i < currentAdvancedSearch.length - 1; i++) {
                if (currentAdvancedSearch[i] !== persistedAdvancedSearch[i]
                    && currentAdvancedSearch[i] !== '') {
                    return true;
                }
            }
            return false;
        }

        const options = !enablePersistingFilters || !$sortForFirstTime.value || checkForChange(persistedOptions, currentOptions) ? currentOptions : persistedOptions;

        dispatch(setSortOptions((
            {
                tableName: pageName,
                value: options
            }
        )))

        $lastSortOptions.set(options);
        $sortForFirstTime.set(false);

        const [sortBy, sortDirection, page, itemsPerPage, searchText, advancedSearch, dateRange] = options;

        const filterFunction = (value, searchValue, matchType = AdvancedSearchMatchType.Contains) => {
            if (searchValue === AdvancedSearchDefaultValue || (Array.isArray(searchValue) && !searchValue.length)) {
                return true;
            }

            if (matchType === AdvancedSearchMatchType.Exact) {
                // eslint-disable-next-line eqeqeq
                return value == searchValue;
            }

            if (Array.isArray(searchValue) && Array.isArray(value)) {
                return value.some(x =>
                    searchValue
                        .map(value =>
                            value.toString()
                                .toLowerCase()
                        ).includes(
                            x.toString()
                                .toLowerCase()
                        )
                );
            }

            if (Array.isArray(searchValue)) {
                return searchValue.map(x =>
                    x.toString()
                        .toLowerCase()
                ).includes(value?.toString().toLowerCase());
            }

            if (Array.isArray(value)) {
                return value.map(x =>
                    x.toString()
                        .toLowerCase()
                ).includes(searchValue.toString().toLowerCase());
            }

            if (typeof (value) === 'string' || typeof (value) === 'number' || typeof (value) === 'boolean') {
                return value.toString()
                    .toLowerCase()
                    .includes(searchValue?.toLowerCase());
            }

            if (value instanceof Date) {
                return shortTime(value)
                    .toLowerCase()
                    .includes(searchValue?.toLowerCase());
            }

            return false;
        };

        const filteredRows = searchText || Object.values(advancedSearch || {}).some(x => x !== AdvancedSearchDefaultValue) ?
            rows.filter(x => {
                const result =
                    (!searchText
                        || fields.some(k => filterFunction(
                            textRenderers[k] ? textRenderers[k]({ rowData: x }) : x[k],
                            searchText
                        ))
                    )
                    && fields.every(k => {
                        const r = advancedSearchSettings[k] ?
                            filterFunction(
                                x[k],
                                advancedSearch[k],
                                advancedSearchSettings[k].matchType
                            )
                            : filterFunction(x[k], advancedSearch[k]);
                        return r;
                    });
                return result;
            })
            : rows.slice(0);

        const sortedRows = sortBy ?
            defaultSort(filteredRows, sortBy, sortDirection)
            : filteredRows;

        $filteredRows.set(sortedRows);
        if (!props.disablePagination) {
            $displayedRows.set(sortedRows.slice(page * itemsPerPage, (page + 1) * itemsPerPage));
        } else {
            $displayedRows.set(sortedRows);
        }

        onSortEvent && onSortEvent(sortBy, sortDirection, page, itemsPerPage);

        // Example with server side filtering
        // $isLoading.set(true);
        // UserService.getAll(9999, sortBy, sortDirection === SortOrder.Asc)
        //     .then(users => {
        //         $users.set(users);
        //         $isLoading.set(false);
        //     });
    };

    const onExport = (exportType) => {
        let exportMethod = exportCSV;
        switch (exportType) {
            case ExportTypes.CSV: break;
            case ExportTypes.Excel:
                exportMethod = exportExcel;
                break;
            default:
                throw new Error(`Unsupported export type [${exportType}][${ExportTypesTranslationKeysObj[exportType]}]`);
        }

        const mapping = headCells
            .filter(x => x.label)
            .reduce(
                (accumulator, current) => ({ ...accumulator, [current.id]: { label: current.label, textRenderer: current.textRenderer } }),
                {}
            );
        exportMethod(
            $filteredRows.value.map((fr, index) => {
                const data = {};
                for (const key in mapping) {
                    const cellTextValue = mapping[key].textRenderer ? mapping[key].textRenderer({ rowIndex: index, rowData: fr, additionalData }) : fr[key];
                    data[mapping[key].label] = getTextContent(cellTextValue);
                }

                return data;
            }),
            (exportFileNameGenerator && exportFileNameGenerator()) || `report-${(new Date()).toISOString().split('T')[0]}`
        );
    }

    useEffect(() => {
        if (!$lastSortOptions.value) {
            return;
        }

        onSort(...$lastSortOptions.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows])

    return (
        <Table
            rows={$displayedRows.value}
            isLoading={isLoading}
            headCells={headCells}
            totalItemsCount={$filteredRows.value.length}
            onSort={onSort}
            sortOptions={pageName && sortOptions[pageName]}
            rowKeySelector={rowKeySelector}
            additionalData={additionalData}
            isRowSelected={isRowSelected}
            showExport={showExport}
            onExport={onExport}
            showSearch={showSearch}
            showAdvancedSearch={showAdvancedSearch}
            showDateRange={showDateRange}
            $dateRangeOption={$dateRangeOption}
            customActions={customActions}
            tableContainerHeight={tableContainerHeight}
            disablePagination={props.disablePagination}
            showTableSizeInfo={props.showTableSizeInfo}
            paginationSettingsPosition={props.paginationSettingsPosition}
            className={props.className}
        />
    );
};
