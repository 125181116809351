/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { TableCSF } from '../../CoreComponents/TableComponents/TableCSF';
import { useHook } from '../../CoreComponents/Utils';
import { TestService } from '../../Services/TestService';
import { shortTime, defaultSort } from '../../Utils/Common';
import { TrashCanIcon } from '../../CoreComponents/CustomIcons';
import { useTranslation } from '../../CoreComponents/Translation';
import { InternalLink } from '../../CoreComponents/Layout';
import { useDispatch, useSelector } from "react-redux";
import { AdminTypes, AdvancedSearchMatchType, AdvancedSearchType, SortOrder, TestStatusesTranslationKeysObj, DateRangeStartAndEndTimes } from "../../Utils/Constants";
import { IconButton } from '../../CoreComponents/Button';
import { CircularLoader } from '../../CoreComponents/Loaders';
import { DeleteConfirmationModal } from "../../CoreComponents/DeleteConfirmationModal";
import { setTests } from "../../globalStates/tableDataState";

const testsListStyles = {
    root: css`
        display: flex;
        flex-direction: column;

        height: 85vh;
        padding: 40px 0 40px 28px;

        & > .table {
            height: 65vh;
        }

        width: unset;
        // @media (min-width: 1500px) {
        //     width: 1200px;
        // }

        & h2 {
            margin-left: 12px;
            margin-top: 0;
        }
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,
}

export const UserTestsTab = ({ $selectedUser }) => {
    const { t } = useTranslation();
    const $refreshTests = useHook({});
    const $tests = useHook([]);
    const $testForDelete = useHook(null);
    const storeState = useSelector((state) => state.storeState);
    const $isLoading = useHook(true);
    const $scriptedActivityNames = useHook([]);
    const $categoryNames = useHook([]);
    const $scriptedActivitySites = useHook([]);
    const $scriptedActivityStatuses = useHook([]);
    const $dateRangeOption = useHook(5);
    const dispatch = useDispatch();

    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 50,
            CellRender: ({ rowData }) => {
                return (
                    <IconButton
                        component={InternalLink}
                        to={`/reports/test/${rowData.testId}`}
                    >
                        <SignalCellularAltIcon />
                    </IconButton>
                );
            }
        },
        {
            id: 'SACategory',
            label: t('tests.table.scriptedActivity'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($scriptedActivityNames.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            columnWidth: 200,
            CellRender: ({ rowData }) => {
                return rowData.scriptedActivityName;
            }
        },
        {
            id: 'scriptedActivityAlias',
            label: '',
            columnWidth: 0,
            CellRender: ({ rowData }) => {
                return '';
            }
        },
        {
            id: 'categoryName',
            label: t('tests.table.category'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($categoryNames.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            columnWidth: 150,
            CellRender: ({ rowData }) => {
                return rowData.categoryName;
            }
        },
        {
            id: 'startTime',
            label: t('tests.table.time'),
            columnWidth: 120,
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.startTime ? shortTime(new Date(rowData.startTime)) : '-';
            }
        },
        {
            id: 'userFullName',
            label: t('tests.table.name'),
            isSortable: true,
            columnWidth: 150,
        },
        {
            id: 'username',
            label: t('tests.table.username'),
            isSortable: true,
            columnWidth: 140,
        },
        {
            id: 'siteName',
            label: t('tests.table.site'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($scriptedActivitySites.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            columnWidth: 180,
        },
        {
            id: 'testStatus',
            label: t('tests.table.status'),
            advancedSearch: {
                type: AdvancedSearchType.Select,
                label: '',
                matchType: AdvancedSearchMatchType.Exact,
                values: defaultSort($scriptedActivityStatuses.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            columnWidth: 120,
            CellRender: ({ rowData }) => {
                return t(TestStatusesTranslationKeysObj[rowData.testStatus]);
            },
            textRenderer: ({ rowData }) => {
                return t(TestStatusesTranslationKeysObj[rowData.testStatus]);
            }
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton disabled={isTrazerViewAdmin} onClick={() => $testForDelete.set(rowData)}>
                            <TrashCanIcon />
                        </IconButton>
                    </>
                )
            }
        },
    ], [t, $scriptedActivityNames.value, $scriptedActivitySites.value, $scriptedActivityStatuses.value, $categoryNames.value]);

    const deleteTest = (testId) => {
        TestService.deleteTest(testId)
            .then(() => {
                $tests.set($tests.value.filter(x => x.testId !== testId));
                $testForDelete.set(null);
            });
    }

    useEffect(() => {
        $isLoading.set(true);
        TestService.getAllTestsNoMap(9999, $selectedUser.value.userId, null, null, DateRangeStartAndEndTimes[$dateRangeOption.value].startDate, DateRangeStartAndEndTimes[$dateRangeOption.value].endDate)
            .then(tests => {
                const mappedTests = tests.map(t => ({
                    ...t,
                    startTime: new Date(t.startTime),
                    endTime: new Date(t.endTime),
                    SACategory: `${t.scriptedActivityName} (${t.categoryName})`,
                    testSteps: (t.testSteps || []).map(ts => ({
                        ...ts,
                        startTime: t.startTime ? new Date(t.startTime) : null,
                        endTime: t.endTime ? new Date(t.endTime) : null,
                    }))
                }))

                const scriptedActivityNames = {};
                const scriptedActivityCategories = {};
                const scriptedActivitySites = {};
                const scriptedActivityStatuses = {};
                for (const test of mappedTests) {
                    scriptedActivityNames[test.SACategory] = 1;
                    scriptedActivityCategories[test.categoryName] = 1;
                    scriptedActivitySites[test.siteName] = 1;
                    scriptedActivityStatuses[test.testStatus] = 1;
                };
                $tests.set(mappedTests);
                dispatch(setTests(tests));
                $scriptedActivityNames.set(Object.keys(scriptedActivityNames).map(x => {
                    const value = x;
                    const label = x;
                    return { value, label };
                }));
                $categoryNames.set(Object.keys(scriptedActivityCategories).map(x => {
                    const value = x;
                    const label = x;
                    return { value, label };
                }));
                $scriptedActivitySites.set(Object.keys(scriptedActivitySites).map(x => {
                    return {
                        value: x,
                        label: x
                    }
                }));
                $scriptedActivityStatuses.set(Object.keys(scriptedActivityStatuses).map(x => {
                    return {
                        value: x,
                        label: t(TestStatusesTranslationKeysObj[x])
                    }
                }));
            }).finally(() => $isLoading.set(false));
    }, [$refreshTests.value, $dateRangeOption.value])

    return $isLoading.value ?
    <div css={testsListStyles.loadingContainer}>
        <CircularLoader />
    </div>
    :
    (
        <div css={testsListStyles.root}>
            <TableCSF
                className="table"
                headCells={headCells}
                rows={$tests.value}
                rowKeySelector={r => r.testId}
                isLoading={$isLoading.value}
                showDateRange={true}
                $dateRangeOption={$dateRangeOption}
            />
            <DeleteConfirmationModal
                onDelete={() => deleteTest($testForDelete.value.testId)}
                open={Boolean($testForDelete.value)}
                onCancel={() => $testForDelete.set(null)}
                title={t('tests.deleteModal.title')}
                description={t('tests.deleteModal.description')}
                deleteLabel={t('actions.delete')}
            />
        </div>
    )
}
