/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { ActivityTemplateService } from '../../../Services/ActivityTemplateService';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { Button } from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { AddActivityTemplateContent } from './AddActivityTemplateContent';
import { TemplateInformationTab } from './TemplateInformationTab';
import { ActivityTemplateLibraryTab } from './ActivityTemplateLibraryTab';
import { ActivityTemplateUnitsTab } from './ActivityTemplateUnitsTab';
import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { IconButton } from '../../../CoreComponents/Button';
import { TabLayout } from '../../../CoreComponents/Layout';
import { AdminTypes } from '../../../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setActivityTemplates } from '../../../globalStates/tableDataState';

const activityTemplatesStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
}

export const ActivityTemplatesList = ({ $selectedActivityTemplate, $refreshActivityTemplates }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const $activityTemplates = useHook(tableDataState.activityTemplates);
    const $isLoading = useHook(!Boolean(tableDataState.activityTemplates.length));
    const $isAddActivityTemplateModalOpened = useHook(false);
    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton disabled={isTrazerViewAdmin} onClick={() => $selectedActivityTemplate.set(rowData)}>
                            <EditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'name',
            label: t('activityTemplates.table.name'),
            isSortable: true,
        },
    ], [t]);

    useEffect(() => {
        ActivityTemplateService.getAll(9999)
            .then(activityTemplates => {
                dispatch(setActivityTemplates(activityTemplates));
                $activityTemplates.set(activityTemplates);
                $isLoading.set(false);
            })
    }, [$refreshActivityTemplates.value]);

    return (
        <div css={activityTemplatesStyles.root}>
            <TableCSF
                customActions={
                    <div css={activityTemplatesStyles.actions}>
                        <Button disabled={isTrazerViewAdmin} onClick={() => $isAddActivityTemplateModalOpened.set(true)} >
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$activityTemplates.value}
                rowKeySelector={r => r.templateId}
                isLoading={$isLoading.value}
                totalItemsCount={$activityTemplates.value.length}
                showAdvancedSearch={false}
            />
            <Modal
                open={$isAddActivityTemplateModalOpened.value}
                onClose={() => $isAddActivityTemplateModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableCloseButton
            >
                <AddActivityTemplateContent onCancel={() => $isAddActivityTemplateModalOpened.set(false)}
                    onAdd={() => {
                        $isAddActivityTemplateModalOpened.set(false);
                        $refreshActivityTemplates.set({});
                    }} />
            </Modal>
        </div>
    )
};

export const ActivityTemplates = () => {
    const { t } = useTranslation();
    const $selectedActivityTemplate = useObjectHook(null);
    const $refreshActivityTemplates = useHook({});
    const $prevSelectedActivityTemplates = useHook(null);
    const $tabs = useHook([]);

    useEffect(() => {
        if ($prevSelectedActivityTemplates.value && $selectedActivityTemplate.value) {
            $refreshActivityTemplates.set({});
            $prevSelectedActivityTemplates.set(null);
            $selectedActivityTemplate.set(null);
            return;
        }

        $prevSelectedActivityTemplates.set($selectedActivityTemplate.value);
    }, [$selectedActivityTemplate.value]);

    useEffect(() => {
        if (!$selectedActivityTemplate.value) {
            return;
        }

        $tabs.set([
            { name: t('activityTemplates.editModal.title'), ContentComponent: () => <TemplateInformationTab $selectedActivityTemplate={$selectedActivityTemplate} onCancel={() => $selectedActivityTemplate.set(null)} />, isDefault: true },
            { name: t('activityTemplates.editModal.activityTemplateLibraryTab'), ContentComponent: () => <ActivityTemplateLibraryTab $selectedActivityTemplate={$selectedActivityTemplate} onCancel={() => $selectedActivityTemplate.set(null)} /> },
            { name: t('activityTemplates.editModal.activityTemplateUnitsTab'), ContentComponent: () => <ActivityTemplateUnitsTab $selectedActivityTemplate={$selectedActivityTemplate} onCancel={() => $selectedActivityTemplate.set(null)} /> },
        ]);
    }, [$selectedActivityTemplate.value]);

    return (
        <>
            <ActivityTemplatesList $selectedActivityTemplate={$selectedActivityTemplate} $refreshActivityTemplates={$refreshActivityTemplates} />
            <Modal
                open={!!$selectedActivityTemplate.value}
                onClose={() => $selectedActivityTemplate.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableCloseButton
            >
                <TabLayout
                    items={$tabs.value}
                />
            </Modal>
        </>
    )
}
