/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import {
    maxLengthValidator,
    requiredValidator
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { ActivityTemplateService } from '../../../Services/ActivityTemplateService';
import { AdminTypes } from '../../../Utils/Constants';
import { useSelector } from 'react-redux';

const activityTemplateTabStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 1200px) {
            width: 900px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .inputs {
            display: flex;
            flex-direction: column;
            width: 100%;

            & textarea {
                width: calc(100% - 20px);
                height: 120px;
                resize: none;
                overflow-y: auto;
            }

            & > .sku-number {
                display: inline-flex;
                margin: 0 0 30px 0;

                width: calc(100% - 40px);
                @media (min-width: 1200px) {
                    width: calc(50% - 40px);
                }
            }

            & > .notes {
                margin: 0;
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;

            & > button {
                border-radius: 8px;
                min-width: 136px;
                box-shadow: none;
            }

            & > button:first-of-type {
                margin-right: 20px;
                margin-left: auto;
                color: #858585;
                background: #EEEEEE;
                border: 2px solid;
            }
        }
    `,
};

const EditActivityTemplateValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
};

export const TemplateInformationTab = ({ $selectedActivityTemplate, onCancel }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const $isForceValidating = useHook(true);
    const $isSaving = useHook(false);
    const $activityTemplateCopy = useObjectHook(
        $selectedActivityTemplate.value,
        EditActivityTemplateValidator,
        null,
        $isForceValidating.value
    );

    const updateTemplateInformation = () => {
        $isForceValidating.set(true);
        const errors = $activityTemplateCopy.validate();
        if (errors) {
            return;
        }

        ActivityTemplateService.putActivityTemplate($activityTemplateCopy.value);
        $isSaving.set(true);
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={activityTemplateTabStyles.root}>
                <h2>{t('activityTemplates.editModal.title')}</h2>

                <Input label={t('activityTemplates.fieldName.name')} required $value={$activityTemplateCopy.getPropHook('name')} />
                <div className="actions">
                    <Button onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || isTrazerViewAdmin || !$activityTemplateCopy.isValid} onClick={updateTemplateInformation}>{t('actions.update')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
